import ForgotPasswordPageConfig from './auth/forgot-password/ForgotPasswordPageConfig';
import LockPageConfig from './auth/lock/LockPageConfig';
import MailConfirmPageConfig from './auth/mail-confirm/MailConfirmPageConfig';
import ResetPasswordPageConfig from './auth/reset-password/ResetPasswordPageConfig';
import ReviewOrderPageConfig from './auth/review-order/ReviewOrderPageConfig';
import ComingSoonPageConfig from './coming-soon/ComingSoonPageConfig';
import Error404PageConfig from './errors/404/Error404PageConfig';
import Error500PageConfig from './errors/500/Error500PageConfig';
import FaqPageConfig from './faq/FaqPageConfig';
import KnowledgeBasePageConfig from './knowledge-base/KnowledgeBaseConfig';
import MaintenancePageConfig from './maintenance/MaintenancePageConfig';
import ProfilePageConfig from './profile/ProfilePageConfig';


const pagesConfigs = [
  ResetPasswordPageConfig,
  ForgotPasswordPageConfig,
  MailConfirmPageConfig,
  ReviewOrderPageConfig,
  LockPageConfig,
  ComingSoonPageConfig,
  Error404PageConfig,
  Error500PageConfig,
  MaintenancePageConfig,
  ProfilePageConfig,
  FaqPageConfig,
  KnowledgeBasePageConfig,
];

export default pagesConfigs;
