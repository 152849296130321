import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Controller, useFormContext } from 'react-hook-form';

function ContainerNumberForm(props) {
  const methods = useFormContext();
  const { control, formState } = methods;
  const { errors } = formState;

  return (
    <>
      <div className="flex flex-col md:flex-row w-full">
        <div className="flex w-full ">
          <Controller
            control={control}
            name="container_number"
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                label="Container Number"
                id="container_number"
                variant="outlined"
                fullWidth
                size="small"
                error={!!errors.container_number}
                helperText={errors?.container_number?.message}
              />
            )}
          />
        </div>
      </div>
    </>
  );
}

export default ContainerNumberForm;
