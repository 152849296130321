import DialogContentText from '@mui/material/DialogContentText';
import { DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { closeDialog } from 'app/store/fuse/dialogSlice';

export default function Confirm(props) {
  const { title, content, cancelText = 'Cancel', confirmText = 'Confirm', onClose, onSubmit } = props
  const dispatch = useDispatch()
  return (<>
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {content}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => {
        dispatch(closeDialog())
        if (onClose)
          onClose()
      }} color="primary">
        {cancelText}
      </Button>
      <Button
        onClick={() => {
          dispatch(closeDialog())
          onSubmit()
        }}
        color="primary"
        autoFocus
      >
        {confirmText}
      </Button>
    </DialogActions>
  </>)
}