import { startsWith } from 'lodash';

const ImageViewerWindow = (image) => {
  const newWindow = window.open('', '_blank');
  newWindow.document.write(`
    <html>
      <head>
        <title>Image Viewer</title>
        <style>
          html, body {
            height: 100%;
            margin: 0;
            padding: 0;
          }
          body {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: black;
          }
          img {
            width: 100vw;
            height: 100vh;
            object-fit: contain;
        }
        </style>
      </head>
      <body>
      ${image.map((media, index) => `
  ${media['Content-Type']
      ? `<img 
        src="${startsWith(media['Content-Type'], 'image/') ?
        `data:${media['Content-Type']};base64,${media.data}` :
        'assets/icons/pdf.png'}" 
        class="max-w-none w-auto h-full" 
        alt="attachment" 
      />`
      : `<img 
        src="${startsWith(media.url, 'data:image/') ?
        media.url :
        'assets/icons/pdf.png'}" 
        class="max-w-none w-auto h-full" 
        alt="attachment" 
      />`
    }
`)}

       
      </body>
    </html>
  `);

  return null;
};

export default ImageViewerWindow;
