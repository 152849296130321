import { authRoles } from 'app/auth';
import SignInPage from "app/main/sign-in/SignInPage";

const SignInVerifyConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.users,
  routes: [
    {
      path: 'verify',
      element: <SignInPage pageType="verify" />,
    },
  ],
};

export default SignInVerifyConfig;
