import { Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { startsWith } from 'lodash'
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Loader } from 'app/main/apps/common/ListInfoMessage';
import ImageViewerWindow from 'app/main/dashboards/main/dialogs/ImageViewerWindow';
import pdfViewerWindow from 'app/main/dashboards/main/dialogs/pdfViewerWindow';
import { getShippingLabelData, getPrintProductionData } from '../../store/orderSlice';

function ShippingLabelDetails({ labelRef, isPrinting }) {
    const location = useLocation()
    const { pathname } = location
    const isprintProduction = pathname.split("/").includes("printProduction")
    const params = useParams()
    const dispatch = useDispatch()
    const { orderId } = params
    const [loading, setLoading] = useState(true)
    const [shippingLabel, setShippingLabel] = useState()
    const totalLabels = shippingLabel?.shipping_label?.orders?.length
    const action = isprintProduction ? getPrintProductionData : getShippingLabelData

    useEffect(() => {
        dispatch(action({ [isprintProduction ? "shipment_id" : "id"]: orderId, stage: isprintProduction ? "in-production" : undefined })).then(({ payload }) => {

            setShippingLabel(payload)

            setLoading(false)
        })
    }, [])

    console.log(shippingLabel, "shippingLabel");

    const getClassName = (index) => {
        if (isPrinting && index > 0 && index % 5 === 0) {
            return 'mb-96'
        }
        if (isPrinting && index !== totalLabels - 1 && totalLabels > 2 && (index + 1) % 2 === 0)
            return 'mb-48'
        return ''
    }



    return (
        loading ? <Loader className="h-5vh mx-auto my-auto inset-0" /> : (
            <div className="m-6 mt-30 w-full shipping-label" ref={labelRef}>
                {!isprintProduction ? (shippingLabel?.shipping_label?.orders.map((o, index) => (
                    <>
                        <Card key={o.orderId} variant="outlined" className={clsx('w-full  mt-20   p-0', getClassName(index))}>
                            <CardContent className='p-0 !pb-0'>
                                <div className='flex border-2 !border-black justify-evenly border-radius rounded-2xl'>
                                    <div className="items-center text-center border-r-2 !border-black w-224">
                                        <div className="border-b-2 !border-black flex items-center justify-center font-bold min-h-60 max-h-60">
                                            <img src='assets/images/logos/logo.png' alt='logo' className='object-cover h-32' />
                                        </div>
                                        <div className="flex text-center h-full">
                                            <div className="border-r-2 !border-black w-full">
                                                <Typography className='font-bold border-b-2 !border-black pb-2 text-[20px]'>LEN</Typography>
                                                <Typography className='text-[20px]'>{o.length}</Typography>
                                            </div>
                                            <div className="border-r-2 !border-black w-full">
                                                <Typography className='font-bold border-b-2 !border-black pb-2 text-[20px]'>THK</Typography>
                                                <Typography className='text-[20px]'>{o.width}</Typography>
                                            </div>
                                            <div className="w-full">
                                                <Typography className='font-bold border-b-2 !border-black pb-2 text-[20px]'>HGT</Typography>
                                                <Typography className='text-[20px]'>{o.height}</Typography>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="items-center border-r-2 !border-black w-1/2">
                                        <div className='font-bold mb-2 text-center border-b-2 !border-black min-h-60 max-h-60'>
                                            <Typography variant="h5" className=''>{shippingLabel?.shipping_label?.company_name}</Typography>
                                            <Typography className='text-[20px] whitespace-nowrap'>{shippingLabel?.shipping_label?.company_address}</Typography>
                                        </div>
                                        <div className="flex  text-center h-full">
                                            <div className="border-r-2 !border-black w-full">
                                                <Typography className='font-bold border-b-2 !border-black text-[20px]'>Color</Typography>
                                                <Typography className='text-[20px] whitespace-nowrap'>{o.color}</Typography>
                                            </div>
                                            <div className="border-r-2 !border-black w-full">
                                                <Typography className='font-bold border-b-2 !border-black text-[20px]'>Shape</Typography>
                                                <Typography className='text-[20px]'>{o.type}</Typography>
                                            </div>
                                            <div className="w-full">
                                                <Typography className='font-bold border-b-2 !border-black text-[20px]'>Finish</Typography>
                                                <Typography className='text-[20px]'>{o.polish}</Typography>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="items-center border-r-2 !border-black w-1/6">
                                        <Typography variant="h5" className='font-bold mb-2 flex items-center justify-center border-b-2 !border-black min-h-60 max-h-60'>{o.box_number}</Typography>
                                        <div className="flex justify-evenly text-center h-full">
                                            <div className="border-r-2 !border-black-1 !border-black text-center w-full h-full ">
                                                <Typography className='font-bold border-b-2 !border-black text-[20px]'>Holes</Typography>
                                                <Typography className='text-[20px]'>Nil</Typography>
                                            </div>
                                            <div className="text-center w-full">
                                                <Typography className='font-bold border-b-2 !border-black text-[20px]'>QTY</Typography>
                                                <Typography className='text-[20px]'>{o.quantity}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center w-1/4">
                                        <Typography variant="h5" className='font-bold mb-2 flex items-center justify-center border-b-2 !border-black min-h-60 max-h-60'>
                                            {shippingLabel?.shipping_label.container_number}
                                        </Typography>
                                        <div className="flex justify-evenly h-full">
                                            <div className="border-r-2 !border-black text-center w-full">
                                                <Typography className='font-bold border-b-2 !border-black text-[20px]'>Decedent</Typography>
                                                <Typography className='text-[20px]'>{o.decedents}</Typography>
                                            </div>
                                            <div className="whitespace-nowrap w-full">
                                                <Typography className='font-bold border-b-2 !border-black text-[20px]'>Order No.</Typography>
                                                <Typography className='text-[20px]'>{o.order_no ? `#${o.order_no}` : ''}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </CardContent>

                            {isprintProduction && (
                                <div className={clsx(`${isPrinting ? "mt-10" : "m-20"} flex gap-10`)}>
                                    {o?.attachments?.map((media, ind) => {
                                        const isPDF = media.mime === 'application/pdf' || startsWith(media?.url, 'data:application/pdf') || media['Content-Type'] === 'application/pdf';
                                        return (
                                            <div
                                                role="button"
                                                onClick={() => isPDF ? pdfViewerWindow(media.data || media?.url) : ImageViewerWindow([media])}
                                                onKeyDown={() => { }}
                                                tabIndex={0}
                                            >
                                                <img
                                                    src={
                                                        startsWith(media['Content-Type'], 'image/')
                                                            ? `data:${media['Content-Type']};base64,${media.data}`
                                                            : 'assets/icons/pdf.png'
                                                    }
                                                    className={clsx(
                                                        'flex justify-center w-128 h-128 rounded-16 mr-12 mb-24 overflow-hidden outline-none shadow hover:shadow-lg relative cursor-pointer')}
                                                    alt="rfq"
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            )}


                        </Card>
                    </>

                ))) : <div style={{ marginTop: "30px" }}>
                    <Card>
                        <Typography variant='h4' className='text-center'>{shippingLabel?.container_number}</Typography>
                        <CardContent>


                            {shippingLabel?.orders?.map((o, index) => (
                                <>
                                    <Table sx={{ border: '1px solid #ffff' }} >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className='whitespace-nowrap font-bold' >Order No</TableCell>
                                                <TableCell className='whitespace-nowrap font-bold'>Decedent</TableCell>
                                                <TableCell className='whitespace-nowrap font-bold'>Qty</TableCell>
                                                <TableCell className='whitespace-nowrap font-bold'>Color</TableCell>
                                                <TableCell className='whitespace-nowrap font-bold' >Shape</TableCell>
                                                <TableCell className='whitespace-nowrap font-bold'>Dimension</TableCell>
                                                <TableCell className='whitespace-nowrap font-bold'>Finish</TableCell>
                                                <TableCell className='whitespace-nowrap font-bold'>Edge</TableCell>
                                                <TableCell className='whitespace-nowrap font-bold' >Weight lbs</TableCell>
                                                <TableCell className='whitespace-nowrap font-bold'>Total Weight lbs</TableCell>
                                                <TableCell className='whitespace-nowrap font-bold'>Crate Number</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={index}>
                                                {/* <TableCell sx={{  border: '1px solid #000',  }}>{index + 1}</TableCell> */}
                                                <TableCell  >{o.order_no}</TableCell>
                                                <TableCell >{o.decedents}</TableCell>
                                                <TableCell >{o.quantity}</TableCell>
                                                <TableCell >{o.color}</TableCell>
                                                <TableCell >{o.type}</TableCell>
                                                <TableCell >{`${o.length}X${o.width}X${o.height}`}</TableCell>
                                                <TableCell >{o.polish}</TableCell>
                                                <TableCell >-</TableCell>
                                                <TableCell >{o.weight}</TableCell>
                                                <TableCell >{o.quantity * o.weight}</TableCell>
                                                <TableCell >{o.box_number}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colspan={5} />
                                                <TableCell>
                                                    <Typography>Description</Typography>
                                                </TableCell>
                                                <TableCell colSpan={6}>
                                                    <Typography className="font-normal text-5 whitespace-nowrap" variant="subtitle1" >{`${o.color} ${o.type} ${o.length}X${o.width}X${o.height}${o.polish} `}</Typography>
                                                </TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                    {o?.attachments?.length > 0 ? (
                                        <div className='border border-black rounded-lg flex flex-row justify-center gap-10'>
                                            {o?.attachments?.map((media, ind) => {
                                                const isPDF = media.mime === 'application/pdf' || startsWith(media?.url, 'data:application/pdf') || media['Content-Type'] === 'application/pdf';
                                                return (
                                                    <div
                                                        role="button"
                                                        onClick={() => isPDF ? pdfViewerWindow(media.data || media?.url) : ImageViewerWindow([media])}
                                                        onKeyDown={() => { }}
                                                        tabIndex={0}
                                                        className='p-20 flex item-center justify-center gap-10'

                                                    >
                                                        <img
                                                            src={
                                                                startsWith(media['Content-Type'], 'image/')
                                                                    ? `data:${media['Content-Type']};base64,${media.data}`
                                                                    : 'assets/icons/pdf.png'
                                                            }
                                                            className={clsx(
                                                                'flex justify-center w-200 h-200 rounded-16 mr-12 mb-24 overflow-hidden outline-none shadow hover:shadow-lg relative cursor-pointer')}
                                                            alt="rfq"
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    ) : <Typography className="font-normal text-center mt-10 mb-10" variant="subtitle1" color="textSecondary">
                                        No Uploads
                                    </Typography>}

                                </>

                            ))}



                        </CardContent>
                    </Card>

                </div>


                }
            </div >)
    );

}
export default ShippingLabelDetails;