import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

export const getCartItems = createAsyncThunk('auth/carts/getCartItems', async () => {
  const response = await axios.get('/api/cartItems', {});
  const data = await response.data
  return data;

});

export const getEtchingImages = createAsyncThunk('auth/carts/getEtchingImages', async (customImage) => {
  const response = await axios.get('/api/etching');
  const data = await response.data.etchingImage;
  if (customImage)
    return [...customImage, ...data]
  return data
});

export const customLayout = createAsyncThunk('auth/carts/getcustomLayout', async (productId) => {
  const response = await axios.get(`/api/customLayout/${productId}`);
  const data = await response.data.product.type.custom_layout
  return data
})
// type vas = { bookId: string; title: string }

export const saveCartItem = createAsyncThunk(
  'auth/carts/saveCartItem',
  async ({ productId, quantity, uid, vas, customer_provider_number: cpn, decedent }, { dispatch, getState }) => {
    await axios.post('/api/cartItem', {
      product_id: productId,
      quantity,
      uid,
      vas,
      customer_provider_number: cpn,
      decedent
    });
    dispatch(getCartItems());
    return productId;
  }
);

const cartsAdapter = createEntityAdapter({});

export const { selectAll: selectCarts, selectById: selectCartById } = cartsAdapter.getSelectors(
  (state) => state.auth.carts
);

const cartSlice = createSlice({
  name: 'auth/carts',
  initialState: cartsAdapter.getInitialState({
    searchText: '',
    totalCartItem: 0,
    images: [],
    customLayout: {},
    loading: false
  }),
  reducers: {
    setTotalCartItem: (state, action) => {
      state.totalCartItem = action.payload;
    },
    setEtchingImages: (state, action) => {
      state.images = action.payload;
    },
    setCartsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getCartItems.fulfilled]: (state, action) => {
      const { data, routeParams } = action.payload;
      cartsAdapter.setAll(state, data);
      state.searchText = '';
      state.totalCartItem = action.payload && action.payload.total ? action.payload.total : 0;
    },
    [saveCartItem.fulfilled]: (state, action) => { },
    [getEtchingImages.fulfilled]: (state, action) => {
      state.images = action.payload
    },
    [customLayout.fulfilled]: (state, action) => {
      state.customLayout = action.payload
      state.loading = false

    },
    [customLayout.pending]: (state, action) => {
      state.loading = true
    }
  },
});

export const { setCartsSearchText, setTotalCartItem, setEtchingImages } = cartSlice.actions;

export default cartSlice.reducer;
