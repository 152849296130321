import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { AuraSimpleTableHead } from 'app/main/apps/common/AuraSimpleTable';
import ImageRenderer from 'app/main/apps/common/ImageRenderer';
import { Fragment } from 'react';

export function ProductTableHeader({ type, hideForType }) {
    const { vasOrder, title } = hideForType
    const hideFor = () => {
        if (vasOrder === 'vas_price_order' && title === 'Create Vas Order With Proof')
            return true
        return false
    }
    const supplierProductsHeaders = [
        { name: 'Product Name' },
        { name: 'Sub Type' },
        { name: 'Weight' },
        { name: 'Quantity' },
        { name: hideFor() ? 'Vas Price' : 'Price' },
        { name: 'FOB', hide: hideFor() },
        { name: 'Price', hide: type === 'create supplier order' || hideFor() },
        { name: 'Amount', hide: hideFor() }
    ]

    const approvalHeaaders = [
        { name: 'Product Name' },
        { name: 'Weight' },
        { name: 'Quantity' }
    ]

    return <AuraSimpleTableHead
        headerList={title === 'Send for Approval' ? approvalHeaaders : supplierProductsHeaders}
        classes={{ cnCell: 'font-semibold px-8 py-8' }}
    />
}

function ProductDetailBody({ tableData = [], calculatePrice = () => { }, type, hideForType, showDocuments = false }) {
    const { vasOrder, title } = hideForType
    console.log(tableData, "tableData");

    return (
        <TableBody>
            {tableData.map((products, index) => {
                console.log('products', products)
                let drawingDocuments = []
                if (products?.drawing_documents?.length > 0) {
                    drawingDocuments = products?.drawing_documents
                }
                return (
                    <Fragment key={products.id}>
                        {title !== "Send for Approval" ?
                            (
                                <TableRow>
                                    <TableCell>
                                        {products.name}
                                    </TableCell>
                                    <TableCell className="px-8 py-4">
                                        {products.sub_type}
                                    </TableCell>
                                    <TableCell className="px-8 py-4">
                                        {products.weight} lbs
                                    </TableCell>
                                    <TableCell className="px-8 py-4">
                                        {products.quantity}
                                    </TableCell>
                                    <TableCell className="px-8 py-4">
                                        {vasOrder !== 'vas_price_order' && title !== 'Create Vas Order With Proof' ? ` $${products.product?.unit_price}` : `$${products.vas_price}`}
                                    </TableCell>

                                    {vasOrder !== 'vas_price_order' && title !== 'Create Vas Order With Proof' && (
                                        <TableCell className="px-8 py-4">
                                            ${products.unit_price}
                                        </TableCell>
                                    )}

                                    {type === 'choose supplier' && <TableCell className="px-8 py-4">
                                        ${calculatePrice(products.unit_price, products.weight, products.quantity, true)}
                                    </TableCell>}
                                    {vasOrder !== 'vas_price_order' && title !== 'Create Vas Order With Proof' && (
                                        <TableCell className="px-8 py-4">
                                            ${type === 'choose supplier' ?
                                                calculatePrice(products.unit_price, products.weight, products.quantity) :
                                                products.unit_price * products.quantity
                                            }
                                        </TableCell>
                                    )}
                                </TableRow>
                            ) : (
                                <>
                                    <TableRow>
                                        <TableCell> {products.product.description}</TableCell>
                                        <TableCell> {products.product.weight}{products.product.weight !== null && ' lbs'}</TableCell>
                                        <TableCell> {products.quantity}</TableCell>
                                    </TableRow>
                                    {
                                        showDocuments && (
                                            <TableRow>
                                                {drawingDocuments.length ?
                                                    <>
                                                        <Typography variant="h6" className="mt-6 mb-10">
                                                            {products?.vas ? 'Proof' : 'Drawing'}
                                                        </Typography>
                                                        <ImageRenderer attachments={drawingDocuments} />
                                                    </> : null
                                                }
                                            </TableRow>
                                        )
                                    }
                                </>
                            )}

                    </Fragment>
                )
            })}
        </TableBody >
    );
};

export default ProductDetailBody;