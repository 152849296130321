import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { authRoles } from 'app/auth';

const Configurations = lazy(() => import('./configurations/Configurations'));
const DataMappings = lazy(() => import('./data-mappings/DataMappings'));
const EmailTemplates = lazy(() => import('./email-templates/EmailTemplates'));
const CompanyProfiles = lazy(() => import('./company-profiles/CompanyProfiles'));

const SettingsAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.admin,
  routes: [
    {
      path: 'settings/configurations',
      element: <Configurations />,
    },
    {
      path: 'settings/mappings',
      element: <DataMappings />,
    },
    {
      path: 'settings/templates',
      element: <EmailTemplates />,
    },
    {
      path: 'settings/profiles',
      element: <CompanyProfiles />,
    },
    {
      path: 'settings',
      element: <Navigate to="configurations" />,
    },
  ],
};

export default SettingsAppConfig;
