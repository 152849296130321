import AuraControlledAutocomplete from 'app/main/apps/common/AuraControlledAutocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import format from 'date-fns/format';
import { Controller, useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStatuses } from 'app/main/dashboards/main/store/widgetsSlice';
import { isSupplier, isCustomer } from 'app/main/apps/common/AuraFunctions';

function NoteFormContent(props) {
  const { confirmOrder, uiId, orderDialog, title } = props;
  const currentUser = useSelector(({ user }) => user);
  const methods = useFormContext();
  const { control, formState, watch } = methods;
  const noteType = watch('note_type')
  const isInternalNotes = watch('isInternalNotes')
  const { errors } = formState;
  const dialogParam = orderDialog?.param;
  const getLable = () => {
    if (isDialogType('review_note') && dialogParam?.title !== 'Mark as Completed') {
      return 'Reply back'
    }
    if (['Reject Quote', 'Reject Order', 'Reject Proof', 'Reject Vas Price'].includes(title)) {
      return 'Reject Comments'
    }
    if (['Approve Quote', 'Approve Order', 'Approve Proof', 'Request Revision for Proof', 'Approve Vas Price',].includes(title)) {
      return 'Additional Comments'
    }
    if (['Request Quote Revision', 'Request Revision for Drawing', 'Request Revision for Vas price', 'Request Revision for Order'].includes(title)) {
      return 'Revision Comments'
    }
    return 'Notes'
  }

  const isDialogType = (name) => {
    return dialogParam && dialogParam.name === name;
  }

  const dispatch = useDispatch()
  const [reason, setReason] = useState([])

  const selectReasonFor = ['REJECT', 'REJECT_DRAWING', 'REJECT_QUOTE'].includes(uiId)

  useEffect(() => {
    if (selectReasonFor) {
      dispatch(getStatuses('reject_reason')).then((data) => setReason(data.payload))

    }
  }, [])

  const showOnlyNotes = ['Request Quote Revision', 'Request Revision for Drawing'].includes(title)

  return (
    <>
      {confirmOrder && (
        <>
          <div className="flex flex-col md:flex-row w-full">
            <div className="flex w-full ">
              <Controller
                control={control}
                name="order_no"
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Order No *"
                    id="order_no"
                    variant="outlined"
                    fullWidth
                    size="small"
                    error={!!errors.order_no}
                    helperText={errors?.order_no?.message}
                  />
                )}
              />
            </div>
          </div>
          {/* <div className="flex flex-col md:flex-row w-full">
            <div className="flex w-full ">
              <Controller
                control={control}
                name="shipping_cost"
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Shipping Cost"
                    id="shipping_cost"
                    type="number"
                    variant="outlined"
                    fullWidth
                    size="small"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      classes: {
                        input: 'FuseSearch-input py-4 h-20',
                        notchedOutline: 'rounded-8',
                      },
                      readOnly: false,
                    }}
                    error={!!errors.shipping_cost}
                    helperText={errors?.shipping_cost?.message}
                  />
                )}
              />
            </div>
          </div> */}
        </>
      )}
      <div className="flex w-full mb-8">
        <div className="flex w-full">
          {
            isDialogType('review_note') && orderDialog?.data?.length > 0 && (
              <div className='mb-10 p-10 overflow-scroll border !border-black w-full' style={{ height: '19rem' }}>
                {
                  orderDialog.data.map((note, index) => {
                    return (
                      <div key={`key-${index}`}>
                        <div className='font-semibold'>{note?.added_by}<span className='text-xs text-slate-400 font-light ml-4'>{format(new Date(note.added_on), 'MMM dd, yyyy h:mm a')}</span></div>
                        <div className='font-light mb-10'>{note?.note}</div>
                      </div>
                    )
                  })
                }
              </div>
            )
          }
        </div>
      </div>
      <div className="flex flex-col md:flex-row w-full">
        {
          selectReasonFor && !showOnlyNotes && (
            <div className="flex w-full mb-8">
              <div className="flex w-full">
                <AuraControlledAutocomplete
                  labelName='Notes Type'
                  control={control}
                  controlName='note_type'
                  inputValues={reason}
                  error={!!errors.note_type}
                  helperText={errors?.note_type?.message}
                />
              </div>
            </div>
          )
        }

      </div>
      <div className="flex w-full mb-8">
        <div className="flex w-full">
          {
            (noteType?.name === 'Others' || !selectReasonFor || showOnlyNotes) && (
              <Controller
                control={control}
                name="note"
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label={getLable()}
                    id="note"
                    variant="outlined"
                    fullWidth
                    size="small"
                    multiline
                    rows={3}
                    error={!!errors.note}
                    helperText={errors?.note?.message}
                    InputProps={{ readOnly: title === 'View Note' }}
                  />
                )}
              />
            )
          }
        </div>
      </div>
      {
        isDialogType('review_note') && !isSupplier(currentUser.role) && !isCustomer(currentUser.role) && dialogParam?.title !== 'Mark as Completed' && (
          <div className="flex w-full w-1/4">
            <div className="flex w-full mb-24 justify-end">
              <Controller
                name="isInternalNotes"
                type="checkbox"
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    checked={value}
                    className="p-0 pl-10"
                    onChange={(ev) => onChange(ev.target.checked)}
                  />
                )}
              />
              <Typography className="text-14 ">Add Internal Notes</Typography>
            </div>
          </div>
        )
      }
      <div className="flex w-full mb-8">
        <div className="flex w-full">
          {
            isDialogType('review_note') && dialogParam?.title !== 'Mark as Completed' && isInternalNotes && (
              <Controller
                control={control}
                name="internalNote"
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Note"
                    id="internalNote"
                    variant="outlined"
                    fullWidth
                    size="small"
                    multiline
                    rows={3}
                  />
                )}
              />
            )
          }
        </div>
      </div>
    </>
  );
}

export default NoteFormContent;
