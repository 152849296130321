const pdfViewerWindow = (base64) => {
    // Assuming your base64 PDF data is stored in a variable called base64PDFData
    const base64PDFData = base64.replace(/^data:application\/pdf;base64,/, '');

    // Convert base64 to a blob
    const byteCharacters = atob(base64PDFData);
    const byteArrays = [];
    for (let i = 0; i < byteCharacters.length; i += 1) {
        byteArrays.push(byteCharacters.charCodeAt(i));
    }
    const byteArray = new Uint8Array(byteArrays);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Create a data URL from the blob
    const dataUrl = URL.createObjectURL(blob);

    // Open a new window or tab with the PDF data URL
    window.open(dataUrl, '_blank');
};

export default pdfViewerWindow