import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const updateAddress = createAsyncThunk(
  'settingsApp/address/updateAddress',
  async (inputData, { dispatch, getState }) => {
    const response = await axios.put(`/api/addresses/${inputData.id}`, inputData);
    const data = await response.data.address;
    return data;
  }
);

const addressSlice = createSlice({
  name: 'settingsApp/address',
  initialState: {},
  reducers: {},
  extraReducers: {
    [updateAddress.fulfilled]: (state, action) => {},
  },
});

export const { setEmailTemplatesSearchText } = addressSlice.actions;

export default addressSlice.reducer;
