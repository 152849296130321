import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import AuraResponseDialog from 'app/main/apps/common/AuraImportResponse';
import { getProducts, uploadProducts } from '../e-commerce/store/productsSlice';
import { getOrders, uploadOrders } from '../e-commerce/store/ordersSlice';

function AuraImport(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const id = `button-file${props.id}`;
  function handleChange(e) {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    props.handleBackdropToggle(true);
    if (props.name === 'order' || props.name === 'quote') {
      dispatch(uploadOrders({ file, type: props.name, ...props.params }))
        .unwrap()
        .then((response) => {
          setResponseData(response);
          setOpen(true);
          e.target.value = null;
          if (!response.is_error)
            dispatch(getOrders({ params: { stage: props.name } }))
        })
        .finally(() => props.handleBackdropToggle(false));
    } else if (props.name === 'products') {
      dispatch(uploadProducts({ file, ...props.params }))
        .unwrap()
        .then((response) => {
          setResponseData(response.items);
          setOpen(true);
          e.target.value = null;
          if (!response.is_error)
            dispatch(getProducts({})).then(() => {
              props.handleBackdropToggle(false);
            });
        });
    }

    const reader = new FileReader();

    reader.readAsBinaryString(file);

    reader.onload = () => {
      if (props.onChange) {
        props.onChange(`data:${file.type};base64,${btoa(reader.result)}`);
      }
    };

    reader.onerror = () => {
      console.log('error on load file');
    };
  }

  const handleClose = () => {
    setOpen(false);
    setResponseData(null);
  };

  return (
    <>
      <label htmlFor={id}>
        <input accept=".xlsx" className="hidden" id={id} type="file" onChange={handleChange} />
        <Tooltip title={props.title}>
          <IconButton className="w-32 h-32 mx-4 p-0" component="span" size="large">
            <Icon fontSize="small">{props.icon || 'save_alt'}</Icon>
          </IconButton>
        </Tooltip>
      </label>
      <AuraResponseDialog
        data={responseData}
        open={open}
        onClose={handleClose}
        name={props.name}
      />
    </>
  );
}

export default AuraImport;
