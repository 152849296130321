import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { formatDateString } from 'app/main/apps/common/AuraFunctions';
import format from 'date-fns/format';
import { capitalize } from 'lodash';

export const getRfdDetails = createAsyncThunk(
    'eCommerceApp/rfd/getEnquiryDetails',
    async ({ id, params }, { dispatch, getState }) => {
        dispatch(setLoading())
        const response = await axios.get(`/api/orders/${id}`);
        const data = await response.data.order
        const getData = getRfd(data)
        dispatch(setRfdProductsData(getData))
        dispatch(setOriginalData(data))
        dispatch(resetLoading())
        return data ? getInitValues(data) : data;
    }
);

const getRfd = (data) => {
    return data.order_details?.map((od) =>
    ({
        ...od.product, order_quantity: od.quantity,
        weight: Number(od.weight), price: !od.unit_price ? '' : od.unit_price,
        decedent: od.decedent,
        customer_provider_number: od.customer_provider_number,
        is_has_drawing: od.is_has_drawing,
        vas: od.vas
    }))
}

export const saveSupplierRfd = createAsyncThunk(
    'eCommerceApp/rfd/saveSupplierRfd',
    async (rfdData, { dispatch, getState }) => {
        const { rfd } = getState().eCommerceApp;
        const payload = { updateStatus: 'yes', ...rfdData, through: rfdData.through?.name || rfdData.through }
        const response = await axios.put(`/api/orders/${rfd.data.id}`, payload);
        const data = await response.data;
        return data;
    }
);

export const rfdProductData = {
    id: null,
    name: '',
    description: '',
    code: '',
    type: null,
    sub_type: null,
    stone_color: null,
    category: {
        name: '',
    },
    length: undefined,
    width: undefined,
    height: undefined,
    weight: undefined,
    polish: '',
    finish: '',
    order_quantity: 1,
    isCustomProduct: 'yes',
    is_draft_product_id: true,
    is_rfq: true,
    unit_price: undefined,
    unit_cost: undefined,
    price: undefined,
    customer_provider_number: '',
    decedent: '',
    errors: {},
    vas: undefined,
    is_has_drawing: false,
    tariff: undefined,
    shipping: undefined,
    margin: undefined
};

export const initState = {
    loading: false,
    data: null,
    productsData: [rfdProductData],
    originalData: {}
};

function getInitValues(data) {
    return {
        id: data && data.id ? data.id : null,
        enquiry_no: data && data.enquiry_no ? data.enquiry_no : '',
        name: data && data.name ? data.name : '',
        customer_id: data && data.customer_id ? data.customer_id : '',
        customer_email: data && data.customer ? data.customer?.email : '',
        customer_phone: data && data.customer ? data.customer?.phone : null,
        customer: data && data.customer ? data.customer : null,
        selected_customer: data && data.customer ? { ...data.customer } : null,
        client_contract_number: data && data.client_contract_number ? data.client_contract_number : '',
        date:
            data && data.date
                ? formatDateString(data.date, 'yyyy-MM-dd')
                : format(new Date(), 'yyyy-MM-dd'),
        requested_date:
            data && data.eta_date ? formatDateString(data.eta_date, 'yyyy-MM-dd') : '',
        quantity: data && data.quantity ? data.quantity : 1,
        amount: data && data.amount ? data.amount : '',
        status_id: data && data.status_id ? data.status_id : null,
        status: data && data.status ? data.status : null,
        status_change_time: data && data.status_change_time ? data.status_change_time : null,
        through: data && data.through ? capitalize(data.through) : 'Email',
        reference: data && data.reference ? data.reference : '',
        ship_to: data && data.ship_to ? data.ship_to : '',
        tags: data && data.tags ? data.tags : '',
        status_histories: data && data.status_histories ? data.status_histories : [],
        note_histories: data && data.note_histories ? data.note_histories : [],
        histories: data && data.histories ? data.histories : [],
        documents: data && data.uploadedFile ? data.uploadedFile : [],
        new_documents: data && data.new_documents ? data.new_documents : [],
        remarks: data && data.remarks ? data.remarks : '',
        notes: data && data.notes ? data.notes : '',
        order_details: data && data.order_details ? data.order_details : [],
        weight: data?.order_details && data?.order_details[0]?.weight ? data?.order_details[0]?.weight : '',
        length: data?.order_details && data?.order_details[0]?.name ? data?.order_details[0]?.length : '',
        width: data?.order_details && data?.order_details[0]?.width ? data?.order_details[0]?.width : '',
        height: data?.order_details && data?.order_details[0]?.height ? data?.order_details[0]?.height : '',
        stone_color: data?.order_details && data?.order_details[0]?.product ? data?.order_details[0]?.product?.stone_color : null,
        finish: data?.order_details && data?.order_details[0]?.finish ? data?.order_details[0]?.finish : '',
        polish: data?.order_details && data?.order_details[0]?.polish ? data?.order_details[0]?.polish : '',
        decedents: data && data?.decedents ? data?.decedents : '',
        type: data?.order_details && data?.order_details[0]?.product?.type ? data?.order_details[0]?.product?.type : '',
        sub_type:
            data?.order_details && data?.order_details[0]?.product?.sub_type
                ? data?.order_details[0]?.product?.sub_type
                : '',
        product: data?.order_details && data?.order_details[0]?.product ? data?.order_details[0]?.product : '',
        supplier_id: data?.supplier_id,
        unit_price: 0,
        revision_notes: data && data.revision_notes ? data.revision_notes : [],
        vas_order_type: data && data.vas_order_type ? data.vas_order_type : null,
        order_no: data && data.order_no ? data.order_no : null,
        order_type: data && data.order_type ? data.order_type : ''

    };
}

const enquirySlice = createSlice({
    name: 'eCommerceApp/rfd',
    initialState: initState,
    reducers: {
        resetRfd: (state, action) => {
            state.data = null;
        },
        setLoading: (state, action) => {
            state.loading = true;
        },
        resetLoading: (state, action) => {
            state.loading = false;
        },
        setRfdProductsData: (state, action) => {
            state.productsData = action.payload
        },
        setOriginalData: (state, action) => {
            state.originalData = action.payload
        },
        newRfd: {
            reducer: (state, action) => {
                state.data = action.payload;
            },
            prepare: (event) => ({
                payload: getInitValues(),
            }),
        },
    },
    extraReducers: {
        [getRfdDetails.fulfilled]: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const {
    resetRfd,
    newRfd,
    setLoading,
    resetLoading,
    setRfdProductsData,
    setOriginalData,
} = enquirySlice.actions;

export default enquirySlice.reducer;
