import { combineReducers } from '@reduxjs/toolkit';
import order from './orderSlice';
import orders from './ordersSlice';
import product from './productSlice';
import products from './productsSlice';
import enquiry from './enquirySlice';
import enquiries from './enquiriesSlice';
import files from './filesSlice';
import rfqs from './rfqsSlice'
import rfq from './rfqSlice';
import rfd from './rfdSlice'

const reducer = combineReducers({
  products,
  product,
  orders,
  order,
  enquiries,
  enquiry,
  files,
  rfqs,
  rfq,
  rfd
});

export default reducer;
