import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { cloneDeep } from 'lodash';
import OrdersWithDetails from 'app/main/apps/e-commerce/orders/OrdersWithDetails';
import { Loader } from 'app/main/apps/common/ListInfoMessage';
import AuraBaseDialog from '../../../common/AuraBaseDialog';
import {
    closeReallocationDialog,
    createReallocation,
    getOrderForReallocation,
    getSelectedOrderDetails,
    reAllocationFrom,
    reAllocationTo,
    orderForReallocation as setOrderForReallocation,
    selectedOrderDetails as setSelectedOrderDetails
} from "../../store/ordersSlice";

function ReallocationDialog() {
    const [toOrder, setToOrder] = useState([]);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState({ from: false, to: false })
    const [submitValue, setSubmitValue] = useState({ fromOrder: [], toOrder: [] });
    const [unChangedValue, setUnChangedValue] = useState({ fromOrder: [], toOrder: [] });
    const reAllocationDialog = useSelector(state => state.eCommerceApp?.orders?.reAllocationDialog);
    const selectedOrderDetails = useSelector(state => state.eCommerceApp?.orders?.selectedOrderDetails);
    const orderForReallocation = useSelector(state => state.eCommerceApp?.orders?.orderForReallocation);
    const selectedTo = useSelector(state => state.eCommerceApp?.orders?.reAllocationTo);
    const dialogParam = reAllocationDialog?.param;
    const dispatch = useDispatch();
    const methods = useForm({
        mode: 'onChange',
    });

    const fetchData = () => {
        setLoading({ from: true, to: true })
        dispatch(reAllocationFrom(reAllocationDialog?.param?.selectedFrom))
        dispatch(getSelectedOrderDetails(reAllocationDialog?.param?.selectedFrom))
            .finally(() => setLoading(prev => ({ ...prev, from: false })))
        dispatch(getOrderForReallocation(reAllocationDialog?.param?.selectedFrom))
            .finally(() => setLoading(prev => ({ ...prev, to: false })))
    }

    useEffect(() => {
        if (reAllocationDialog?.props?.open) {
            fetchData()
        }
    }, [reAllocationDialog])

    useEffect(() => {
        const opt = []
        if (orderForReallocation)
            orderForReallocation.forEach((customer) => {
                customer.orders.forEach(order => opt.push({ label: `${order.order_no} - ${order.customer.name}`, oid: order.id }))
            })
        setOptions(opt)
    }, [orderForReallocation])

    function closeDialog() {
        dispatch(closeReallocationDialog());
        setToOrder([]);
        setOptions([]);
        setSubmitValue({ fromOrder: [], toOrder: [] });
        setUnChangedValue({ fromOrder: [], toOrder: [] });
    }

    function getFilteredOrder(newValue) {
        return orderForReallocation.map(customer => ({ ...customer, orders: customer.orders.filter(order => order.id === newValue) }))
    }

    function onChangeHandler(e, { label, oid }) {
        dispatch(reAllocationTo({ label, oid }))
        setToOrder(prev => {
            if (prev.length)
                fetchData()
            return getFilteredOrder(oid)
        })
        setSubmitValue({
            fromOrder: cloneDeep(selectedOrderDetails),
            toOrder: cloneDeep(getFilteredOrder(oid))
        });
        setUnChangedValue({
            fromOrder: cloneDeep(selectedOrderDetails),
            toOrder: cloneDeep(getFilteredOrder(oid))
        })
    }

    function changeToOrder(event, productId) {
        const temp = cloneDeep(toOrder)
        const prevFromValue = unChangedValue.fromOrder[0].orders[0].order_detail.find(detail => detail.product_id === productId).confirmed_quantity
        const prevToValue = unChangedValue.toOrder[0].orders[0].order_detail.find(detail => detail.product_id === productId).confirmed_quantity
        const newValue = event - prevFromValue
        temp[0].orders.find(order => order.id === selectedTo.oid).order_detail.forEach(detail => {
            if (detail.product_id === productId) {
                detail.confirmed_quantity = prevToValue - newValue
            }
            return detail
        })
        setToOrder(temp)
        return temp
    }

    function changeFromOrder(event, productId) {
        const temp = cloneDeep(selectedOrderDetails)
        temp[0].orders[0].order_detail.forEach(detail => {
            if (detail.product_id === productId)
                detail.confirmed_quantity = Number(event)
            return detail
        })
        dispatch(setSelectedOrderDetails(temp))
        return temp
    }

    function handleReAllocationChange(type, customerIndex, orderIndex, detailIndex, event, productId) {
        try {
            submitValue.fromOrder[customerIndex].orders[orderIndex].order_detail[detailIndex].confirmed_quantity = Number(event);
            const changedToOrder = changeToOrder(event, productId);
            changeFromOrder(event, productId);
            setSubmitValue({ ...submitValue, toOrder: changedToOrder });

        } catch (error) { console.error(error) }
    }

    function onSubmit() {
        dispatch(createReallocation({ from_order: submitValue.toOrder[0], to_order: submitValue.fromOrder[0] })).then((res) => {
            if (!res.error)
                dispatch(
                    showMessage({
                        message: 'Order Reallocated Successfully',
                        type: 'success',
                    })
                );
        });
        closeDialog();
    }

    function check(product) {
        const ids = unChangedValue.fromOrder[0]?.orders.map(o => o.order_detail?.map(od => ({ id: od?.product_id, max: od?.quantity - od?.confirmed_quantity })))[0]
        const allowed = ids?.find(({ id }) => id === product.product_id)?.max;
        const fromMax = unChangedValue.toOrder[0]?.orders[0].order_detail.find(detail => detail.product_id === product.product_id)?.confirmed_quantity || 0
        const toMax = unChangedValue.fromOrder[0]?.orders[0].order_detail.find(detail => detail.product_id === product.product_id)?.confirmed_quantity || 0
        const max = Math.min(fromMax, allowed) || 0
        const isMiniumum = product.confirmed_quantity < toMax;
        const isMaximum = product.confirmed_quantity > (max + toMax)
        return isMaximum || isMiniumum
    }

    const disabled = selectedOrderDetails.map((customer) => customer.orders[0].order_detail.map((detail) => check(detail)))[0]
    return reAllocationDialog?.props?.open ? (
        <>
            <FormProvider {...methods}>
                <AuraBaseDialog
                    dialogProps={reAllocationDialog.props}
                    closeDialog={closeDialog}
                    dialogParam={{ ...dialogParam, maxWidth: 'lg' }}
                    onSubmit={onSubmit}
                    disableSubmitButton={() => loading.from || loading.to || !selectedTo || disabled.includes(true)}
                // header={headerData}
                >
                    {loading.from || loading.to ? <Loader /> :
                        <>
                            <Typography color="textSecondary" variant="body1" className="font-semibold text-xl mb-5">
                                To
                            </Typography>
                            {selectedOrderDetails.map((customer, customerIndex) => (
                                <OrdersWithDetails
                                    key={customerIndex}
                                    orders={customer.orders}
                                    customerId={customer.id}
                                    isAllOrderSelected={customer.is_selected}
                                    type='fromOrder'
                                    reAllocationChange={(orderIndex, index, event, productId) => handleReAllocationChange('fromOrder', customerIndex, orderIndex, index, event, productId)}
                                    prevValue={unChangedValue}
                                />))}

                            <Typography color="textSecondary" variant="body1" className="font-semibold text-xl mt-14 pt-14 mb-5">
                                From
                            </Typography>
                            <Autocomplete
                                options={options}
                                size='small'
                                sx={{ width: 300, marginTop: 2, marginBottom: 2 }}
                                value={selectedTo}
                                getOptionLabel={(opt) => opt.label}
                                isOptionEqualToValue={(opt) => opt.oid === selectedTo.oid}
                                onChange={onChangeHandler}
                                renderInput={(params) => <TextField {...params} label="Select Order" />}
                                disableClearable
                            />
                            {toOrder.map((customer, customerIndex) => {
                                return (
                                    <OrdersWithDetails
                                        key={customerIndex}
                                        orders={customer.orders}
                                        customerId={customer.id}
                                        isAllOrderSelected={customer.is_selected}
                                        type='toOrder'
                                        reAllocationChange={(orderIndex, index, event, productId) => handleReAllocationChange('toOrder', customerIndex, orderIndex, index, event, productId)}
                                        prevValue={unChangedValue}
                                    />)
                            })}
                        </>}
                </AuraBaseDialog>
            </FormProvider>
        </>
    ) : (
        <></>
    );
}

export default ReallocationDialog;
