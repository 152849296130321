import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';

export function ShowMessage(props) {
  return (
    <div className="flex flex-1 items-center justify-center h-full">
      <Typography color="textSecondary" variant="h5">
        {props.message}
      </Typography>
    </div>
  );
}

export function Loader({ style, height, className }) {
  return (
    <div className={`flex items-center justify-center h-[${height || '50vh'}] ${className || ''}`} style={style}>
      <CircularProgress />
    </div>
  );
}

function ListInfoMessage(props) {
  if (props.loading === false) {
    return <ShowMessage message={props.message} />;
  }
  return <ShowMessage message="Loading..." />;
}

export function DetailPageMessage({
  message,
  classes = 'flex flex-1 h-full pl-32',
  textColor = 'textSecondary',
  textVariant = 'subtitle1',
}) {
  return (
    <div className={classes}>
      <Typography color={textColor} variant={textVariant}>
        {message}
      </Typography>
    </div>
  );
}

export default ListInfoMessage;
