import i18next from 'i18next';

import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';
import authRoles from '../auth/authRoles';
import authTypes from '../auth/authTypes';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const adminNavigationConfig = [
  {
    id: 'applications',
    title: 'dashboard',
    translate: 'DASHBOARD',
    type: 'item',
    auth: authRoles.users,
    authType: authTypes.allSupplierTypes,
    icon: 'dashboard',
    url: 'dashboard',
  },
  {
    id: 'e-commerce-rfq',
    title: 'rfqs',
    translate: 'RFQ',
    type: 'item',
    auth: authRoles.users,
    authType: authTypes.defaultSupplierTypes,
    icon: 'request_quote',
    url: 'rfqs',
  },
  {
    id: 'e-commerce-quotes',
    title: 'quotes',
    translate: 'QUOTES',
    type: 'item',
    auth: authRoles.users,
    authType: authTypes.defaultSupplierTypes,
    icon: 'material-solid:monetization_on',
    url: 'quotes',
  },
  {
    id: 'e-commerce-rfd-open',
    title: 'RFP',
    type: 'item',
    auth: authRoles.onlySupplier,
    authType: authTypes.onlyArtDesign,
    icon: 'request_quote',
    url: 'rfd',
  },
  {
    id: 'e-commerce-rfd-completed',
    title: 'RFP Completed',
    type: 'item',
    auth: authRoles.onlySupplier,
    authType: authTypes.onlyArtDesign,
    icon: 'request_quote',
    url: 'completed',
  },
  {
    id: 'e-commerce-orders',
    title: 'Received Orders',
    translate: 'ORDERS',
    type: 'item',
    auth: authRoles.customer,
    // authType: authTypes.defaultSupplierTypes,
    icon: 'receipt_long',
    url: 'orders',
  },
  {
    id: 'e-commerce-recieved-orders',
    title: 'Received Orders',
    translate: 'RECIEVED_ORDERS',
    type: 'item',
    auth: authRoles.onlySupplier,
    authType: authTypes.defaultSupplierTypes,
    icon: 'receipt_long',
    url: 'orders',
  },
  {
    id: 'e-commerce-confirmed_orders',
    title: 'Confirmed Orders',
    translate: 'CONFIRMED_ORDERS',
    type: 'item',
    auth: authRoles.onlySupplier,
    authType: authTypes.defaultSupplierTypes,
    icon: 'receipt_long',
    url: 'confirmed',
  },
  {
    id: 'e-commerce-in-production',
    title: 'In Production',
    translate: 'In_PRODUCTION',
    type: 'item',
    auth: authRoles.onlySupplier,
    authType: authTypes.defaultSupplierTypes,
    icon: 'local_shipping',
    url: 'in-production',
  },
  {
    id: 'e-commerce-shipped-orders',
    title: 'Shipped Order',
    translate: 'SHIPPED_ORDER',
    type: 'item',
    auth: authRoles.onlySupplier,
    authType: authTypes.defaultSupplierTypes,
    icon: 'receipt_long',
    url: 'fulfilled',
  },
  {
    id: 'e-commerce-waiting-for-approval',
    title: 'Waiting for Approval',
    translate: 'WAITING_FOR_APPROVAL',
    type: 'item',
    auth: authRoles.onlyCustomer,
    icon: 'material-solid:av_timer',
    url: 'awaiting-approval',
  },
  {
    id: 'e-commerce-invoices',
    title: 'Invoices',
    translate: 'Invoices',
    type: 'item',
    auth: authRoles.users,
    authType: authTypes.defaultSupplierTypes,
    icon: 'receipt',
    url: 'invoices',
  },
  {
    id: 'e-commerce-supplier-invoices',
    title: 'Supplier Invoices',
    translate: 'Supplier_Invoices',
    type: 'item',
    auth: authRoles.onlyStaffAndAdmin,
    icon: 'assignment_returned',
    url: 'supplier-invoices',
  },
  {
    id: 'e-commerce-products',
    title: 'PRODUCTS',
    translate: 'PRODUCTS',
    type: 'item',
    auth: authRoles.onlyStaffAndAdmin,
    icon: 'shopping_basket',
    url: 'products',
  },
  {
    id: 'e-commerce-order-from-inventory',
    title: 'Order from Inventory',
    translate: 'ORDER_FROM_INVENTORY',
    type: 'item',
    auth: authRoles.onlyCustomer,
    icon: 'shopping_basket',
    url: 'products',
    sx: { backgroundColor: '#45ce22c2', borderRadius: "10px", marginLeft: '10px' }
  },
  {
    id: 'customers',
    title: 'Customers',
    translate: 'CUSTOMERS',
    type: 'item',
    auth: authRoles.staff,
    icon: 'group',
    url: 'customers',
  },
  {
    id: 'suppliers',
    title: 'Suppliers',
    translate: 'SUPPLIERS',
    type: 'item',
    auth: authRoles.staff,
    icon: 'engineering',
    url: 'suppliers',
  },
  {
    id: 'shipments',
    title: 'Shipments',
    translate: 'Shipments',
    type: 'group',
    auth: authRoles.staff,
    icon: 'local_shipping',
    children: [
      {
        id: 'inbound',
        title: 'Inbound Shipments',
        translate: 'INBOUNDSHIPMENTS',
        type: 'group',
        auth: authRoles.staff,
        children: [
          {
            id: 'container',
            title: 'Container Shipments',
            translate: 'CONTAINERSHIPMENTS',
            type: 'item',
            auth: authRoles.staff,
            url: 'shipments/containers',
          },
        ]
      },
      {
        id: 'outbound',
        title: 'Outbound Shipments',
        translate: 'OUTBOUNDSHIPMENTS',
        type: 'group',
        auth: authRoles.staff,
        children: [
          {
            id: 'create',
            title: 'New Shipment',
            translate: 'NEWSHIPMENT',
            type: 'item',
            auth: authRoles.staff,
            url: 'shipments/new',
          },
          {
            id: 'calendar',
            title: 'Calendar',
            translate: 'CALENDAR',
            type: 'item',
            auth: authRoles.staff,
            url: 'shipments/calendar',
          },
          {
            id: 'profiles',
            title: 'Shipment Profiles',
            translate: 'SHIPMENTPROFILES',
            type: 'item',
            auth: authRoles.staff,
            url: 'shipments/profiles',
          },
        ]
      },
    ],
  },
  {
    id: 'settings',
    title: 'Settings',
    translate: 'SETTINGS',
    type: 'group',
    auth: authRoles.admin,
    icon: 'supervisor_account',
    children: [
      {
        id: 'employees',
        title: 'Employees',
        translate: 'EMPLOYEES',
        type: 'item',
        auth: authRoles.admin,
        url: 'employees',
      },
      {
        id: 'configurations',
        title: 'Configurations',
        translate: 'CONFIGURATIONS',
        type: 'item',
        auth: authRoles.admin,
        url: 'settings/configurations',
      },
      {
        id: 'mappings',
        title: 'Mappings',
        translate: 'MAPPINGS',
        type: 'item',
        auth: authRoles.admin,
        url: 'settings/mappings',
      },
      {
        id: 'email-templates',
        title: 'Email Templates',
        translate: 'TEMPLATES',
        type: 'item',
        auth: authRoles.admin,
        url: 'settings/templates',
      },
      {
        id: 'company-profiles',
        title: 'Company Profiles',
        translate: 'PROFILES',
        type: 'item',
        auth: authRoles.admin,
        url: 'settings/profiles',
      },
    ],
  },
];

export default adminNavigationConfig;
