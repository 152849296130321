import { forwardRef, useEffect, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';

const AuraTableIndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <Checkbox ref={resolvedRef} {...rest} className="p-0" />;
});

export default AuraTableIndeterminateCheckbox;
