
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';


export const getReviewOrderDetail = createAsyncThunk(
    'auth/reviewOrder/getOrderDetail',
    async (token, { dispatch, getState }) => {
        const response = await axios.get(`/api/process/${token}`);
        console.log(response, "response");
        const data = await response.data;
        return data;
    }
);

export const approveReviewedOrder = createAsyncThunk(
    'auth/approvereviewedOrder/approveOrder',
    async ({ token, params }, { dispatch, getState }) => {
        const response = await axios.put(`/api/approval/${token}`, params);
        const data = await response.data;
        return data;
    }
)

export const reviewedOrderRevision = createAsyncThunk(
    'auth/approvereviewedOrder/approveOrder',
    async ({ token, params }, { dispatch, getState }) => {
        const response = await axios.put(`/api/approval/${token}`, params);
        const data = await response.data;
        return data;
    }
)

export const reviewedOrderReject = createAsyncThunk(
    'auth/approvereviewedOrder/approveOrder',
    async ({ token, params }, { dispatch, getState }) => {
        const response = await axios.put(`/api/approval/${token}`, params);
        const data = await response.data;
        return data;
    }
)

const initialState = {
    orderDetail: {},
    name: ''
};

const reviewOrderSlice = createSlice({
    name: 'auth/reviewOrder',
    initialState,
    reducers: {},
    extraReducers: {
        [getReviewOrderDetail.fulfilled]: (state, action) => {
            state.orderDetail = action.payload
        }
    },
});

export default reviewOrderSlice.reducer;