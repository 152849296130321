import DialogContent from '@mui/material/DialogContent';
import { useCallback, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { BootstrapDialog, BootstrapDialogTitle } from 'app/main/apps/common/AuraDialogHelper';
import { capitalize } from 'lodash';
import { AuraSimpleTableBody, AuraSimpleTableHead } from './AuraSimpleTable';

function AuraResponseDialog(props) {
  const { onClose, data, open, name } = props;
  const [tableHeaders, setTableHeaders] = useState([]);
  const [title, setTitle] = useState('Upload Response');
  const [dataFieldNames, setDataFieldNames] = useState([]);

  /**
   * Initialize Dialog with Data
   */
  const initDialog = useCallback(() => {
    if (name === 'products' && data.failed) {
      setTableHeaders([{ name: 'Index' }, { name: 'Name' }, { name: 'Description' }]);
      setDataFieldNames([{ name: 'index' }, { name: 'name' }, { name: 'description' }]);
    }
    if (name === 'orders' && data.failed) {
      setTableHeaders([{ name: 'Index' }, { name: 'ID' }, { name: 'Business Name' }]);
      setDataFieldNames([{ name: 'index' }, { name: 'legacy_id' }, { name: 'customer_company' }]);
    }
    setTitle(`Upload ${name === 'products' ? 'Products' : `${capitalize(name)}s`}`);
  }, [name, data]);

  /**
   * On Dialog Open
   */
  useEffect(() => {
    if (open && data) {
      initDialog();
    }
  }, [open, data, initDialog]);

  const getContent = () => {
    if (data.message) {
      return (<Typography variant="subtitle1" className={data.is_error ? "text-red" : "text-green"}>
        {data.message}
      </Typography>)
    }

    return (
      <>
        {data.failed ? (
          <Typography variant="subtitle1" className="text-red font-bold py-10">
            {data.failed_count} out of {data.total} {name} are failed to upload.
          </Typography>
        ) : (
          <>
            <Typography variant="subtitle1" className="text-green">
              All {name} are uploaded successfully.
            </Typography>
            <Typography variant="subtitle1" className="text-green text-capitalize">
              Total {name} count: <span className="font-bold">{data.total}</span>
            </Typography>
          </>
        )}
      </>
    )
  }

  return (
    <BootstrapDialog onClose={onClose} open={open} fullWidth maxWidth="md">
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        {title}
      </BootstrapDialogTitle>
      {data && (
        <div className="flex flex-col md:overflow-hidden ">
          <DialogContent classes={{ root: 'p-24' }}>
            <div className="flex flex-col w-full items-center justify-center">
              {getContent()}
            </div>
            {data.failed && (
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="table">
                  <AuraSimpleTableHead headerList={tableHeaders} />
                  <AuraSimpleTableBody dataList={data.failed} dataFieldList={dataFieldNames} />
                </Table>
              </TableContainer>
            )}
          </DialogContent>
        </div>
      )}
    </BootstrapDialog>
  );
}

export default AuraResponseDialog;
