import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import { Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { openViewOrderDialog, openOrderDialog } from '../e-commerce/store/orderSlice';
import {
  getEcomWidgetsOrderItems,
  getEcomWidgetsRfq,
} from '../../dashboards/main/store/widgetsSlice';

function OrderViewAction({ row, cell, stage, value, loadOrders, loadOrdersFun, toHide = undefined, showEditAction = false }) {
  const dispatch = useDispatch();
  const onCellClick = (ev) => {
    ev.stopPropagation();
  };
  let { handleOpen } = row;
  if (!handleOpen) {
    handleOpen = openViewOrderDialog;
  }
  const isInvoice = stage === 'invoice'
  const getDetails = () => {
    let stageValue = { id: '', key: '', widgetId: '' };
    switch (stage) {
      case 'invoice':
        stageValue = {
          id: row.original.invoice_no,
          key: 'invoice_id',
          widgetId: row.original.id
        }
        break;
      case 'container':
      case 'in-production':
        stageValue = {
          id: row.original.container_number,
          key: 'shipment_id',
          widgetId: row.original.shipment_id
        }
        break;
      default:
        stageValue = {
          id: row.original.order_no,
          key: 'oid',
          widgetId: row.original.id
        }
    }

    return stageValue;
  };

  const getStageTitle = (isTooltipTitle) => {
    const stageData = value || stage
    switch (stageData) {
      case 'fulfilled':
      case 'confirmed':
      case 'order':
        return `View Order Details`;
      case 'quote':
        return `View Quote Details`;
      case 'rfq':
        return `View Rfq Details`;
      case 'invoice':
        return `View Invoice`;
      case 'approval':
        return `View Approval Items`;
      case 'shipment':
        return 'View Shipment';
      case 'container':
        return 'View Container Shipment'
      case 'in-production':
        return 'View In-production'
      default:
        return "";
    }
  }

  const handleContainerEdit = () => {
    dispatch(
      openOrderDialog({
        completeOrderData: row.original,
        data: row.original,
        param: {
          name: 'container_number',
          title: 'Edit Container Number',
          successMessage: 'Success',
          loadOrdersFun,
          submitButtonName: 'Update'
        },
        stage,
      })
    );
  }

  return (
    <div
      onClick={onCellClick}
      onKeyDown={onCellClick}
      role="button"
      tabIndex="0"
      style={{ cursor: 'default', pointerEvents: 'auto' }}
      className="flex flex-row"
    >
      <Tooltip title={getStageTitle(true)}>
        <Typography
          className="py-0 px-2"
          sx={{ cursor: 'pointer' }}
          onClick={(ev) => {
            dispatch(getEcomWidgetsOrderItems({ [getDetails().key]: getDetails().widgetId, stage: stage === 'container' ? 'in-production' : stage, invoiceId: true })).then((data) => {
              if (data && data.payload) {
                // setOrderItems(data.payload);
                dispatch(
                  handleOpen({
                    completeOrderData: row.original,
                    stage: stage === 'container' ? 'in-production' : stage,
                    data: stage === 'invoice' ? data.payload.invoice : data.payload,
                    param: {
                      name: 'viewOrder',
                      type: 'View',
                      title: getStageTitle(false),
                      showDialogActions: true,
                      is_vas_order: row.original?.is_vas_order,
                      packingSlips: data.payload?.packing_slips || [],
                      supplierInvoices: data.payload?.supplier_invoices || [],
                      loadOrders,
                      toHide,
                    },
                  })
                );
              }
            })
          }
          }
          size="small"
        >
          {getDetails().id}
        </Typography>
      </Tooltip>
      {
        showEditAction && (
          <IconButton
            className="py-0 px-2"
            size="small"
            onClick={() => handleContainerEdit()}
          >
            <Icon fontSize="small">edit</Icon>
          </IconButton>
        )
      }
    </div>
  );
}

export default OrderViewAction;
