import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

export default function AuraAutoComplete({
  readOnly,
  placeholder,
  inputValues,
  onValueChange,
  defaultValue = null,
  sxStyle = null,
  hideClearable = true,
  freeSolo = false,
  optionDisplayName = 'name',
  isOptionEqualToValue = null,
  renderOption = null,
  filterOptions = undefined,
  className = '',
  error,
  helperText,
  variant,
}) {
  const sxValue = sxStyle || {
    width: 250,
    '.MuiInput-input': {
      padding: '0 !important',
    },
  };

  function listOption(props1, option) {
    return (
      <Box component="li" {...props1}>
        {option[optionDisplayName]}
      </Box>
    );
  }

  return (
    <Autocomplete
      readOnly={readOnly}
      id="size-small-standard"
      className={className}
      sx={sxValue}
      size="small"
      options={inputValues || []}
      getOptionLabel={(option) => {
        if (option) {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          if (option.name) {
            return option.name;
          }
          return '';
        }
        return '';
      }}
      disableClearable={hideClearable}
      freeSolo={freeSolo}
      onChange={(event, newValue) => {
        onValueChange(newValue);
      }}
      {...(freeSolo ? { onInputChange: (e, value) => onValueChange(value) } : {})}
      value={defaultValue}
      isOptionEqualToValue={isOptionEqualToValue}
      renderOption={renderOption || listOption}
      renderInput={(params) => (
        <TextField {...params} variant={variant || "standard"} placeholder={placeholder} className={className} InputProps={{...params.InputProps,
          endAdornment: freeSolo ? (
            <InputAdornment position="end">
              <ArrowDropDownIcon />
            </InputAdornment>
          ) : params.InputProps.endAdornment,
        }} error={error} helperText={helperText} />
      )}
    />
  );
}
