import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const resetPassword = createAsyncThunk(
  'auth/reset/resetPassword',
  async (param, { dispatch, getState }) => {
    const response = await axios.post('/api/auth/password-reset', param);
    const data = await response.data;
    return data;
  }
);

export const checkLink = createAsyncThunk(
  'auth/reset/resetPassword',
  async (param, { dispatch, getState }) => {
    const response = await axios.post('/api/auth/check-link', param);
    const data = await response.data;
    return data;
  }
);

export const sendResetLink = createAsyncThunk(
  'auth/reset/sendResetLink',
  async (param, { dispatch, getState }) => {
    const response = await axios.post('/api/auth/password/email', param);
    const data = await response.data;
    return data;
  }
);

const initialState = {
  success: false,
  errors: [],
};

const resetPasswordSlice = createSlice({
  name: 'auth/reset',
  initialState,
  reducers: {},
  extraReducers: {},
});

export default resetPasswordSlice.reducer;
