import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    backgroundColor: '#FFFFFF',
    color: '#111827',
    paddingBottom: theme.spacing(4),
  },
  '& .MuiTableCell-root': {
    color: '#111827',
    border: '0px',
    borderBottom: '1px solid rgba(81, 81, 81, 1)',
  },
  '& .MuiTableContainer-root': {
    marginBottom: theme.spacing(4),
  },
}));

export const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <AppBar position="static" elevation={0}>
      <Toolbar className="flex w-full">
        <Typography variant="subtitle1" color="inherit">
          {children}{' '}
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
