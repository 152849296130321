import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import jwtService from '../../auth/services/jwtService';
import { setUser, completeVerify, setDynamicActions } from '../userSlice';

export const submitLogin =
  ({ email, password, rememberMe }) =>
    async (dispatch) => {
      return jwtService
        .signInWithEmailAndPassword(email, password, rememberMe)
        .then((user) => {
          dispatch(setUser(user));
          return dispatch(loginSuccess());
        })
        .catch((errors) => {
          return dispatch(loginError(errors.response.data));
        });
    };

export const verifyLogin = createAsyncThunk(
  'auth/login/verifyLogin',
  async (param, { dispatch, getState, t }) => {
    try {
      const response = await axios.post('/api/verify', param);
      const data = await response.data;
      dispatch(completeVerify());
      const workflowData = await (await axios.get('/api/workflowactions')).data;
      dispatch(setDynamicActions(workflowData));
      return dispatch(verificationSuccess());
    } catch (errors) {
      return dispatch(verificationError(errors.response.data));
    }
  }
);

export const resendLoginVerification = createAsyncThunk(
  'auth/login/resendLoginVerification',
  async (dispatch) => {
    try {
      const response = await axios.get('/api/verify/resend');
      const data = await response.data;
      return data;
    } catch (errors) {
      return errors;
    }
  }
);

const initialState = {
  success: false,
  verify: false,
  errors: [],
  errorMessage: null,
  verificationSuccess: false,
  verificationErrorMessage: null,
};

const loginSlice = createSlice({
  name: 'auth/login',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.success = true;
      state.errors = [];
      state.verify = action.payload && action.payload.verify ? action.payload.verify : false;
    },
    loginError: (state, action) => {
      state.success = false;
      state.errors = action.payload && action.payload.errors ? action.payload.errors : [];
      state.errorMessage = action.payload.message;
    },
    verificationSuccess: (state, action) => {
      state.verificationSuccess = true;
      state.verificationErrorMessage = null;
    },
    verificationError: (state, action) => {
      state.verificationSuccess = false;
      state.verificationErrorMessage = action.payload.message;
    },
  },
  extraReducers: {},
});

export const { loginSuccess, loginError, verificationSuccess, verificationError } =
  loginSlice.actions;

export default loginSlice.reducer;
