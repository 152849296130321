/**
 * Authorization Types
 */
const authTypes = {
    allSupplierTypes: ['Art/Design', 'Stone', 'Etching', 'Sandblasting'],
    onlyArtDesign: ['Art/Design'],
    onlyStone: ['Stone'],
    onlyEtching: ['Etching'],
    onlySandblasting: ['Sandblasting'],
    defaultSupplierTypes: ['Stone', 'Etching', 'Sandblasting']
  };
  
  export default authTypes;
  