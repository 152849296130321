import React, { useEffect, useRef, useState } from 'react';
import { AuraSimplePageRoot } from 'app/main/apps/common/AuraFunctions';
import AuraHeader from 'app/main/apps/common/AuraHeader';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from 'react-router-dom';
import ShippingLabelDetails from '../tabs/ShippingLableDetails';

const ShippingLabelContent = () => {
    const [isPrinting, setIsPrinting] = useState(false);
    const labelRef = useRef()
    const promiseResolveRef = useRef(null);
    const location = useLocation()
    const { pathname } = location
    const isprintProduction = pathname.split("/").includes("printProduction")

    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const handleLabelDownload = useReactToPrint({
        content: () => labelRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
        },
        pageStyle: `
        @page {
          size: A3;
        }
        @media print {
            body {
                padding: 20px;
                font-size: 12px;
                -webkit-print-color-adjust: exact;
              }
            .scale-content {
              transform: scale(0.6); /* Scale content to 60% */
              transform-origin: top left; /* Set transformation origin */
            }
            .element-with-spacing {
                margin-bottom: 20mm!important; /* Add spacing between elements */
                page-break-inside: avoid!important; /* Avoid breaking the element between pages */
              }
          }
      `,
        documentTitle: isprintProduction ? 'print-production-sheet.pdf' : `shipping-label.pdf`,
    });

    const headerData = {
        headerTitle: isprintProduction ? 'Print Production Sheet' : 'Shipment Label',
        filter: {
            component: null,
        },
        search: {
            enabled: false,
        },
        updateShow: true,
        links: [
            {
                id: 1,
                title: `Download`,
                type: 'save',
                value: 'Download',
                show: true,
                onClick: handleLabelDownload,
            },
            {
                id: 2,
                type: 'back',
                value: 'Shipments',
                link: '/in-production',
                show: true,
            }
        ],
    };

    return <AuraSimplePageRoot
        header={<AuraHeader {...headerData} />}
        content={<ShippingLabelDetails labelRef={labelRef} isPrinting={isPrinting} />}
        sidebarInner
    />
};

export default ShippingLabelContent;
