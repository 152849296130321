import TextField from '@mui/material/TextField';
import AuraControlledAutocomplete from 'app/main/apps/common/AuraControlledAutocomplete';
import { Controller, useFormContext } from 'react-hook-form';
import Documents from '../Documents';

function CompleteShipmentFormContent(props) {
    const methods = useFormContext();
    const { control, formState: { errors } } = methods;

    return (
        <>
            <div>
                <div className="flex flex-col md:flex-row w-full gap-10">
                    <div className="flex w-full ">
                        <Controller
                            control={control}
                            name="container_tracking_number"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    className="mb-8"
                                    label="Container Tracking Number"
                                    id="container_tracking_number"
                                    variant="outlined"
                                    size="small"
                                    required
                                    fullWidth
                                    error={!!errors.container_tracking_number}
                                    helperText={errors?.container_tracking_number?.message}
                                />
                            )}
                        />
                    </div>
                    <div className="flex w-full ">
                        <AuraControlledAutocomplete
                            control={control}
                            controlName="shipping_company"
                            inputValues={props.options || []}
                            labelName="Shipping Company *"
                            hideClearable
                            className="mb-24 mt-0"
                            fullWidth
                            size="small"
                        />
                    </div>

                </div>
                <div className="flex flex-col md:flex-row w-full gap-10">
                    <div className="flex w-full ">
                        <Controller
                            control={control}
                            name="invoice_number"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    className="mb-24"
                                    type="text"
                                    label="Invoice Number"
                                    id="invoice_number"
                                    variant="outlined"
                                    error={!!errors.invoice_number}
                                    helperText={errors?.invoice_number?.message}
                                    size="small"
                                    fullWidth
                                    required
                                />
                            )}
                        />
                    </div>
                    <div className="flex w-full ">
                        <Controller
                            control={control}
                            name="eta_date"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    className="mb-24"
                                    type='date'
                                    label="ETA Date"
                                    id="eta_date"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        min: new Date().toISOString().split('T')[0], // Disable past dates
                                    }}
                                    size="small"
                                />
                            )}
                        />
                    </div>

                </div>
                <Documents heading="Packing slip and Containerized picture" attachmentsProps={{
                    newAttachmentsType: 'packing_slips',
                    attachmentKey: 1
                }} />
                <Documents heading="Supplier Invoice" attachmentsProps={{
                    newAttachmentsType: 'supplier_invoice',
                    attachmentKey: 2
                }} />
            </div>
        </>
    );
}

export default CompleteShipmentFormContent;
