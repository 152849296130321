import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import { Button, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, lowerCase, upperCase } from 'lodash';
import { isCustomer, isSupplier } from 'app/main/apps/common/AuraFunctions';
import { openReallocationDialog, getOrderStatuses } from 'app/main/apps/e-commerce/store/ordersSlice';
import { selectActions, selectUser } from 'app/store/userSlice';
import { convertToOrder, getOrderDetails, getQuotedSuppliers, getShippingLabelData, openChooseSupplierDialog, openSupplierShippingDialog, performAction, updateOrder } from 'app/main/apps/e-commerce/store/orderSlice';
import { openProductDialog } from 'app/main/apps/e-commerce/store/productSlice';
import { getSuppliers } from 'app/main/apps/e-commerce/store/rfqsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import downloadFile from 'app/main/apps/common/AuraDownload';
import { useFormContext } from 'react-hook-form';
import { openViewOrderDialog, makeItReview, closeViewOrderDialog, getEcomWidgetsOrderItems, viewNote, getOrderNotes, getShipmentInfo } from '../store/widgetsSlice';
import MenuActions from '../dialogs/MenuActions';


function OpenOrdersRowAction({
  order,
  stage,
  orderId,
  statusGroup,
  statusName,
  isManage,
  loadOrders,
  orderData,
  popupButtons,
  closeDialog = undefined,
  type = undefined,
  approveProof,
  approveNote,
  listData, selectedImages
}) {
  const dispatch = useDispatch();
  const allaActions = useSelector(selectActions);
  const user = useSelector(selectUser);
  const stageAction = allaActions.filter((action) => action.stage === stage);
  let actions = stageAction.find((action) =>
    (action.fromStatusesTypeCode === order.status.system_code) && action.fromStatusesTypeCode)?.actionList[0];

  const methods = useFormContext()
  const { setError, getValues } = methods || {}

  if (actions) actions = JSON.parse(actions).filter((action) => action.dashboardAction === true);
  if (type && actions) actions = actions.filter(el => !(['cancel', 'visibility', 'alarm'].includes(el.icon)))

  if (order?.is_stone_order && !order?.is_proof_approval && actions) {
    actions = actions.filter(el => el.name !== 'Create Supplier Order')
  }
  if (!order?.is_stone_order && order?.is_proof_approval && actions) {
    actions = actions.filter(el => el.name !== 'Create Stone Order')
  }


  let count = -1;
  const showMore = actions?.length > 3;
  const key = stage === 'invoice' ? 'invoice_id' : 'oid'
  const allowActions = [
    'EDIT_ORDER',
    'EDIT_QUOTE',
    'VIEW_ORDER',
    'VIEW_QUOTE',
    'CONVERT_TO_QUOTE',
    'CONVERT_TO_PROOF',
    'EDIT_RFQ',
    'VIEW_RFQ',
    'EDIT',
    'SHIPMENT_PRINT',
    'EDIT_SHIPMENT',
    'PRINT_PRODUCTION',
    'STATUS_UPDATE',
    'REVISE_QUOTE',
    'REVIEW_NOTE'
  ];

  const basedOnImageCustomzation = () => {
    let Key = ''
    if (orderData.image_customisation) {
      Key = 'update_status_with_attachment'
    }
    else {
      Key = 'updateStatus'
    }
    return Key
  }

  const getTitle = () => {
    if (['in-production', 'fulfilled', 'confirmed'].includes(stage)) {
      return 'order'
    }
    if (stage === 'completed') return 'rfd'
    return stage
  }

  const buildSelectedDrawing = (selectedImagesData, fullDetails) => {
    if (fullDetails?.length === 0) return []
    return fullDetails.map((detail, index) => {
      const selectedDocs = selectedImagesData[index] || [];
      const selectedDocIds = selectedDocs.map(doc => doc.id);

      const filteredDocuments = detail?.drawing_documents?.filter(doc => selectedDocIds.includes(doc.id));
      return {
        ...detail,
        vas: detail?.vas ? JSON.stringify(detail.vas) : null,
        drawing_documents: filteredDocuments
      };
    });
  };


  const confirmation = (toStatusCode, id, otherPayload = {}, code, approvalPayload) => {
    let action;
    const performApi = ['MAKE_IN_PROGRESS', 'CANCEL_INVOICE', 'SEND_REMINDER', 'RFQ_REMINDER', 'UPLOAD_DOCUMENT'].includes(id)
    const isApproval = !performApi && !['RECEIVE_ORDER', 'STATUS_UPDATE', 'REJECT_QUOTE'].includes(id)

    if (performApi)
      action = dispatch(performAction({
        ui_id: id,
        data: {
          [id === 'UPLOAD_DOCUMENT' ? 'shipment_id' : 'id']: orderId,
          ...(id === 'UPLOAD_DOCUMENT' && { orders: [...approvalPayload] })
        },

      }));
    else
      action = dispatch(
        updateOrder({
          id: orderId,
          to_statuses_type_id: code || toStatusCode,
          [isApproval ? 'update_status_with_attachment' : basedOnImageCustomzation()]: 'yes',
          ...otherPayload
        })
      )
    action.then((res) => {
      if (!res.error) {
        dispatch(showMessage({ type: 'success', message: 'Success' }));
        if (loadOrders) {
          loadOrders();
        }
        dispatch(closeViewOrderDialog());
      } else {
        throw new Error(res.data.message ? res.data.message : 'Failed to update');
      }
    }).catch((error) => {
      dispatch(showMessage({ type: 'error', message: error.message }));
    });
  };

  const defaultAction = [
    {
      dashboardAction: true,
      icon: "preview",
      name: `View ${stage === 'completed' ? upperCase(getTitle()) : capitalize(getTitle())}`,
      to_statuses_types_code: "",
      to_statuses_types_id: "",
      ui_id: `VIEW_${upperCase(getTitle())}`,
    }
  ]

  const paramForSupplier = ['art/design', 'stone']
  const workFlowAction = ['Send to Supplier for Proof', 'Send to Supplier']


  const checkData = (data) => {
    let validProduct = true;
    data.every((product) => {
      if (!product.color || !product.type || !product.length || !product.width || !product.height) {
        validProduct = false;
      }
      return null;
    })

    return validProduct;

  }

  const getApprovalResetData = (data, showDocuments = false) => {
    const resetData = { order_details: [] }
    data?.forEach((item) => {
      resetData.order_details.push(
        {
          id: item.id,
          vas: item.vas,
          showDocuments
        }
      )
    })
    return resetData
  }

  const exportShipment = (isPrice) => {
    return (
      downloadFile({ params: { id: orderId, is_price: isPrice }, endPoint: '/api/exportShipment' })
        .then(() => {
          dispatch(showMessage({ type: 'success', message: 'File downloaded successfully' }))
          dispatch(closeViewOrderDialog({ field: 'orderDialog', }))
        })
        .catch(() => dispatch(showMessage({ type: 'error', message: 'Failed to download' })))

    )
  }

  const additionalButton = () => {
    return (
      <div className='flex gap-10'>
        <Button
          variant="contained"
          color="secondary"
          type="button"
          onClick={() => { exportShipment(true) }}
        >
          Export Shipment With Price
        </Button>
        <Button
          variant="contained"
          color="secondary"
          type="button"
          onClick={() => { exportShipment(false) }}
        >
          Export Shipment Without Price
        </Button>
      </div>
    )
  }

  const handleClick = (id, toStatusCode, name, toStatusId) => {
    console.log('id', id)
    console.log('stage', stage)

    switch (id) {
      case 'ADD_NOTE':
        dispatch(
          openViewOrderDialog({
            completeOrderData: orderData,
            data: { id: orderId },
            param: {
              name: 'note',
              title: 'Add Note',
              successMessage: 'Your note has been sent successfully',
            },
            field: 'orderDialog',
            stage
          })
        );
        break;

      case 'QUICK_EDIT':
        dispatch(getOrderDetails(({ id: orderId, params: {} }))).then(({ payload }) => {
          dispatch(openViewOrderDialog({
            completeOrderData: orderData,
            data: payload,
            param: {
              name: 'edit_order',
              title: `Edit ${stage.replace(/^\w/, (c) => c.toUpperCase())} (Basic Info)`,
              successMessage: 'Success',
              submitButtonName: 'Save',
              loadOrdersFun: loadOrders,
            },
            field: 'orderDialog',
            stage
          }))
        })
        break;

      case 'CONFIRM_INVOICE':
        dispatch(
          openViewOrderDialog({
            completeOrderData: orderData,
            data: { id: orderId },
            param: {
              name: 'confirm_invoice',
              title: 'Confirm Invoice',
              successMessage: 'Invoice has been confirmed and sent to the customer',
              loadOrdersFun: loadOrders
            },
            field: 'orderDialog',
            stage
          })
        );
        break;

      case 'VIEW_NOTE':
        dispatch(viewNote({ id: orderData.id })).then((data) => {
          dispatch(
            openViewOrderDialog({
              completeOrderData: orderData,
              data: data.payload,
              param: {
                name: 'note',
                title: 'View Note',
              },
              field: 'orderDialog',
              stage
            })
          );

        })
        break;
      case 'VIEW_IN PRODUCTION':
      case 'VIEW_ORDER':
      case 'VIEW_QUOTE':
      case 'VIEW_RFQ':
      case 'VIEW_RFD':
      case 'VIEW_PAYMENT':
        if (!isManage || ['VIEW_PAYMENT'].includes(id))
          dispatch(getEcomWidgetsOrderItems({ [key]: orderData.id, stage })).then(
            (data) => {
              if (data && data.payload) {
                // setOrderItems(data.payload);
                dispatch(
                  openViewOrderDialog({
                    completeOrderData: orderData,
                    data: data.payload,
                    param: {
                      name: id === 'VIEW_PAYMENT' ? 'payment' : 'viewOrder',
                      type: 'View',
                      title: id === 'VIEW_PAYMENT' ? 'Payment History' : `View ${capitalize(getTitle())} Items`,
                      // showDialogActions: !isManage ? true : null,
                      loadOrders,
                    },
                    stage,
                  })
                );
              }
            }
          );
        break;

      case 'REVIEW_ORDER':
        dispatch(getEcomWidgetsOrderItems({ [key]: orderData.id, stage })).then(
          (data) => {
            if (data && data.payload) {
              dispatch(
                openViewOrderDialog({
                  completeOrderData: orderData,
                  data: data.payload,
                  param: {
                    name: 'Review',
                    type: 'View',
                    title: `Review ${capitalize(getTitle())}`,
                    showDialogActions: !isManage ? true : null,
                    loadOrders,
                  },
                  stage,
                })
              );
            }
          }
        );
        break;

      case 'REVIEW_QUOTE':
        dispatch(getEcomWidgetsOrderItems({ [key]: orderData.id, stage })).then(
          (data) => {
            if (data && data.payload) {
              dispatch(
                openViewOrderDialog({
                  completeOrderData: orderData,
                  data: data.payload,
                  param: {
                    name: 'Review Quote',
                    type: 'View',
                    title: name,
                    showDialogActions: true,
                    hideDialogActions: true,
                    loadOrders,
                  },
                  stage,
                })
              );
            }
          }
        );
        break;


      case 'CONVERT_TO_ORDER':
        dispatch(getEcomWidgetsOrderItems({ [key]: orderId, stage })).then((data) => {
          if (data && data.payload) {
            dispatch(
              openViewOrderDialog({
                completeOrderData: orderData,
                data: data.payload,
                param: {
                  name,
                  type: 'approve',
                  title: name,
                  submitButtonName: name,
                  toStatusCode,
                  onSubmit: () => {
                    dispatch(convertToOrder({ id: orderId, to_status_id: toStatusId }))
                      .unwrap()
                      .then(loadOrders)
                  },
                },
                stage,
              }))
          }
        });
        break;

      case 'RE_ALLOCATION':
        dispatch(
          openReallocationDialog({
            param: {
              name: 'reallocation',
              title: 'Re Allocation',
              submitButtonName: 'Submit',
              selectedFrom: orderData.id,
            },
            stage
          })
        );
        break;

      case 'CONFIRM_ORDER':
        dispatch(
          openViewOrderDialog({
            completeOrderData: orderData,
            data: {
              id: orderId,
              action: 'status',
              status: 'Confirmed',
              order_no: orderData.order_no
            },
            param: {
              name: 'confirm',
              title: 'Confirm Order',
              submitButtonName: 'Confirm Order',
              loadOrdersFun: loadOrders,
              successMessage: 'Order status has been updated successfully',
            },
            field: 'orderDialog',
            stage
          }),
          true
        );
        break;

      case 'SEND_TO_SUPPLIER':
        dispatch(getEcomWidgetsOrderItems({ oid: orderData.id, stage })).then(data => {
          if (checkData(data.payload)) {
            if (data && data.payload) {
              dispatch(
                openViewOrderDialog({
                  completeOrderData: orderData,
                  data: data.payload,
                  param: {
                    id: orderData.id,
                    name: 'send to supplier',
                    type: 'View',
                    title: name,
                    submitButtonName: name,
                    loadOrdersFun: loadOrders,
                    action: name
                  },
                  stage
                }))
            }
          } else {
            dispatch(showMessage({
              message: 'Products related fields are missing Please fill in before sending to supplier',
              variant: 'error',
              autoHideDuration: null,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
            }))
          }
        })
        if (workFlowAction.includes(name)) {
          const index = workFlowAction.indexOf(name)
          const params = { type: paramForSupplier[index] }
          dispatch(getSuppliers({ suppliers: orderData.suppliers || [], params }));
        }
        break;

      case 'CHOOSE_SUPPLIER':
        dispatch(
          openChooseSupplierDialog({
            param: orderData.id,
            completeOrderData: orderData,
            name,
            status: orderData.status.name,
            stage
          })
        );
        break;


      case 'CREATE_VAS_ORDER':
      case 'CREATE_SUPPLIER_ORDER':
        if (orderData.show_inventory_dialog) {
          dispatch(openProductDialog({
            param: {
              name: 'supplierOrderDirectShipment',
              title: name,
              orderNo: orderData.id,
              stage,
              loadOrdersFun: loadOrders,
              action: name
            },
          }))
        }
        else {
          dispatch(
            openViewOrderDialog({
              completeOrderData: orderData,
              param: {
                id: orderData.id,
                name: 'create supplier order',
                type: 'View',
                title: name,
                maxWidth: 'md',
                submitButtonName: name,
                loadOrdersFun: loadOrders,
                action: name
              },
              stage
            })
          );
        }
        dispatch(getQuotedSuppliers({ id: orderData.id }));
        break;

      case 'VIEW_SHIPPING_INFO':
      case 'CREATE_SHIPPING':
        dispatch(
          openSupplierShippingDialog({
            param: orderData.id,
            completeOrderData: orderData,
            type: id === 'VIEW_SHIPPING_INFO' ? 'view' : 'create',
          })
        );
        break;

      case 'SEND_FOR_ORDER_APPROVAL':
      case 'REJECT_DRAWING':
      case 'REJECT':
      case 'REQUEST_REVISION_FOR_DRAWING':
      case 'REQUEST_REVISION_FOR_PROOF':
      case 'APPROVE_DRAWING':
      case 'APPROVE_PROOF':
      case 'SEND_FOR_DRAWING_APPROVAL':
      case 'SEND_FOR_PROOF_APPROVAL':
      case 'MAKE_IN_PROGRESS':
      case 'RECEIVE_ORDER':
      case 'CANCEL_INVOICE':
      case 'SEND_REMINDER':
      case 'RFQ_REMINDER':
      case 'SEND_PRICE_APPROVAL_FOR_VAS':
      case 'APPROVE_VAS_PRICE':
      case 'MAKE_SHIPMENT':
      case 'REQUEST_REVISION_FOR_VAS_PRICE':
        dispatch(getEcomWidgetsOrderItems({ [key]: orderId, stage })).then((data) => {
          console.log('orderData.is_proof_approval', orderData.is_proof_approval)
          let submitButtonName = name;
          if (name === "Review VAS Price") {
            submitButtonName = "Send Price Approval for VAS"
          }
          if (name === 'Receive Order') {
            submitButtonName = 'Order Receive'
          }
          if (data && data.payload) {
            dispatch(
              openViewOrderDialog({
                completeOrderData: orderData,
                data: data.payload,
                param: {
                  name,
                  type: 'approve',
                  title: name,
                  submitButtonName,
                  toStatusCode,
                  is_proof_approval: id === 'SEND_PRICE_APPROVAL_FOR_VAS' ? false : orderData.is_proof_approval,
                  onSubmit: (formData, code) => confirmation(toStatusCode, id, formData, code),
                  showApproveButton: id === 'SEND_PRICE_APPROVAL_FOR_VAS',
                  showPriceField: id !== 'MAKE_IN_PROGRESS',
                  sendFormValue: ['SEND_PRICE_APPROVAL_FOR_VAS', 'SEND_FOR_ORDER_APPROVAL'].includes(id),
                  resetFormData: getApprovalResetData(data.payload, id === 'SEND_PRICE_APPROVAL_FOR_VAS'),
                  resetForm: true,
                  actionName: id,
                  showAttachment: ['SEND_FOR_ORDER_APPROVAL', 'SEND_FOR_PROOF_APPROVAL', 'SEND_FOR_DRAWING_APPROVAL', 'MAKE_IN_PROGRESS'].includes(id),
                },
                stage,
              }))
          }
        });
        break;

      case 'STATUS_UPDATE':
        dispatch(getEcomWidgetsOrderItems({ [key]: orderId, stage })).then((data) => {
          if (data && data.payload && stage !== 'rfq' && isManage) {
            dispatch(
              openViewOrderDialog({
                completeOrderData: orderData,
                data: data.payload,
                param: {
                  name,
                  type: 'approve',
                  title: name,
                  submitButtonName: name === "Review VAS Price" ? "Send Price Approval for VAS" : name,
                  toStatusCode,
                  onSubmit: (formData, code) => confirmation(toStatusCode, id, formData, code),
                  showApproveButton: id === 'SEND_PRICE_APPROVAL_FOR_VAS',
                  showPriceField: true,
                  sendFormValue: id === 'SEND_PRICE_APPROVAL_FOR_VAS',
                  resetFormData: getApprovalResetData(data.payload, id === "SEND_PRICE_APPROVAL_FOR_VAS"),
                  resetForm: true,
                  showAttachment: ['SEND_FOR_ORDER_APPROVAL', 'SEND_FOR_PROOF_APPROVAL', 'SEND_FOR_DRAWING_APPROVAL', 'MAKE_IN_PROGRESS'].includes(id),
                },
                stage,
              }))
          }
        });
        break;

      case 'UPLOAD_DOCUMENT':
        dispatch(getShipmentInfo({ id: orderId })).then((data) => {
          if (data && data.payload) {
            dispatch(
              openViewOrderDialog({
                completeOrderData: orderData,
                data: data.payload,
                param: {
                  name,
                  type: 'approve',
                  title: name,
                  submitButtonName: name,
                  toStatusCode,
                  onSubmit: (formData, code, approvalPayload) => confirmation(toStatusCode, id, formData, code, approvalPayload),
                  showPriceField: true,
                  isApproval: orderData.is_approval_type,
                  documentApproval: true
                },
                stage,
              }))
          }
        });
        break;

      case 'REJECT_QUOTE':
        dispatch(getEcomWidgetsOrderItems({ [key]: orderId, stage })).then(
          (data) => {
            if (data && data.payload) {
              dispatch(
                openViewOrderDialog({
                  completeOrderData: orderData,
                  data: data.payload,
                  param: {
                    name: 'rejectQuote',
                    title: name,
                    successMessage: 'Your note has been sent successfully',
                    submitButtonName: name,
                    onSubmit: ({ note }) => {
                      confirmation(toStatusCode, id, { note })
                    }
                  },
                  field: 'orderDialog',
                  stage
                })
              );
            }
          }
        );
        break;

      case 'MAKE_PAYMENT':
        dispatch(
          openViewOrderDialog({
            completeOrderData: orderData,
            data: order,
            field: 'orderDialog',
            param: {
              name: 'payment',
              title: 'Add Payment',
              successMessage: 'Success',
              loadOrdersFun: loadOrders,
            },
            stage,
          })
        );
        break;

      case 'VIEW_INVOICE':
        dispatch(
          openViewOrderDialog({
            completeOrderData: orderData,
            data: order,
            field: 'orderDialog',
            param: {
              name: 'invoice',
              title: 'Invoice Details',
            },
            stage,
          })
        );
        break;

      case 'STATUS_CHANGE':
        dispatch(
          openViewOrderDialog({
            completeOrderData: orderData,
            data: order,
            field: 'orderDialog',
            param: {
              name: 'status',
              title: 'Update Status',
              loadOrdersFun: loadOrders
            },
            stage
          })
        );
        break;

      case 'CREATE_DIRECT_SHIPMENT_SUPPLIER_ORDER':
        dispatch(
          openProductDialog({
            param: {
              name: 'supplierOrderDirectShipment',
              title: 'Create Supplier Order',
              orderNo: orderData.id,
              loadProducts: loadOrders,
              stage,
              action: name
            },
          })
        );
        break;


      case 'EXPORT_SHIPMENT':
        downloadFile({ params: { id: orderId }, endPoint: '/api/exportShipment' })
          .then(() => dispatch(showMessage({ type: 'success', message: 'File downloaded successfully' })))
          .catch(() => dispatch(showMessage({ type: 'error', message: 'Failed to download' })))
        break;


      case 'EXPORT_SHIPMENT_WITH_PRICE':
        dispatch(openViewOrderDialog({
          completeOrderData: orderData,
          data: { id: orderId },
          param: {
            name: '',
            title: name,
            submitButtonName: name,
            loadOrdersFun: loadOrders,
            hideDialogActions: true,
            additionalButton: additionalButton()
          },
          field: 'orderDialog',
          stage,
        }))
        break;


      case 'EXPORT_INVOICE_CUSTOMER':
        downloadFile({ params: { id: orderId, }, endPoint: '/api/exportInvoice' })
          .then(() => dispatch(showMessage({ type: 'success', message: 'File downloaded successfully' })))
          .catch(() => dispatch(showMessage({ type: 'error', message: 'Failed to download' })))
        break;

      case 'COMPLETE_AND_SHIP':
        dispatch(openViewOrderDialog({
          completeOrderData: orderData,
          data: { id: orderId },
          param: {
            name: 'complete_shipment',
            title: name,
            successMessage: 'Completed And Shipped',
            submitButtonName: name,
            loadOrdersFun: loadOrders,
            maxWidth: 'md'
          },
          field: 'orderDialog',
          stage
        }))
        break;

      case 'GENERATE_INVOICE':
        dispatch(openViewOrderDialog({
          completeOrderData: orderData,
          data: { id: orderId },
          param: {
            name: 'generate_invoice',
            title: name,
            successMessage: 'Invoice has been generated',
            submitButtonName: name,
            loadOrdersFun: loadOrders,
          },
          field: 'orderDialog',
          stage
        }))
        break;

      default:
        break;
    }

  };

  const pathFunction = (uiId) => {
    switch (uiId) {
      case 'EDIT':
      case 'EDIT_RFQ':
      case 'EDIT_ORDER':
      case 'EDIT_QUOTE':
      case 'VIEW_ORDER':
      case 'VIEW_QUOTE':
        if (isManage)
          return `/${stage}s/${order.id}`;
        return ''
      case 'VIEW_RFQ':
        if (isManage) {
          return `/rfq/${order.id}`
        }
        return ''
      case 'REVISE_QUOTE':
        if (isManage) {
          return `/${stage}s/${order.id}?type=revise-quote`
        }
        return ''
      case 'REVIEW_NOTE': {
        let title = ''
        let redirectionType = `${stage}s`
        if ((isCustomer(user.role) || isSupplier(user.role)) && !['rfq', 'quote'].includes(stage)) {
          if (stage === 'rfd') {
            title = 'Rfp'
            redirectionType = 'rfds'
          }
          if (stage === 'completed') {
            title = 'Rfp Completed'
            redirectionType = 'completed'
          }
          if (stage === 'order') {
            title = isCustomer(user.role) ? 'Orders' : 'Received Order'
            redirectionType = 'orders'
          }
          if (stage === 'confirmed') {
            title = 'Confirmed Order'
            redirectionType = 'confirmed'
          }
          if (stage === 'rfq') {
            redirectionType = 'rfqs'
          }
        }
        if (redirectionType === 'rfqs') {
          if (isCustomer(user.role) || isSupplier(user.role)) {
            redirectionType = 'rfqs'
          } else {
            redirectionType = 'rfq'
          }
        }
        if (stage === 'order' && order?.status?.title === 'proof' && isCustomer(user.role)) {
          redirectionType = 'awaiting-approval'
        }
        return `/${redirectionType}/${order.id}?type=view&tab=6&title=${title}`
      }
      case 'CONVERT_TO_QUOTE':
        if (['admin', 'staff', 'sales'].includes(user.role)) return `/rfqs/admin-quotes/${order.id}?type=convert-to-quote`
        return `/rfqs/supplier-quotes/${order.id}`;
      case 'CONVERT_TO_PROOF':
        return `/rfd/supplier-quotes/${order.id}`
      case 'SHIPMENT_PRINT':
        return `/shipments/shippingLabel/${orderId}`;
      case 'PRINT_PRODUCTION':
        return `/shipments/printProduction/${orderId}`;
      case 'EDIT_SHIPMENT':
        return `/shipments/${orderId}`
      case 'STATUS_UPDATE':
        if (isManage)
          return `/${stage}s/${order.id}?type=in-review`;
        return ''
      default:
        return '';
    }
  };

  const getRoute = (path, uiId) => {
    if (isManage && lowerCase(uiId).includes('view') && uiId !== 'REVIEW_NOTE')
      return `${path}?type=view`
    return path
  }

  const menuObj = {
    handleClick,
    pathFunction,
    allowActions,
    getRoute,
    closeDialog,
    loadOrders,
    closeViewOrderDialog,
  }

  const checkShow = (uiId) => {
    if (isManage) {
      return true;
    }
    if (['VIEW_ORDER', 'VIEW_QUOTE', 'VIEW_RFQ', 'REVIEW_ORDER'].includes(uiId)) {
      return false;
    }
    return true;
  }

  const showActions = (id) => {
    console.log('orderData', orderData)
    switch (id) {
      case 'SEND_FOR_PROOF_APPROVAL':
        return orderData.is_proof_approval
      case 'SEND_FOR_DRAWING_APPROVAL':
        return orderData.is_drawing_approval
      case 'APPROVE_PROOF':
      case 'APPROVE_VAS_PRICE':
      case 'APPROVE_DRAWING':
      case 'REQUEST_REVISION_FOR_VAS_PRICE':
      case 'REQUEST_REVISION_FOR_PROOF':
      case 'REQUEST_REVISION_FOR_DRAWING':
      case 'REJECT':
      case 'REJECT_DRAWING':
        return isCustomer(user.role) ? approveProof : true
      case 'CREATE_VAS_ORDER':
        if (order.is_vas_order && !order.is_proof_approval)
          return false
        if (!order.is_vas_order && order.is_proof_approval)
          return false
        return true
      case 'GENERATE_INVOICE':
        return order.is_invoiced
      case 'UPLOAD_DOCUMENT':
        return order.is_approval_type
      case 'EXPORT_INVOICE_CUSTOMER':
        if (user.role === "customer") return true
        return false
      case 'REJECT_QUOTE':
      case 'STATUS_UPDATE':
        if (isCustomer(user.role)) {
          return approveProof
        }
        if (isManage && stage === "quote" && !popupButtons) {
          return false
        }
        return true
      default:
        return true;
    }
  }

  const showDefaultAction = () => {
    switch (order.status.name) {
      case 'Confirmed':
        if (isCustomer(user.role)) {
          return false;
        }
        break;
      default:
        return true;
    }
    return true
  };

  let rearrangedActions;

  if (actions) {
    if (stage === 'order') {
      rearrangedActions = actions;
    } else if (stage === 'quote') {
      if (isCustomer(user.role)) {
        rearrangedActions = actions
      }
      else if (popupButtons && isManage) {
        rearrangedActions = [actions[1], actions[2], actions[3]]
      }
      else rearrangedActions = [...actions.slice(1), actions[0]];
    } else {
      rearrangedActions = [...actions.slice(2), ...actions.slice(0, 2)];
    }
  }

  const handleHideAction = (name) => {
    if (name === 'Revisions To Supplier' && orderData?.suppliers?.length === 0) return true
    return false
  }

  return (
    <div className="flex flex-row">
      <div className="flex items-center">
        {!actions && stage !== "fulfilled" && showDefaultAction() && !isCustomer(user.role) && !isSupplier(user.role) &&
          defaultAction.map(({ name, icon, ui_id: uiId, to_statuses_types_code: toStatusCode, to_statuses_types_id: toStatusId }) => {
            if (showActions(uiId)) {
              if (popupButtons) {
                if (checkShow(uiId)) {
                  count += 1;
                  return (
                    <div>
                      {count < 3 &&
                        <Button
                          className='mr-5'
                          color="secondary"
                          variant="contained"
                          onClick={() => {
                            dispatch(closeViewOrderDialog());
                            handleClick(uiId, toStatusCode, name, toStatusId);
                          }}
                          startIcon={<IconButton
                            className="py-0 px-2"
                            size="small"
                          >
                            <Icon fontSize="small" sx={{ color: 'white' }}>{icon}</Icon>
                          </IconButton>}
                          component={pathFunction(uiId) && allowActions.includes(uiId) ? Link : undefined}
                          to={getRoute(pathFunction(uiId), uiId)}
                          sx={{ width: 'auto' }}
                        >
                          <span style={{ fontSize: 12 }}>{name}</span>
                        </Button>
                      }

                    </div>
                  );
                }
                return null;

              }

              return (
                <Tooltip key={uiId} title={name}>
                  <IconButton
                    className="py-0 px-2"
                    size="small"
                    onClick={(ev) => handleClick(uiId, toStatusCode, name, toStatusId)}
                    component={pathFunction(uiId) && allowActions.includes(uiId) ? Link : undefined}
                    to={getRoute(pathFunction(uiId), uiId)}
                  >
                    <Icon fontSize="small">{icon}</Icon>
                  </IconButton>
                </Tooltip>

              );
            }
            return null
          })}
        {actions && approveProof === undefined &&
          actions.map(({ name, icon, ui_id: uiId, to_statuses_types_code: toStatusCode, to_statuses_types_id: toStatusId }) => {
            if (handleHideAction(name)) return null
            if (showActions(uiId)) {
              if (popupButtons) {
                if (checkShow(uiId)) {
                  count += 1;
                  return (
                    <div>
                      {count < 3 &&
                        <Button
                          className='mr-5'
                          color="secondary"
                          variant="contained"
                          onClick={() => {
                            dispatch(closeViewOrderDialog());
                            handleClick(uiId, toStatusCode, name, toStatusId);
                          }}
                          startIcon={<IconButton
                            className="py-0 px-2"
                            size="small"
                          >
                            <Icon fontSize="small" sx={{ color: 'white' }}>{icon}</Icon>
                          </IconButton>}
                          component={pathFunction(uiId) && allowActions.includes(uiId) ? Link : undefined}
                          to={getRoute(pathFunction(uiId), uiId)}
                          sx={{ width: 'auto' }}
                        >
                          <span style={{ fontSize: 12 }}>{name}</span>
                        </Button>
                      }

                    </div>
                  );
                }
                return null;

              }

              return (
                <Tooltip key={uiId} title={name}>
                  <IconButton
                    className="py-0 px-2"
                    size="small"
                    onClick={(ev) => handleClick(uiId, toStatusCode, name, toStatusId)}
                    component={pathFunction(uiId) && allowActions.includes(uiId) ? Link : undefined}
                    to={getRoute(pathFunction(uiId), uiId)}
                  >
                    <Icon fontSize="small">{icon}</Icon>
                  </IconButton>
                </Tooltip>

              );
            }
            return null
          })}

        {actions && approveProof &&
          rearrangedActions.map(({ name, icon, ui_id: uiId, to_statuses_types_code: toStatusCode, to_statuses_types_id: toStatusId }) => {
            if (showActions(uiId)) {
              if (popupButtons) {
                if (checkShow(uiId)) {
                  count += 1;
                  return (
                    <div>
                      {count < 3 &&
                        <Button
                          className='mr-5'
                          color="secondary"
                          variant="contained"
                          startIcon={<IconButton
                            className="py-0 px-2"
                            size="small"
                          >
                            <Icon fontSize="small" sx={{ color: 'white' }}>{icon}</Icon>
                          </IconButton>}
                          component={pathFunction(uiId) && allowActions.includes(uiId) && name !== 'Approve Quote' ? Link : undefined}
                          to={getRoute(pathFunction(uiId), uiId)}
                          onClick={() => {
                            if (['Approve Quote', 'Send for Quote Approval'].includes(name)) {
                              const orderDetails = buildSelectedDrawing(selectedImages, listData)
                              confirmation(toStatusCode, uiId, { updateStatus: 'yes', order_details: orderDetails })
                            } else {
                              dispatch(
                                openViewOrderDialog({
                                  completeOrderData: orderData,
                                  data: { id: orderId },
                                  param: {
                                    name: 'approveActions',
                                    title: name,
                                    successMessage: 'Success',
                                    submitButtonName: 'Submit',
                                    loadOrdersFun: loadOrders,
                                    data: {
                                      uiId,
                                      toStatusCode
                                    }
                                  },
                                  field: 'orderDialog',
                                  stage
                                })
                              );
                            }
                          }}
                          sx={{ width: 'auto' }}
                        >
                          <span style={{ fontSize: 12 }}>{name}</span>
                        </Button>
                      }
                    </div>
                  )
                }
                return null;
              }

            }
            return null;
          })}

        {showMore && approveProof === undefined && count >= 3 ? <MenuActions actions={actions.slice(3)} obj={menuObj} /> : ''}

        {/* 
        Temporarily hidden this action
        {showMore && approveProof && count >= 3 ? <MenuActions actions={rearrangedActions.slice(4)} obj={menuObj} /> : ''} */}


      </div>
    </div>
  );
}

export default OpenOrdersRowAction;
