import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { formatDateString } from 'app/main/apps/common/AuraFunctions';
import format from 'date-fns/format';

export const getEnquiry = createAsyncThunk('eCommerceApp/enquiry/getEnquiry', async (params) => {
  const response = await axios.get(`/api/enquiries/${params.id}`);
  const data = await response.data.enquiry;
  return data === undefined ? null : data;
});

export const getEnquiryDetails = createAsyncThunk(
  'eCommerceApp/enquiry/getEnquiryDetails',
  async ({ id, params }, { dispatch, getState }) => {
    const response = await axios.get(`/api/enquiries/${id}`, { params });
    const data = await response.data.enquiry;
    return data ? getInitValues(data) : data;
  }
);

export const sendQuote = createAsyncThunk(
  'eCommerceApp/enquiry/requestForQuote',
  async (enquiryData, { dispatch, getState }) => {
    const { enquiry } = getState().eCommerceApp;
    const response = await axios.post('/api/requestForQuote', {
      ...enquiry,
      ...enquiryData,
    });
    const data = await response.data.enquiry;
    return getInitValues(data);
  }
);

export const saveEnquiry = createAsyncThunk(
  'eCommerceApp/enquiry/saveEnquiry',
  async (enquiryData, { dispatch, getState }) => {
    dispatch(setLoading());
    const { enquiry } = getState().eCommerceApp;
    let response;
    if (enquiryData.id) {
      response = await axios.put(`/api/enquiries/${enquiryData.id}`, {
        ...enquiry,
        ...enquiryData,
      });
    } else {
      response = await axios.post('/api/enquiries', {
        ...enquiry,
        ...enquiryData,
      });
    }
    const data = await response.data.enquiry;
    return getInitValues(data);
  }
);

export const getContactInfo = createAsyncThunk('eCommerceApp/enquiry/getContactInfo', async () => {
  const response = await axios.get(`/api/contactInfo`);
  const data = await response.data;
  return data;
});

export const initState = {
  loading: false,
  data: null,
  enquiryDialog: {
    props: {
      open: false,
    },
    data: null,
  },
};

function getInitValues(data) {
  return {
    id: data && data.id ? data.id : null,
    enquiry_no: data && data.enquiry_no ? data.enquiry_no : '',
    name: data && data.name ? data.name : '',
    customer_id: data && data.customer_id ? data.customer_id : '',
    customer: data && data.customer ? data.customer : null,
    selected_customer: data && data.customer ? { ...data.customer } : null,
    client_contract_number: data && data.client_contract_number ? data.client_contract_number : '',
    date:
      data && data.date
        ? formatDateString(data.date, 'yyyy-MM-dd')
        : format(new Date(), 'yyyy-MM-dd'),
    requested_date:
      data && data.requested_date ? formatDateString(data.requested_date, 'yyyy-MM-dd') : '',
    quantity: data && data.quantity ? data.quantity : 1,
    amount: data && data.amount ? data.amount : '',
    status_id: data && data.status_id ? data.status_id : null,
    status: data && data.status ? data.status : null,
    status_change_time: data && data.status_change_time ? data.status_change_time : null,
    through: data && data.through ? data.through : null,
    reference: data && data.reference ? data.reference : '',
    ship_to: data && data.ship_to ? data.ship_to : '',
    tags: data && data.tags ? data.tags : '',
    status_histories: data && data.status_histories ? data.status_histories : [],
    note_histories: data && data.note_histories ? data.note_histories : [],
    histories: data && data.histories ? data.histories : [],
    documents: data && data.documents ? data.documents : [],
    new_documents: data && data.new_documents ? data.new_documents : [],
    remarks: data && data.remarks ? data.remarks : '',
    notes: data && data.notes ? data.notes : '',
  };
}

const enquirySlice = createSlice({
  name: 'eCommerceApp/enquiry',
  initialState: initState,
  reducers: {
    resetEnquiry: (state, action) => {
      state.data = null;
    },
    setLoading: (state, action) => {
      state.loading = true;
    },
    resetLoading: (state, action) => {
      state.loading = false;
    },
    newEnquiry: {
      reducer: (state, action) => {
        state.data = action.payload;
      },
      prepare: (event) => ({
        payload: getInitValues(),
      }),
    },
    openEnquiryDialog: (state, action) => {
      state.enquiryDialog = {
        props: {
          open: true,
        },
        data: action.payload.data,
        param: action.payload.param,
      };
    },
    closeEnquiryDialog: (state, action) => {
      state.enquiryDialog = {
        props: {
          open: false,
        },
        data: null,
        param: null,
      };
    },
  },
  extraReducers: {
    [getEnquiry.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
    [getEnquiryDetails.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
    [saveEnquiry.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [saveEnquiry.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  resetEnquiry,
  newEnquiry,
  openEnquiryDialog,
  closeEnquiryDialog,
  setLoading,
  resetLoading,
} = enquirySlice.actions;

export default enquirySlice.reducer;
