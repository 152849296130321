import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import moment from 'moment';
import axios from 'axios';
import { getParams } from '../../common/AuraFunctions';

export const getOrders = createAsyncThunk(
  'eCommerceApp/orders/getOrders',
  async ({ params = {}, goToFirstPage }, { dispatch, getState }) => {
    console.log('params', params, 'goto', goToFirstPage)
    if (params.stage === 'completed') params.stage = 'submitted'
    const { filterObj, headerObj } = getParams(goToFirstPage)
    const headers = { 'App-Pagination-Limit': 10, 'App-Pagination-Num': 0, ...headerObj };
    dispatch(setLoading(true))
    const response = await axios.get('/api/orders', { params: { ...params, ...filterObj }, headers });
    dispatch(setLoading(false))
    dispatch(setOrderListHeaders(response.headers))
    dispatch(setParams(params))
    const data = await response.data.data;
    return data;
  }
);

export const removeOrders = createAsyncThunk(
  'eCommerceApp/orders/removeOrders',
  async (orderIds, { dispatch, getState }) => {
    await axios.post('/api/e-commerce-app/remove-orders', { orderIds });

    return orderIds;
  }
);

export const deleteOrders = createAsyncThunk(
  'eCommerceApp/orders/deleteOrders',
  async (payload, { dispatch, getState }) => {
    const response = await axios.delete('/api/order', { data: payload });
    const data = await response.data
    return data;
  }
);

export const uploadOrders = createAsyncThunk(
  'eCommerceApp/orders/uploadOrders',
  async (params, { dispatch, getState }) => {
    const formData = new FormData();
    let url = ''
    if (params.single)
      url = '/api/importOrder'
    else
      url = '/api/importOrders'
    delete params.single

    Object.keys(params).forEach((key) => {
      formData.append(key, params[key]);
    });
    let response
    try {
      response = await axios
        .post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
    } catch (error) {
      console.log(error)
    }

    const uploadResponse = response?.data || {};
    return uploadResponse;
  }
);

export const getOrderStatuses = createAsyncThunk(
  'eCommerceApp/orders/getOrderStatuses',
  async ({ params }) => {
    const response = await axios.get('/api/categoryValues', { params });
    const data = await response.data;
    return { data, params };
  }
);

export const getUpdateStatusValues = createAsyncThunk(
  'eCommerceApp/orders/getUpdateStatusValues',
  async (params) => {
    const response = await axios.get('/api/updateStatusesValues', { params });
    return response.data;
  }
);

export const sendInvoice = createAsyncThunk(
  'eCommerceApp/orders/sendInvoice',
  async (inputData, { dispatch, getState }) => {
    const response = await axios.post('/api/sendInvoice', inputData);
    const data = await response.data;
    return data;
  }
);

export const getSelectedOrderDetails = createAsyncThunk(

  'eCommerceApp/orders/getSelectedOrderDetails',
  async (oid, { dispatch, getState }) => {
    const response = await axios.get(`/api/orders/${oid}`);
    const data = [
      {
        orders: [{ ...response.data.order, order_detail: response.data.order.order_details }],
      },
    ];
    dispatch(selectedOrderDetails(data));
    return data;
  }
);

export const getSuppliers = createAsyncThunk(
  'eCommerceApp/orders/viewSupplier',
  async (suppliers, { dispatch }) => {
    const response = await axios.get(`/api/suppliers`);
    const data = await response.data.data;
    dispatch(setPreviousSuppliers(suppliers || []));
    return data;
  }
);

export const getOrderForReallocation = createAsyncThunk(
  'eCommerceApp/orders/getOrderForReallocation',
  async (id, { dispatch }) => {
    const response = await axios.get(`/api/getOrderForReallocation?oid=${id}`);
    const data = await response.data.availableOrders;
    dispatch(orderForReallocation([data]));
    return [data];
  }
);

export const createReallocation = createAsyncThunk(
  'eCommerceApp/orders/createReallocation',
  async (inputData, { dispatch }) => {
    const response = await axios.put('api/reallocateOrder', {
      reallocate_order: [inputData],
    });
    const data = await response.data;
    return data;
  }
);

export const viewSupplierShipment = createAsyncThunk(
  'eCommerceApp/orders/viewSupplierShipment',
  async (id, { dispatch }) => {
    const response = await axios.get(`api/viewSupplierShipment/${id}`);
    const data = await response.data[0];
    return { ...data, eta_date: data.eta_date?.split('T')[0] };
  }
);

export const getPaymentHistory = createAsyncThunk(
  'DashboardApp/widgets/getPaymentHistory',
  async (id) => {
    const response = await axios.get(`/api/payment/${id}`);
    const data = await response.data.paymentDetails;
    return data
  }
);

export const getContainerNumber = createAsyncThunk(
  'DashboardApp/widgets/getContainerNumber',
  async (params) => {
    const response = await axios.get(`/api/containerNumber`, { params });
    const data = await response.data;
    return data
  }
);

/** Bulk order imports */
export const getImportOrders = createAsyncThunk(
  'eCommerceApp/orders/getImportOrders',
  async (date, { dispatch }) => {
    dispatch(setLoading(true))
    console.log('date', date)
    const formattedDate = moment(date).format('MM-DD-YYYY')
    const response = await axios.get(`/api/bulkImport/${formattedDate}`);
    const data = await response.data;
    return { data, date };
  }
);

export const getImportOrdersDetail = createAsyncThunk(
  'eCommerceApp/orders/getImportOrdersDetail',
  async (id, { dispatch }) => {
    dispatch(setLoading(true))
    const response = await axios.get(`/api/bulkImportData/${id}`);
    const data = await response.data;
    return data;
  }
);

const ordersAdapter = createEntityAdapter({
  selectId: (entity) => {
    return entity.shipment_id ? entity.shipment_id : entity.id
  }
});

export const { selectAll: selectOrders, selectById: selectOrderById } = ordersAdapter.getSelectors(
  (state) => state.eCommerceApp.orders
);

const reallocationState = {
  reAllocationDialog: {
    props: {
      open: false,
    },
    data: null,
  },
  reAllocationFrom: null,
  reAllocationTo: null,
  selectedOrderDetails: [],
  orderForReallocation: [],
  paymentHistory: [],

};

const ordersSlice = createSlice({
  name: 'eCommerceApp/orders',
  initialState: ordersAdapter.getInitialState({
    loading: false,
    searchText: '',
    customer: null,
    supplier: null,
    orderStatusesNames: [],
    orderTypeNames: [],
    orderStatuses: [],
    suppliersList: [],
    previousSuppliers: [],
    suppliersListLoading: false,
    supplierShipmentValues: {},
    orderListHeaders: {},
    selectedValue: null,
    params: {},
    orderTypes: [],
    importOrders: [],
    importDetails: [],
    ...reallocationState,

  }),
  reducers: {
    setOrdersSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setCustomer: (state, action) => {
      state.customer = action.payload || null;
    },
    setSupplier: (state, action) => {
      state.supplier = action.payload || null;
    },
    setSelectedValue: (state, action) => {
      state.selectedValue = action.payload || null
    },
    openReallocationDialog: (state, action) => {
      state.reAllocationDialog = {
        props: {
          open: true,
        },
        param: action.payload.param,
      };
    },
    closeReallocationDialog: (state) => {
      return { ...state, ...reallocationState };
    },
    reAllocationFrom: (state, action) => {
      state.reAllocationFrom = action.payload;
    },
    reAllocationTo: (state, action) => {
      state.reAllocationTo = action.payload;
    },
    selectedOrderDetails: (state, action) => {
      state.selectedOrderDetails = action.payload;
    },
    orderForReallocation: (state, action) => {
      state.orderForReallocation = action.payload;
    },
    setPreviousSuppliers: (state, action) => {
      state.previousSuppliers = action.payload;
    },
    setOrderListHeaders: (state, action) => {
      state.orderListHeaders = action.payload;
    },
    setParams: (state, action) => {
      state.params = action.payload
    },
    setOrderTypes: (state, action) => {
      state.orderTypes = action.payload
    }
  },
  extraReducers: {
    [getOrders.fulfilled]: ordersAdapter.setAll,
    [getOrders.pending]: ordersAdapter.removeAll,
    [getOrders.rejected]: (state) => {
      state.loading = false
    },
    [removeOrders.fulfilled]: (state, action) => ordersAdapter.removeMany(state, action.payload),
    [getOrderStatuses.fulfilled]: (state, action) => {
      if (action.payload.params && action.payload.params.on === 'y') {
        state.orderStatusesNames = action.payload.data;
      } else if (action.payload.params && action.payload.params.c === "order_type") {
        state.orderTypeNames = action.payload.data.map(function (a) {
          return a.name;
        });
      } else {
        state.orderStatuses = action.payload.data;
        state.orderStatusesNames = action.payload.data.map(function (a) {
          return a.name;
        });
      }
    },
    [getUpdateStatusValues.fulfilled]: (state, action) => {
      state.updateStatusValues = action.payload;
    },
    [getImportOrders.fulfilled]: (state, action) => {
      state.importOrders = action.payload;
      state.loading = false
    },
    [getImportOrdersDetail.fulfilled]: (state, action) => {
      state.importDetails = action.payload;
      state.loading = false
    },
    [getSuppliers.fulfilled]: (state, action) => {
      state.suppliersList = action.payload;
      state.suppliersListLoading = false;
    },
    [getSuppliers.rejected]: (state, action) => {
      state.suppliersListLoading = false;
    },
    [getSuppliers.pending]: (state, action) => {
      state.suppliersList = [];
      state.previousSuppliers = [];
      state.suppliersListLoading = true;
    },
    [viewSupplierShipment.fulfilled]: (state, action) => {
      state.supplierShipmentValues = action.payload
    },
    [viewSupplierShipment.pending]: (state, action) => {
      state.supplierShipmentValues = {}
    },
    [getPaymentHistory.pending]: (state, action) => {
      state.paymentHistory = []
    },
    [getPaymentHistory.fulfilled]: (state, action) => {
      state.paymentHistory = action.payload
    },
  },
});

export const {
  setLoading,
  setOrdersSearchText,
  setCustomer,
  setSelectedValue,
  setSupplier,
  openReallocationDialog,
  closeReallocationDialog,
  reAllocationFrom,
  reAllocationTo,
  selectedOrderDetails,
  orderForReallocation,
  setPreviousSuppliers,
  setOrderListHeaders,
  setParams,
  setOrderTypes
} = ordersSlice.actions;

export default ordersSlice.reducer;
