import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getEnquiries = createAsyncThunk(
  'eCommerceApp/enquiries/getEnquiries',
  async (queryParams, { dispatch, getState }) => {
    const response = await axios.get('/api/enquiries', { params: queryParams });
    const data = await response.data.data;
    return data;
  }
);

export const getEnquiryStatuses = createAsyncThunk(
  'eCommerceApp/enquiries/getEnquiryStatuses',
  async ({ params }) => {
    const response = await axios.get('/api/categoryValues', { params });
    const data = await response.data;
    return { data, params };
  }
);

export const setEnquiryStatus = createAsyncThunk(
  'eCommerceApp/enquiries/setEnquiryStatus',
  async (inputData, { dispatch, getState }) => {
    const response = await axios.post('/api/setEnquiryStatus', inputData);
    const data = await response.data;
    return data;
  }
);

const enquiriesAdapter = createEntityAdapter({});

export const { selectAll: selectEnquiries, selectById: selectEnquiryById } =
  enquiriesAdapter.getSelectors((state) => state.eCommerceApp.enquiries);

const enquiriesSlice = createSlice({
  name: 'eCommerceApp/enquiries',
  initialState: enquiriesAdapter.getInitialState({
    searchText: '',
    customer: null,
    enquiryStatuses: [],
  }),
  reducers: {
    setEnquiriesSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setCustomer: (state, action) => {
      state.customer = action.payload || null;
    },
  },
  extraReducers: {
    [getEnquiries.fulfilled]: enquiriesAdapter.setAll,
    [getEnquiryStatuses.fulfilled]: (state, action) => {
      if (action.payload.params && action.payload.params.on === 'y') {
        state.enquiryStatusesNames = action.payload.data;
      } else {
        state.enquiryStatuses = action.payload.data;
        state.enquiryStatusesNames = action.payload.data.map(function (a) {
          return a.name;
        });
      }
    },
  },
});

export const { setEnquiriesSearchText, setCustomer } = enquiriesSlice.actions;

export default enquiriesSlice.reducer;
