import { lazy } from 'react';

const MainDashboardApp = lazy(() => import('./MainDashboardApp'));
const Notifications = lazy(() => import('../../apps/backoffice/notifications/Notifications'))

const MainDashboardAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'dashboard',
      element: <MainDashboardApp />,
    },
    {
      path: 'notifications',
      element: <Notifications />,
    },
  ],
};

export default MainDashboardAppConfig;
