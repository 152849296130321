import { useSelector } from 'react-redux';
import { SelectFilter } from '../../common/FiltersComponent';

export function OrdersTypeFilter({ column, params, updateParams }) {
  const orderTypeNames = useSelector(
    ({ eCommerceApp }) => eCommerceApp.orders.orderTypeNames
  );
  return <SelectFilter multiple={false} column={column} menuData={orderTypeNames} params={params} updateParams={updateParams} />;
}

function OrdersSelectFilter({ column, params, updateParams }) {
  const orderStatusesNames = useSelector(
    ({ eCommerceApp }) => eCommerceApp.orders.orderStatusesNames
  );
  return <SelectFilter multiple={column.type === 'order'} column={column} menuData={orderStatusesNames} params={params} updateParams={updateParams} />;
}

export function PaymentSelectFilter({ column, params, updateParams }) {
  return <SelectFilter column={column} menuData={['paid', 'pending']} params={params} updateParams={updateParams} />;
}

export default OrdersSelectFilter;
