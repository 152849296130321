import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  state: null,
  options: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    autoHideDuration: 2000,
    message: 'Hi',
    variant: null,
  },
};

const topRightOrigin = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
}

const type = {
  error: {
    variant: 'error',
    autoHideDuration: null,
    ...topRightOrigin
  },
  success: {
    variant: 'success',
    ...topRightOrigin
  },
  info: {
    variant: 'info',
    ...topRightOrigin
  },
  warning: {
    variant: 'warning',
    ...topRightOrigin
  }
}


const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    showMessage: (state, action) => {
      state.state = true;

      if (action.payload?.type && type[action.payload.type])
        state.options = {
          ...initialState.options,
          ...action.payload,
          ...type[action.payload.type],
        };
      else
        state.options = {
          ...initialState.options,
          ...action.payload,
        };
    },
    hideMessage: (state, action) => {
      state.state = null;
    },
  },
});

export const { hideMessage, showMessage } = messageSlice.actions;

export const selectFuseMessageState = ({ fuse }) => fuse.message.state;

export const selectFuseMessageOptions = ({ fuse }) => fuse.message.options;

export default messageSlice.reducer;
