import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Icon, IconButton, Checkbox } from '@mui/material';
import clsx from 'clsx';
import { startsWith } from 'lodash';
import React from 'react';
import ImageViewerWindow from 'app/main/dashboards/main/dialogs/ImageViewerWindow';
import pdfViewerWindow from 'app/main/dashboards/main/dialogs/pdfViewerWindow';

const ImageRenderer = ({
    onChange = () => { },
    setSelected = () => { },
    attachments = [],
    onClear,
    selected,
    value,
    showCheckbox,
    handleSelectionMedia
}) => {

    return (
        <div className='flex'>
            {attachments?.map((media) => {
                console.log('image render media', media)
                const isImage = startsWith(media.url, 'data:image/');
                const isPDF = media.mime === 'application/pdf' || startsWith(media?.url, 'data:application/pdf') || media['Content-Type'] === 'application/pdf';

                let src;

                if (isImage) {
                    src = media.url;
                } else if (isPDF) {
                    src = 'assets/icons/pdf.png'; // Replace 'd' with the desired value for PDF
                } else {
                    src = `data:${media.content_type};base64,${media.data}`;
                }
                return (
                    <div>
                        {
                            showCheckbox && (
                                <div>
                                    <Checkbox
                                        className='flex-start'
                                        //   checked={selectedImage?.[sInd]?.[index]?.[selectedImageIndex] || false}
                                        onChange={(event) => handleSelectionMedia(media)}
                                    />
                                </div>
                            )
                        }
                        <div
                            onClick={() => {
                                // reset()
                                onChange(media.id)
                                setSelected(media)
                                if (isPDF) {
                                    pdfViewerWindow(media.data || media?.url)
                                } else {
                                    ImageViewerWindow([media])
                                }
                            }}
                            onKeyDown={() => onChange(media.id)}
                            role="button"
                            tabIndex={0}
                            className={clsx(
                                'attachmentItem flex items-center justify-center relative w-128 h-128 rounded-16 mx-12 overflow-hidden cursor-pointer outline-none shadow hover:shadow-lg',
                                media.id === value && 'featured', selected === media.id && 'border-blue-500'
                            )}
                            key={media.id}
                        >
                            {onClear && <IconButton
                                className="absolute top-0 right-0"
                                onClick={(ev) => {
                                    ev.stopPropagation()
                                    onClear(ev, media);
                                }}
                                size="small"
                            >
                                <Icon>clear</Icon>
                            </IconButton>}
                            <img
                                src={src}
                                className="max-w-none w-auto h-full"
                                alt="attachment"
                            />
                            {selected === media.id && <div className="selection absolute flex items-center justify-center w-14 h-14 bg-white rounded-full">
                                <FuseSvgIcon size={28} color="success">heroicons-solid:check-circle</FuseSvgIcon>
                            </div>}
                        </div>
                        <p className='mb-24 text-center w-96 m-auto text-sm'>{media?.filename || media?.name}</p>

                    </div>
                )
            }
            )}
        </div>
    );
};

export default ImageRenderer;