import { lazy } from 'react';
import { authRoles } from 'app/auth';

const ShippingLabel = lazy(() => import('./order/Dialogs/ShippingLabelContent'))
const Orders = lazy(() => import('./orders/Orders'));

const SupplierAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.supplier,
  routes: [
    {
      path: 'in-production',
      element: <Orders type="in-production" />,
    },
    {
      path: 'fulfilled',
      element: <Orders type="fulfilled" />,
    },
    {
      path: 'confirmed',
      element: <Orders type="confirmed" />,
    },
    {
      path: '/shipments/shippingLabel/:orderId',
      element: <ShippingLabel />
    },
    {
      path: '/shipments/printProduction/:orderId',
      element: <ShippingLabel />
    },
  ],
};

export default SupplierAppConfig;
