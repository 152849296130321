import { lazy } from 'react';
import { authRoles } from 'app/auth';

const Cart = lazy(() => import('./cart/Cart'));
const RequestQuote = lazy(() => import('./order/RequestQuote'));
const Orders = lazy(() => import('./orders/Orders'));

const CustomerAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.customer,
  routes: [
    {
      path: 'cart',
      element: <Cart />,
    },
    {
      path: 'rfqs/request',
      element: <RequestQuote />,
    },
    {
      path: 'awaiting-approval',
      element: <Orders type="approval" />,
    },
  ],
};

export default CustomerAppConfig;
