import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import axios from 'axios';

export const getSuppliers = createAsyncThunk(
  'eCommerceApp/rfqs/viewSupplier',
  async ({ suppliers, params }, { dispatch }) => {
    const response = await axios.get(`/api/suppliers`, { params });
    const data = await response.data.data;
    dispatch(setPreviousSuppliers(suppliers))
    return data;
  }
);

export const getSupplierOrder = createAsyncThunk(
  'eCommerceApp/rfqs/getSupplierOrder',
  async ({ id, params }, { dispatch }) => {
    const response = await axios.get(`/api/getSupplierOrder/${id}`, { params });
    const data = await response.data.suppliers;
    return data;
  }
);

export const quoteToSupplier = createAsyncThunk(
  'eCommerceApp/rfqs/requestForQuoteToSupplier',
  async ({ id, payload }, { dispatch }) => {
    const response = await axios.post(`/api/requestForQuoteToSupplier/${id}`, payload);
    const data = await response.data.data;
    return data;
  }
);

export const quoteToOrder = createAsyncThunk(
  'eCommerceApp/rfqs/convertQuoteToOrder',
  async ({ id, payload, params }, { dispatch }) => {
    const response = await axios.put(`/api/convertQuoteToOrder/${id}`, payload, { params });
    const data = await response.data.data;
    return data;
  }
);

const rfqsAdapter = createEntityAdapter({});

export const { selectAll: selectRfqs, selectById: selectRfqsById } =
  rfqsAdapter.getSelectors((state) => state.eCommerceApp.rfqs);

const rfqsSlice = createSlice({
  name: 'eCommerceApp/rfqs',
  initialState: rfqsAdapter.getInitialState({
    customer: null,
    rfqsStatuses: [],
    suppliersList: [],
    previousSuppliers: [],
    suppliersListLoading: false
  }),
  reducers: {
    setPreviousSuppliers: (state, action) => {
      state.previousSuppliers = action.payload
    }
  },
  extraReducers: {
    [getSuppliers.fulfilled]: (state, action) => {
      state.suppliersList = action.payload
      state.suppliersListLoading = false
    },
    [getSuppliers.rejected]: (state, action) => {
      state.suppliersListLoading = false
    },
    [getSuppliers.pending]: (state, action) => {
      state.suppliersList = []
      state.previousSuppliers = []
      state.suppliersListLoading = true
    },
    [getSupplierOrder.fulfilled]: (state, action) => {
      state.suppliersList = action.payload
      state.suppliersListLoading = false
    },
    [getSupplierOrder.rejected]: (state, action) => {
      state.suppliersListLoading = false
    },
    [getSupplierOrder.pending]: (state, action) => {
      state.suppliersList = []
      state.previousSuppliers = []
      state.suppliersListLoading = true
    }
  },
});

export const { setPreviousSuppliers } = rfqsSlice.actions;

export default rfqsSlice.reducer;
