import Typography from '@mui/material/Typography';
import AuraAutoComplete from 'app/main/apps/common/AuraAutoComplete';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { matchSorter } from 'match-sorter';
import { useEffect, useState, memo } from 'react';
import { useParams } from 'react-router-dom';
import { getCustomers, getCustomersShipment } from 'app/main/apps/client/store/customersSlice';
import { getStatuses } from 'app/main/dashboards/main/store/widgetsSlice';
import { getSuppliers, setOrderTypes, setSelectedValue } from '../store/ordersSlice';
import { setSupplierOrderType } from '../../backoffice/store/shipmentOrderSlice';

function CustomerFilter(props) {
  const { entitiesSelector, setCustomer, defaultCustomer, filterValue, setSupplier, isOrderModule, pageRef } = props;
  const dispatch = useDispatch();
  const currentUser = useSelector(({ user }) => user);
  const suppliersList = useSelector(state => state.eCommerceApp.orders.suppliersList);
  const selectedSupplier = useSelector(state => state.eCommerceApp.orders.supplier);
  const { location } = window;
  const isCustomer = currentUser && currentUser.role === 'customer';
  const [customers, setCustomers] = useState([]);
  const isCustomerFilter = (!['supplier', 'supplier_archive',].includes(filterValue?.value)) || !isOrderModule;
  const sp = new URLSearchParams(location.search)

  useEffect(() => {
    if (!isCustomer && location.pathname === '/shipments/new') {
      dispatch(getCustomersShipment({ lt: 'shipment' })).then(({ payload }) => {
        setCustomers(payload.data);
      });
    } else if (!isCustomer) {
      dispatch(getCustomers({ lt: 'n' })).then((response) => {
        const customerTemp =
          response && response.payload && response.payload.data ? response.payload.data : [];
        setCustomers(customerTemp);
      });
    }
    if (['supplier', 'supplier_archive'].includes(filterValue?.value))
      dispatch(getSuppliers());
  }, [dispatch, isCustomer, location.pathname]);

  useEffect(() => {
    if (location.search && isOrderModule && !pageRef?.current) {
      const cid = sp.get('cid');
      const sid = sp.get('sid');
      const orderType = sp.get('o_type')
      if (cid && customers?.length) {
        const customer = customers.find(c => c.id === cid)
        dispatch(setCustomer(customer));
        pageRef.current = true
      }
      else if (sid && suppliersList?.length) {
        const supplier = suppliersList.find(c => c.id === sid)
        dispatch(setSupplier(supplier));
        pageRef.current = true
      }

    }
  }, [location, customers, suppliersList, dispatch, setCustomer, setSupplier, isOrderModule])

  const handleCustomerChange = (value) => {
    sp.delete('sid')
    sp.delete('cid')
    if (!value && filterValue?.value.includes('supplier'))
      sp.set('sid', 'all');

    if (isCustomerFilter) {
      dispatch(setCustomer(value));
      if (isOrderModule) {
        dispatch(setSupplier(null));
        if (value) {
          sp.set('cid', value.id)
        }
      }
    }
    else {
      dispatch(setSupplier(value));
      dispatch(setCustomer(null));
      if (value)
        sp.set('sid', value.id)
    }

    if (isOrderModule)
      window.history.replaceState(null, "", sp.toString() ? `${location.pathname}?${sp.toString()}` : location.pathname);
  };

  const inputValues = isCustomerFilter ? customers : [{ name: 'All', value: 'all', email: '', id: 'all' }, ...suppliersList]
  const defaultValue = isCustomerFilter ? defaultCustomer : selectedSupplier


  return (
    <div className="flex flex-row mx-12">
      <Typography className="text-12 md:text-14 pr-8 pt-12">{isCustomerFilter ? 'Customer' : 'Supplier'}:</Typography>
      <div className="text-12 md:text-14 pr-8 font-semibold">
        <AuraAutoComplete
          placeholder={`All ${isCustomerFilter ? 'Customers' : 'Suppliers'}`}
          inputValues={inputValues}
          onValueChange={handleCustomerChange}
          defaultValue={defaultValue || null}
          optionDisplayName="name"
          // isOptionEqualToValue={(option, value) => option.name === value.name}
          isOptionEqualToValue={(option, value1) => {
            if (option && option.name && value1.name) {
              return option.name === value1.name;
            }
            return false;
          }}
          hideClearable={false}
          renderOption={(renderprops, option) => (
            <Box
              component="li"
              {...renderprops}
              key={option.id}
              className={`${renderprops.className} !block py-2 px-10`}
            >
              <div className="font-medium">{option.name}</div>
              <div className="font-light text-10 text-gray-400">{option.email}</div>
            </Box>
          )}
          filterOptions={(options, { inputValue }) =>
            matchSorter(options, inputValue, {
              keys: ['name'],
              threshold: matchSorter.rankings.STARTS_WITH,
            })
          }
        />
      </div>
    </div>
  );
}

export function OrderTypeFilter({ filterValue, setSelectedFilterType, setSupplier, setCustomer, options, role, type, isSupplierInvoice }) {
  const dispatch = useDispatch();
  const { location } = window;
  const sp = new URLSearchParams(location.search);

  const clearFilterValues = () => {
    const keysToDelete = Array.from(sp.keys());

    keysToDelete.forEach(key => sp.delete(key));
  }

  const filterFunction = (value) => {
    setSelectedFilterType(value)
    dispatch(setCustomer(null));
    sp.delete('sid')
    sp.delete('cid')
    sp.delete('archive')
    if (['admin', 'staff', 'sales'].includes(role)) {
      switch (value.value) {
        case 'supplier':
          dispatch(setSupplier({ name: 'All', value: 'all', email: '', id: 'all' }));
          clearFilterValues()
          if (!isSupplierInvoice)
            sp.set('sid', 'all')
          break;
        case 'customer_archive':
          dispatch(setSupplier(null));
          clearFilterValues()
          sp.set('archive', true)
          if (type !== "invoice") {
            sp.set('cid', 'all')
          }
          break;
        case 'supplier_archive':
          dispatch(setSupplier({ name: 'All', value: 'all', email: '', id: 'all' }));
          clearFilterValues()
          if (!isSupplierInvoice) {
            sp.set('sid', 'all')
          }
          if (isSupplierInvoice) {
            sp.delete('sid', 'all')
          }
          sp.set('archive', true)
          break;
        case 'customer':
          dispatch(setSupplier(null))
          clearFilterValues()
          break;
        case 'archive_rfq':
          clearFilterValues()
          sp.set('cid', 'all')
          sp.set('archive', true)
          break;
        case 'archive_quote':
          clearFilterValues()
          sp.set('cid', 'all')
          sp.set('archive', true)
          break;
        default:
          clearFilterValues()
          break;
      }
    }
    if (role === 'customer') {
      switch (value.value) {
        case 'customer_archive':
          dispatch(setSupplier(null));
          clearFilterValues()
          sp.set('archive', true)
          break;
        case 'customer':
          dispatch(setSupplier(null))
          clearFilterValues()
          break;
        default:
          break;
      }
    }

    window.history.replaceState(null, "", sp.toString() ? `${location.pathname}?${sp.toString()}` : location.pathname);
  }

  return (
    <div className="flex flex-row mx-12">
      <Typography className="text-12 md:text-14 pr-8 pt-12"> Type:</Typography>
      <div className="text-12 md:text-14 pr-8 font-semibold">
        <AuraAutoComplete
          inputValues={options}
          onValueChange={filterFunction}
          defaultValue={filterValue}
          optionDisplayName="name"
          isOptionEqualToValue={(option, value1) => {
            if (option && option.name && value1.name) {
              return option.name === value1.name;
            }
            return false;
          }}
        />
      </div>
    </div>
  );
}

export function NotificationFilter({ filterValue, setSelectedFilterType, options, role, }) {
  const { location } = window;
  const sp = new URLSearchParams(location.search);

  const clearFilterValues = () => {
    const keysToDelete = Array.from(sp.keys());

    keysToDelete.forEach(key => sp.delete(key));
  }

  const filterFunction = (value) => {
    setSelectedFilterType(value)
    sp.delete('notes')
    if (['admin', 'staff', 'sales'].includes(role)) {
      switch (value.value) {
        case 'customers':
          clearFilterValues()
          sp.set('notes', value.value)
          break;
        case 'suppliers':
          clearFilterValues()
          sp.set('notes', value.value)
          break;
        default:
          clearFilterValues()
          break;
      }
    }

    window.history.replaceState(null, "", sp.toString() ? `${location.pathname}?${sp.toString()}` : location.pathname);
  }

  return (
    <div className="flex flex-row mx-12">
      <Typography className="text-12 md:text-14 pr-8 pt-12"> Notes:</Typography>
      <div className="text-12 md:text-14 pr-8 font-semibold">
        <AuraAutoComplete
          inputValues={options}
          onValueChange={filterFunction}
          defaultValue={filterValue}
          optionDisplayName="name"
          isOptionEqualToValue={(option, value1) => {
            if (option && option.name && value1.name) {
              return option.name === value1.name;
            }
            return false;
          }}
        />
      </div>
    </div>
  );
}

export function OrderTyepFilterFunction() {
  const dispatch = useDispatch();
  const [value, setValue] = useState([]);
  const { selectedValue } = useSelector(({ eCommerceApp }) => eCommerceApp?.orders)
  const supplierOrderType = useSelector(({ backofficeApp }) => backofficeApp?.shipmentOrder?.supplierOrderType);
  const currentUser = useSelector(({ user }) => user);
  const { location } = window
  const { shipmentId } = useParams()

  useEffect(() => {
    dispatch(getStatuses('order_type'))
      .unwrap()
      .then((res) => {
        setValue(res);
        dispatch(setOrderTypes(res))
      });
  }, [dispatch]);


  const getDefaultValue = () => {
    let defaultValue = '';
    if (location.pathname === '/shipments/new') {
      if (supplierOrderType) {
        defaultValue = supplierOrderType;
      } else {
        defaultValue = value?.[2];
      }
    }
    else {
      defaultValue = selectedValue;
    }
    return defaultValue;
  };

  const chooseOrderType = (selected) => {
    const orderType = new URLSearchParams(location.search)
    orderType.delete('o_type');

    if (!selected) {
      if (currentUser.role === 'supplier') {
        dispatch(setSupplierOrderType(null))
        getDefaultValue()
      }
      else {
        dispatch(setSelectedValue(null))
      }

    }
    if (selected && currentUser.role !== 'supplier') {
      orderType.set('o_type', selected.name)
      dispatch(setSelectedValue(selected))
      const params = {
        stage: 'order',
        order_type: selected.name
      }
    }
    if (selected && currentUser.role === 'supplier') {
      dispatch(setSupplierOrderType(selected))
    }
    window.history.replaceState(null, "", orderType.toString() ? `${location.pathname}?${orderType.toString()}` : location.pathname)
  }

  return (
    shipmentId === 'new' ? (<div className="flex flex-row mx-12">
      <Typography className="text-12 md:text-14 pr-8 pt-12" style={{ whiteSpace: "nowrap" }}> Order Type:</Typography>
      <div className="text-12 md:text-14 pr-8 font-semibold">
        <AuraAutoComplete
          placeholder="All"
          inputValues={value}
          defaultValue={getDefaultValue()}
          onValueChange={chooseOrderType}
          optionDisplayName="name"
          hideClearable={false}
          isOptionEqualToValue={(option, value1) => {
            if (option && option.name && value1) {
              return option.name === value1;
            }
            return false;
          }}

        />
      </div>
    </div>) : null
  );
}


export default memo(CustomerFilter);
