/**
 * Roles based Layout
 */
const roleLayout = {
  admin: 'backoffice',
  staff: 'backoffice',
  sales: 'backoffice',
  supplier: 'portal',
  customer: 'portal',
  onlyGuest: [],
};

export default roleLayout;
