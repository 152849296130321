import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Icon, IconButton, Tooltip } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import FuseUtils from '@fuse/utils/FuseUtils';
import { authPermissions } from 'app/auth';
import { useLocation } from 'react-router-dom';
import ValueSectionSmall from './ValueSectionSmall';
import { formatDateString, selectAndFormatAmount, selectAndFormatWeight } from './AuraFunctions';
import _ from '../../../../@lodash';


function OrderHeader(props) {
  const methods = useFormContext();
  const { control, formState } = methods;
  const { errors } = formState;
  const currentUser = useSelector(({ user }) => user);
  const { role } = currentUser
  const isManage = currentUser && FuseUtils.hasPermission(authPermissions.manage, currentUser.role);
  const location = useLocation()
  const { pathname } = location
  const isSupplierInvoice = pathname.split('/').includes('supplier-invoices')
  const urlParams = new URLSearchParams(location.search);
  const sidValue = urlParams.get('sid');


  const {
    order: orderData,
    isShipmentData,
    isCommunicationTab,
    hideAmount = false,
    hideQuantity = false,
    hideDate = false,
    hideCustomer = false,
    showTotalWeight,
    showPrintAction,
    showDownloadAction,
    onPrint,
    onDownload,
    stage,
    overRideData = {},
    hideShipmentDetails,
    showSupplierName,
    isPrinting,
    showContainerNo,
    hideCTNAndCompany,
    reviewNoteDialog = false
  } = props;

  if (!orderData) {
    return null;
  }
  const getOrder = () => {
    let order = {}
    if (orderData.invoice_no) {
      order = {
        order_no: orderData.invoice_no,
        amount: orderData.invoice_amount,
        date: orderData.invoice_date,
        company_name: orderData.customer_name || orderData.company_name,
        supplier_name: orderData?.supplier?.name || orderData?.supplier_name || '-',
        status: {
          name: orderData.status.name
        }
      }
    }
    else if (['shipment', 'in-production'].includes(stage)) {
      order = {
        ...orderData,
        order_no: orderData.container_number,
        date: orderData.shipment_date,
        quantity: orderData.no_of_order_items
      }
    }
    else {
      return { ...orderData, ...overRideData }
    }
    return order
  }


  const getCustomerName = () => {
    let customerName = ''
    if (role === 'customer' || isManage) {
      customerName = orderData.customer_name || '-'
    }
    if (isCommunicationTab) {
      customerName = orderData?.customer?.company_name
    }
    else customerName = orderData.customer_code || orderData.customer_name || '-'
    return customerName;
  }

  const getOrderLabel = () => {
    console.log('stage', stage)
    if (['confirmed', 'fulfilled'].includes(stage)) {
      return 'Order'
    }
    if (stage === 'in-production') {
      return 'Container'
    }
    if (stage === 'rfd') {
      return 'Rfp'
    }
    if (stage === 'completed') {
      return 'Rfp Completed'
    }
    return _.capitalize(stage)
  }

  const getDateLabel = () => {
    if (stage === 'rfd') {
      return 'Rfp'
    }
    if (orderData?.status?.system_code === 'ORDER_RECEIVED') {
      return 'Received Order '
    }
    if (orderData?.status?.system_code === 'RFD_SUBMITTED') {
      return 'RFP Completed'
    }
    return _.capitalize(stage)
  }

  const statusName = _.capitalize(getOrder().status?.name || getOrder().status || getOrder()['status.name'] || getOrder()[0]?.status.name)

  return (
    <>
      <Box boxShadow={2}>
        <div className="flex flex-wrap w-full items-center px-8 py-4 md:px-16 border-b">
          <div className="flex flex-col flex-auto my-12 mx-8">
            <div className="flex items-center space-x-20">
              <Typography className="font-mono text-md">{`${getOrderLabel()} No :`}{isShipmentData ? getOrder()?.orders[0].order_no : getOrder().order_no || getOrder().invoice_no}</Typography>
              {!hideDate && (!['staff', 'admin', 'sales'].includes(role) || reviewNoteDialog) && (
                <Typography
                  className="font-mono text-md leading-none tracking-tight"
                  color="text.secondary"
                >
                  <span className="font-bold">{['shipment', 'in-production'].includes(stage) ? 'Shipment Date' : `${getDateLabel()} Date:`} </span>  {formatDateString(getOrder().date)}
                </Typography>
              )}
              {!isShipmentData && !isPrinting && statusName && <Chip
                color="info"
                size="small"
                label={statusName}
              />}
            </div>
            <div className="flex  items-end mt-4 space-x-6">
              {!hideCustomer && !isSupplierInvoice && sidValue !== "all" && <Typography className="font-mono text-md tracking-wider" color="text.secondary">
                <span className="font-bold">Customer Name: </span>  {isShipmentData ? getOrder()?.orders[0].customer.company_name : getCustomerName()}
              </Typography>}

            </div>
            <div className="flex  items-end mt-4 space-x-6">
              {showSupplierName && getOrder()?.supplier_name && !['customer'].includes(role) && !isPrinting && <Typography className="font-mono text-md tracking-wider" color="text.secondary">
                <span className="font-bold">Supplier Name: </span>  {getOrder()?.supplier_name}
              </Typography>}

            </div>

            {!['invoice', 'shipment', 'rfq', 'in-production'].includes(stage) && !['staff', 'admin'].includes(role) && (
              <>
                {role === 'supplier' && !['quote', 'rfq'].includes(stage) && (
                  <>
                    <div className="flex items-end mt-4 space-x-6">
                      <Typography className="font-mono text-md tracking-wider" color="text.secondary">
                        <span className="font-bold"> Delivery Rail Ramp: </span>{getOrder().delivery_location
                        }
                      </Typography>
                    </div>
                    <div className="flex items-end mt-4 space-x-20">
                      <Typography className="font-mono text-md tracking-wider" color="text.secondary">
                        <span className="font-bold"> Order Type: </span> {getOrder().order_type}
                      </Typography>
                    </div></>
                )}

              </>
            )}
            {['shipment'].includes(stage) && !hideShipmentDetails && (
              <>

                <div className="flex flex-col mt-4 space-y-6">
                  {!hideCTNAndCompany && (
                    <>
                      <Typography className="font-mono text-md tracking-wider" color="text.secondary">
                        <span className="font-bold">Container Tracking Number: </span>{getOrder().container_tracking_number}
                      </Typography>
                      <Typography className="font-mono text-md tracking-wider" color="text.secondary">
                        <span className="font-bold">Shipping Company: </span>{getOrder().shipping_company?.name}
                      </Typography>
                    </>
                  )}

                  <Typography className="font-mono text-md tracking-wider" color="text.secondary">
                    <span className="font-bold"> ETA: </span> {formatDateString(getOrder().eta_date)}
                  </Typography>
                </div>
              </>
            )}
          </div>

          <div>
            <div className='text-right'>
              {showPrintAction &&
                <Tooltip title='Print'>
                  <IconButton onClick={onPrint}>
                    <Icon>
                      print
                    </Icon>
                  </IconButton>
                </Tooltip>}
              {showDownloadAction &&
                <Tooltip title='Download'>
                  <IconButton onClick={onDownload}>
                    <Icon>
                      download
                    </Icon>
                  </IconButton>
                </Tooltip>}
            </div>
            <div className="hidden sm:flex items-center my-12 mx-8 rounded-xl border">

              {(!hideQuantity || isPrinting) && <ValueSectionSmall title="Total Quantity" value={isShipmentData ? getOrder()?.orders[0].quantity : getOrder().quantity} />}
              <Divider orientation="vertical" flexItem />
              {/*
          <ValueSectionSmall title="Weight" value={order.weight} type="lbs" />
          <Divider orientation="vertical" flexItem />
          
*/}
              {(showTotalWeight || isPrinting) ?
                <ValueSectionSmall
                  title="Total Weight"
                  isAmount
                  value={selectAndFormatWeight(0, getOrder()?.weight)}
                /> : null}
              <Divider orientation="vertical" flexItem />


              {(!hideAmount && !isShipmentData && getOrder()?.status?.category !== 'rfq_status' || isPrinting) ?
                <ValueSectionSmall
                  title="Total Amount"
                  isAmount
                  value={selectAndFormatAmount(getOrder().all_invoices_pre_tax_total, getOrder().amount ? getOrder().amount : getOrder().displayAmount)}
                /> : null}

              <Divider orientation="vertical" flexItem />
              {showContainerNo && (
                <>
                  <ValueSectionSmall title="Container Number"
                    value={getOrder()?.container_number}
                  />
                  <Divider orientation="vertical" flexItem />
                </>
              )}
            </div>
          </div>
        </div>
      </Box>
      {/* <div className="flex flex-col md:flex-row mt-24">
            <div className="flex w-full px-24">
              <Controller
                control={control}
                name="order_no"
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Order No *"
                    id="order_no"
                    variant="outlined"
                    value={orderData.order_no}
                    fullWidth
                    size="small"
                    error={!!errors.note}
                    helperText={errors?.note?.message}
                  />
                )}
              />
            </div>
          </div> */}
    </>
  );
}

export default OrderHeader;
