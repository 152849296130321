import {React,useState} from 'react'
import {
    Icon,
    IconButton,
    Button,
    Menu,
    MenuItem,
    Tooltip,
  } from '@mui/material';
  import { MoreVert } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';


const MenuActions = ( {actions=[],obj}) => {
    const dispatch = useDispatch();
    const [anchorEl,setAnchorEl] = useState();
  const openActionMenu= Boolean(anchorEl);
  
  const setAnchorElement = (e)=>{
    setAnchorEl(e.currentTarget);
  }

    const {handleClick,pathFunction,allowActions,getRoute,closeDialog,closeViewOrderDialog} = obj;
    console.log(handleClick);
    return(
    <>
      <Tooltip title="more actions">
        <IconButton onClick={(e)=>setAnchorElement(e)}>
          <MoreVert/>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={openActionMenu}
        onClose={()=>{setAnchorEl(null)}} 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'bottom',
        }}
    >
        {actions.map(({ name, icon, ui_id: uiId, to_statuses_types_code: toStausId })=>(
          <MenuItem
                onClick={() =>{
                    dispatch(closeViewOrderDialog());
                    ;handleClick(uiId, toStausId, name)}}
                component={pathFunction(uiId) && allowActions.includes(uiId) ? Link : undefined}
                to={getRoute(pathFunction(uiId), uiId)}>
          <IconButton
                className="py-0 px-2"
                size="small"
                
              >
                <Icon fontSize="small">{icon}</Icon>
            </IconButton>
            <span style={{ fontSize: 12 }}>{name}</span>
          </MenuItem>
        ))}
      </Menu>
    </>
    )
}

export default MenuActions