import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const uploadFile = createAsyncThunk(
  'eCommerceApp/files/uploadFile',
  async ({ file, addTo,image_customisation_type:ics }) => {
    const formData = new FormData();
    formData.append('file', file);
    if (addTo) {
      formData.append('addTo', addTo);
    }
    if (ics)
    formData.append('image_customisation_type', ics)

    const response = await axios.post('/api/files', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    const data = await response.data.file;
    return data;
  }
);

export const getFile = createAsyncThunk('eCommerceApp/files/getFile', async (id, { getState }) => {
  const response = await axios.get(`/api/files/${id}`);
  const data = await response;
  return data;
});

const filesSlice = createSlice({
  name: 'eCommerceApp/files',
  initialState: null,
  reducers: {},
  extraReducers: {
    [uploadFile.fulfilled]: (state, action) => {},
    [getFile.fulfilled]: (state, action) => {},
  },
});

export default filesSlice.reducer;
