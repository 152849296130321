import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Attachments from '../shared/Attachments';

const Documents = ({ attachmentsProps = {}, heading = 'Documents', isView = false }) => {
    const [map, setMap] = useState('documents');
    const properties = {
        type: 'document',
        category: 'document',
        allowAdd: true,
        addTo: 'Enquiry',
        allowDelete: true,
        acceptedTypes: 'image/*,.pdf',
        ...attachmentsProps
    };

    return (
        <Accordion
            className="border-0 shadow-0 overflow-hidden pb-8 bg-transparent"
            expanded={map === 'documents'}
            onChange={() => setMap(map !== 'documents' ? 'documents' : false)}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{
                    root: 'border-b-2 border-solid mb-24 px-0 border-gray-200',
                }}
            >
                <Typography color="textSecondary" variant="h6" className="font-semibold">
                    {heading}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className="flex flex-col md:flex-row -mx-0 px-0">
                <Attachments {...properties} allowAdd={!isView} allowDelete={!isView} />
            </AccordionDetails>
        </Accordion>
    );
};

export default Documents;