import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { verifyLogin, resendLoginVerification } from 'app/store/auth/loginSlice';
import * as yup from 'yup';
import _ from '@lodash';
import FormHelperText from '@mui/material/FormHelperText';
import withRouter from '@fuse/core/withRouter';
import dispatchMessage from 'app/main/apps/common/AuraFunctions';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  code: yup.string().required('You must enter a code'),
});

const defaultValues = {
  code: '',
};

function JWTLoginVerification(props) {
  const dispatch = useDispatch();
  const login = useSelector(({ auth }) => auth.login);
  const { control, setValue, formState, handleSubmit, reset, trigger, setError } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    setValue('code', '');
    setValue('trust', true, { shouldDirty: true, shouldValidate: true });
  }, [reset, setValue, trigger]);

  function onSubmit(model) {
    dispatch(verifyLogin(model))
      .unwrap()
      .then((response) => {
        if (!response.payload || (response.payload && !response.payload.message)) {
          props.navigate(`/`);
        }
      });
  }

  function resendVerificationCode() {
    dispatch(resendLoginVerification())
      .unwrap()
      .then((response) => {
        if (response && response.data && response.data.message) {
          dispatchMessage(dispatch, response.data.message);
        }
      });
  }

  return (
    <div className="w-full">
      <form className="flex flex-col justify-center w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex ">
          <span className="mb-16 mt-16 font-medium text-12">
            You have received an email which contains login code. If you haven't received,{' '}
            <span
              onKeyDown={resendVerificationCode}
              onClick={resendVerificationCode}
              role="button"
              tabIndex={0}
              className="font-bold text-cyan-700 hover:text-cyan-900 text-12"
            >
              press it here.
            </span>
          </span>
        </div>
        <Controller
          name="code"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mb-16"
              label="Code"
              error={!!errors.code}
              helperText={errors?.code?.message}
              variant="outlined"
              type="text"
              InputProps={{
                className: 'pr-2',
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton size="large">
                      <Icon className="text-20" color="action">
                        lock
                      </Icon>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
            />
          )}
        />
        <div className="flex flex-row">
          <Controller
            name="trust"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                className="mb-16"
                label="Trust Device"
                name="trust"
                variant="outlined"
                s={value.toString()}
                checked={value}
                onChange={(ev) => onChange(ev.target.checked)}
              />
            )}
          />
          <Typography className="mt-10 font-medium text-14">
            Don’t ask again on this device
          </Typography>
        </div>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="w-full mx-auto mt-16"
          aria-label="LOG IN"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          value="legacy"
        >
          Verify
        </Button>
      </form>
      {login && login.verificationErrorMessage && (
        <FormHelperText className="mt-10 text-12 text-center" error>
          {login.verificationErrorMessage}
        </FormHelperText>
      )}
      <div className="h-128" />
    </div>
  );
}

export default withRouter(JWTLoginVerification);
