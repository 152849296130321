import React from 'react';
import { Button, Typography } from '@mui/material';
import AuraBaseDialog from 'app/main/apps/common/AuraBaseDialog';

const DeleteConfirmation = ({ open, onClose, onSubmit }) => {
    return (
        <>
            <AuraBaseDialog
                dialogProps={{ open }}
                closeDialog={onClose}
                dialogParam={{ title: 'Delete Confirmation', submitButtonName: 'Yes' }}
                onSubmit={onSubmit}
                additionalButton={<Button onClick={onClose} variant='outlined' color='secondary'>No</Button>}
                disableSubmitButton={() => { }}
                noValidate
            >
                <Typography>Are you sure you want to delete this item? </Typography>
            </AuraBaseDialog>
        </>
    );
};

export default DeleteConfirmation;