import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import _ from '@lodash';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'app/store/userSlice';
import { useLocation, useParams } from 'react-router-dom';
import { showMessage } from 'app/store/fuse/messageSlice';
import { Grid } from '@mui/material';
import { StyledTableRow, StyledTableCell, formatDateString } from '../../common/AuraFunctions';

Order.propTypes = {
  order: PropTypes.object.isRequired,
};

function getOrderTotal(_entities) {
  return _.sumBy(_entities, function (item) {
    return _.multiply(_.toNumber(item.selected_quantity || item.quantity), _.toNumber(item.weight));
  });
}

function Order(props) {
  const {
    order,
    customersOrders,
    customerId,
    orderChange,
    isShipmentData,
    shipmentData,
    type,
    reAllocationChange,
    orderIndex,
    prevValue,
    orderNo,
    decedents,
    totalWeight,
    tableProps = {},
    isNewShipment
  } = props;

  const { role } = useSelector(selectUser)
  const isSupplier = role === 'supplier'
  const location = useLocation()
  const dispatch = useDispatch()
  const { shipmentId } = useParams();

  const getHeader = () => {
    if (isSupplier)
      return <>
        <TableCell padding="checkbox" />
        <TableCell>LEN</TableCell>
        <TableCell>THK</TableCell>
        <TableCell>HGT</TableCell>
        <TableCell>Color</TableCell>
        <TableCell>Shape</TableCell>
        <TableCell>Polish</TableCell>
        <TableCell>Sub Type</TableCell>
        <TableCell>Quantity</TableCell>
        <TableCell>Weight</TableCell>
        {!isNewShipment && <TableCell>Price</TableCell>}
        <TableCell>Total Weight</TableCell>
        {!isNewShipment && <TableCell>Amount</TableCell>}
        <TableCell>Reference No</TableCell>
        <TableCell>Decedents</TableCell>
        <TableCell>Box No *</TableCell>


      </>
    if (("/dashboard").includes(location?.pathname)) {
      return <>
        <TableCell>Order Item</TableCell>
        <TableCell>Decendent</TableCell>
        <TableCell>Vas</TableCell>
        <TableCell>Weight</TableCell>
      </>
    }
    return <>
      <TableCell padding="checkbox">
        {!type && <Checkbox
          color="primary"
          checked={order.is_selected === 1}
          inputProps={{
            'aria-label': 'select all',
          }}
          onChange={(event) => {
            orderChange(event, customerId, order.id);
          }}
        />}
      </TableCell>
      <TableCell>Description</TableCell>
      <TableCell>Requested</TableCell>
      <TableCell>Confirmed</TableCell>
      <TableCell>Stock</TableCell>
      <TableCell>Ordered</TableCell>
      <TableCell>Committed</TableCell>
      <TableCell align="right">Weight</TableCell>
    </>
  }

  const getMinMax = (index, product) => {
    const ids = prevValue.fromOrder[0]?.orders.map(o => o.order_detail?.map(od => ({ id: od?.product_id, max: od?.quantity - od?.confirmed_quantity })))[0]
    const allowed = ids?.find(({ id }) => id === product.product_id)?.max;
    const fromMax = prevValue.toOrder[0]?.orders[0].order_detail.find(detail => detail.product_id === product.product_id)?.confirmed_quantity || 0
    const toMax = prevValue.fromOrder[0]?.orders[0].order_detail.find(detail => detail.product_id === product.product_id)?.confirmed_quantity || 0
    const max = Math.min(fromMax, allowed) || 0
    const isMiniumum = product.confirmed_quantity < toMax;
    const isMaximum = product.confirmed_quantity > (max + toMax)
    const availableProducts = prevValue.toOrder[0]?.orders[0].order_detail.map(od => od.product_id)

    return {
      error: isMaximum || isMiniumum,
      // eslint-disable-next-line
      errorText: isMaximum ? `Maximum ${max + toMax}` : isMiniumum ? `Minimum ${toMax}` : '',
      disabled: !availableProducts || !availableProducts.includes(product.product_id),
      max: max + toMax,
      min: toMax
    }
  }

  const staffTableBody = order?.order_detail?.map((product, index) => {
    const validations = type ? getMinMax(index, product) : {}
    return (
      <TableRow key={product.id}>
        <TableCell padding="checkbox">
          {!type && <Checkbox
            color="primary"
            checked={product.is_selected === 1}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
            onChange={(event) => {
              orderChange(event, customerId, order.id, product.id, true);
            }}
          />}
        </TableCell>
        <TableCell component="th" scope="row">
          <span className="truncate">
            {`${product.color} ${product.type}  ${product.length || ''
              }X${product.width || ''}X${product.height || ''} ${product.polish}`}
          </span>
        </TableCell>
        <TableCell className="w-1">{product.quantity}</TableCell>
        {type === 'toOrder' && <TableCell className='w-1'>{product.confirmed_quantity}</TableCell>}
        {!type && <TableCell className="w-1">
          <TextField
            id="type-id"
            value={product.selected_quantity}
            type="number"
            variant="standard"
            fullWidth
            className="p-0"
            size="small"
            onChange={(event) => {
              orderChange(event, customerId, order.id, product.id, true, true);
            }}
            InputProps={{
              classes: {
                input: 'FuseSearch-input py-4 h-20 w-64',
                notchedOutline: 'rounded-8',
              },
              inputProps: { max: product.quantity, min: 0 },
            }}
            error={product.selected_quantity > product.requested_quantity}
            helperText={product.selected_quantity > product.requested_quantity && `Maximum ${product.requested_quantity}`}
          />
        </TableCell>}
        {type === 'fromOrder' && <TableCell className="w-1">
          {validations.disabled ? product.confirmed_quantity :
            <TextField
              id="type-id"
              value={product.confirmed_quantity}
              type="number"
              variant="standard"
              fullWidth
              className="p-0"
              size="small"
              onChange={(event) => {
                reAllocationChange(orderIndex, index, event.target.value, product.product_id);
              }}
              InputProps={{
                classes: {
                  input: 'FuseSearch-input py-4 h-20 w-64',
                  notchedOutline: 'rounded-8',
                },
                inputProps: { max: validations.max, min: validations.min },
              }}
              error={validations.error}
              helperText={validations.errorText}
              disabled={validations.disabled}
            />}
        </TableCell>}
        <TableCell className="w-1">{product.product.units_in_stock}</TableCell>
        <TableCell className="w-1">{product.on_order}</TableCell>
        <TableCell className="w-1">{product.committed}</TableCell>
        <TableCell align="right" className="w-1">
          {(product.selected_quantity === undefined ? product.confirmed_quantity : product.selected_quantity || 0) * (product.weight || 0)} lbs
        </TableCell>
      </TableRow>
    )
  })

  const supplierTableBody = order?.order_detail?.map((product, index) => {
    return (
      <TableRow key={product.id}>
        <TableCell padding="checkbox">
          {!type && !tableProps.hideCheckbox && <Checkbox
            color="primary"
            checked={product.is_selected === 1}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
            onChange={(event) => {
              orderChange(event, customerId, order.id, product.id);
            }}
          />}
        </TableCell>
        <TableCell>
          {product.length}
        </TableCell>
        <TableCell>
          {product.width}
        </TableCell>
        <TableCell>
          {product.height}
        </TableCell>
        <TableCell>
          {product.color}
        </TableCell>
        <TableCell>
          {product.type}
        </TableCell>
        <TableCell>
          {product.polish}
        </TableCell>
        <TableCell>
          {product.sub_type}
        </TableCell>
        <TableCell>
          <TextField
            variant='standard'
            className='number-no-scroll'
            size='small'
            type='number'
            onChange={(e) => {
              const { value } = e.target;
              if (e.target.validity.valid || value === '') {
                props.onChange(index, value, 'newQuantity');
              }
            }}
            disabled={!product.is_selected && shipmentId === 'new'}
            value={product.newQuantity}
            defaultValue={product.quantity}
            InputProps={{ inputProps: { min: 1, max: product.quantity } }}
            error={!!product.quantity_error}
            helperText={product.quantity_error || ' '}
          />
        </TableCell>
        <TableCell>
          {product.weight} lbs
        </TableCell>
        {!isNewShipment && <TableCell>
          ${product.unit_price}
        </TableCell>}
        <TableCell>
          {Number(product.quantity) * Number(product.weight)} lbs
        </TableCell>
        {!isNewShipment && <TableCell>
          {Number(product.quantity) * Number(product.unit_price)}
        </TableCell>}
        <TableCell>{product?.customer_provider_number}</TableCell>
        <TableCell>{product?.decedent}</TableCell>

        <TableCell className="w-160">
          <div className='flex items-center gap-10'>
            <TextField
              variant='standard'
              className='number-no-scroll'
              size='small'
              type='number'
              onChange={(e) => {
                if (e.target.validity.valid || e.target.value === '')
                  props.onChange(index, e.target.value, 'box_number_from')
              }}
              disabled={!product.is_selected && shipmentId === 'new'}
              value={product.box_number_from || ''}
              InputProps={{ inputProps: { min: 0, pattern: "[0-9]*", } }}
              error={!!product.box_number_from_error}
              helperText={product.box_number_from_error || ' '}
            />
            to
            <TextField
              variant='standard'
              className='number-no-scroll'
              size='small'
              type='number'
              onChange={(e) => {
                if (e.target.validity.valid || e.target.value === '')
                  props.onChange(index, e.target.value, 'box_number_to')
              }}
              disabled={!product.is_selected && shipmentId === 'new'}
              value={product.box_number_to || ''}
              InputProps={{ inputProps: { min: 0, pattern: "[0-9]*", } }}
              error={!!product.box_number_to_error}
              helperText={product.box_number_to_error || ' '}
            />
          </div>
        </TableCell>

      </TableRow >
    )
  })

  const recieveOrderTableBody = () => {
    return <TableRow>
      <TableCell>{order.name}</TableCell>
      <TableCell>{decedents}</TableCell>
      <TableCell>{order.vas ? 'Yes' : 'No'}</TableCell>
      <TableCell>{order.weight}</TableCell>
    </TableRow>
  }

  const getTableBody = () => {
    if (isSupplier) {
      return supplierTableBody
    }
    if (("/dashboard").includes(location?.pathname)) {
      return recieveOrderTableBody()
    }
    return staffTableBody
  }

  const getWeight = () => {
    if (isShipmentData) {
      return order.weight
    }
    if (("/dashboard").includes(location?.pathname)) {
      return order.weight
    }
    return getOrderTotal(order.order_detail)
  }

  const onChange = (event) => {
    if (isSupplier && event.target.checked) {
      const selectedWeight = _.sumBy(order.order_detail, (detail) => {
        return _.multiply(detail.quantity, detail.weight)
      })
      // if (totalWeight + selectedWeight > 40000)
      //   return dispatch(showMessage(({ type: 'info', message: 'You have crossed the allowed limit of 40,000 lbs, Please select the order to match the limit.' })))
    }

    if (tableProps.showShipTo) {
      customersOrders?.forEach(item => {
        if (item?.id !== customerId) {
          orderChange(false, item?.id, item?.orders[0].id);
        }
      });
    }

    return orderChange(event, customerId, order.id);
  }

  return (
    <>
      <StyledTableRow>
        <StyledTableCell align="left" style={{ padding: 0 }}>
          {isSupplier && shipmentId === 'new' ? < Checkbox
            color="primary"
            checked={order.is_selected === 1}
            inputProps={{
              'aria-label': 'select all',
            }}
            onChange={onChange}
          /> : null}
          <Typography className={`inline-block ${isShipmentData ? 'p-10 font-medium' : 'p-0'}`} >
            Order: #<span className="font-normal">{order.order_no ? order.order_no : orderNo}</span></Typography>
          <Grid item >
            <div className='ml-10'>
              {isShipmentData && (
                <div >
                  <Typography className="font-medium truncate mb-8" color="inherit">
                    Order Date : <span className="font-normal">{formatDateString(order.date)}</span>
                  </Typography>
                  <Typography className="font-medium truncate mb-8" color="inherit">
                    Delivery Location : <span className="font-normal">{order.location}</span>
                  </Typography>
                  <Typography className="font-medium truncate mb-8" color="inherit">
                    Order Type : <span className="font-normal">{order.order_type}</span>
                  </Typography>
                  <Typography className="font-medium truncate mb-5 " color="inherit">
                    Order Weight : <span className="font-normal">{getWeight()} lbs</span>
                  </Typography>
                </div>
              )}
            </div>

          </Grid>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {order.tags}
        </StyledTableCell>
        {!isShipmentData && <StyledTableCell align="right">
          {order.eta_date && tableProps.showETA ? `ETA : ${formatDateString(order.eta_date)}` : ''}
          <Typography className="font-medium truncate mx-8" color="inherit">
            Order Weight : {getWeight()} lbs
          </Typography>
        </StyledTableCell>}
      </StyledTableRow>
      <TableRow>
        {isShipmentData ? (
          <>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, whiteSpace: 'pre' }} colSpan={6}>
              <Box sx={{ margin: 1 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Order Item</TableCell>
                      <TableCell>Weight</TableCell>
                      {/* <TableCell> Status</TableCell> */}
                      {/* <TableCell>Order Level Packing</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order?.order_detail?.map((product) => (
                      <TableRow key={product.id}>
                        <TableCell component="th" scope="row">
                          <span className="truncate">
                            {`${product.color} ${product.type || ''} ${product.length || ''}X${product.width || ''
                              }X${product.height || ''} ${product.polish || ''}`}
                          </span>
                        </TableCell>
                        <TableCell align="right" className="w-1">
                          {product.weight} lbs
                        </TableCell>

                        {/* <TableCell className="w-1">{product.status}</TableCell> */}
                        <TableCell className="w-1" />

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </TableCell>
          </>
        ) : (
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }} colSpan={15}>
            <Box sx={{ margin: 1 }}>
              <Table
                sx={{
                  '&.simple tbody tr td': {
                    paddingTop: '0px',
                    paddingBottom: '0px'
                  },
                }}
                className={['admin', 'staff', 'sales'].includes(role) ? '' : 'simple'}
                size='small'>
                <TableHead>
                  <TableRow>
                    {getHeader()}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getTableBody()}
                </TableBody>
              </Table>
            </Box>
          </TableCell>
        )}
      </TableRow >
    </>
  );
}

OrdersWithDetails.propTypes = {
  orders: PropTypes.array.isRequired,
};

export default function OrdersWithDetails(props) {
  const {
    orders,
    customerId,
    orderChange,
    isShipmentData,
    shipmentData,
    type,
    reAllocationChange,
    prevValue,
    onChange,
    orderNo,
    decedents,
    totalWeight,
    tableProps,
    isNewShipment,
    shipmentIndex,
    customersOrders
  } = props;

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {orders.map((order, orderIndex) => (
            <Order
              decedents={decedents}
              orderNo={orderNo}
              key={order.id}
              order={order}
              orderChange={orderChange}
              customerId={customerId}
              isShipmentData={isShipmentData}
              shipmentData={shipmentData}
              // Reallocation Props
              type={type}
              reAllocationChange={reAllocationChange}
              orderIndex={orderIndex}
              prevValue={prevValue}
              onChange={(detailIndex, value, field) => onChange(orderIndex, detailIndex, value, customerId, field, shipmentIndex)}
              totalWeight={totalWeight}
              tableProps={tableProps}
              isNewShipment={isNewShipment}
              customersOrders={customersOrders}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
