import {
  Button,
  Collapse,
  Icon,
  IconButton,
  InputAdornment,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Box,
  Checkbox
} from '@mui/material';
import format from 'date-fns/format';
import _ from '@lodash';
import { Fragment, useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { cloneDeep, omit, sumBy } from 'lodash';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import AuraBaseDialog from 'app/main/apps/common/AuraBaseDialog';
import { Loader } from 'app/main/apps/common/ListInfoMessage';
import OrderHeader from 'app/main/apps/common/OrderHeader';
import { AuraSimpleTableHead } from 'app/main/apps/common/AuraSimpleTable';
import { showMessage } from 'app/store/fuse/messageSlice';
import ImageViewerWindow from 'app/main/dashboards/main/dialogs/ImageViewerWindow';
import pdfViewerWindow from 'app/main/dashboards/main/dialogs/pdfViewerWindow';
import { chooseSupplierForArtWork, closeChooseSupplierDialog, getQuotedSuppliers, updateQuotedSuppliersList, saveOrder, updateOrder } from '../../store/orderSlice';
import { getOrders } from '../../store/ordersSlice';


function ChooseSupplier({ isDashboard, loadOrders }) {
  const dispatch = useDispatch();
  const suppliersList = useSelector((state) => state.eCommerceApp.order?.quotedSuppliersList?.orders);
  const parentId = useSelector((state) => state.eCommerceApp?.order?.quotedSuppliersList?.parent_order_details)
  const [selected, setSelected] = useState('');
  const [accordionIndex, setAccordionIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [next, setNext] = useState(true)
  const [selectedIndex, setSelectIndex] = useState()
  const [approve, setApprove] = useState(false)
  const [action, setAction] = useState({ approve: false, revision: false, reject: false })
  const [weightIndex, setWeightIndex] = useState({})
  const [open, setOpen] = useState({});
  const [selectedImage, setSelectedImage] = useState({})

  const { props, param, completeOrderData, name, status, stage } = useSelector(
    ({ eCommerceApp }) => eCommerceApp?.order?.chooseSupplierDialog
  );

  const schema = yup.object().shape({
    tariff: yup.string().nullable(),
    shipping: yup.string().nullable(),
    // fob: yup.string().required('You must provide fob'),
    margin: yup.string().nullable(),
  });

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      tariff: 0,
      shipping: 0,
      margin: 0,
      note: '',
      order_details: []
    },
  });

  const {
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
    getValues,
    setError,
    handleSubmit: formSubmit
  } = methods;

  useEffect(() => {
    // const orderDetail = suppliersList?.flatMap(list => list.order_details.map((od => ({ vas: od.vas, weight: od.weight }))));
    const orderDetail = suppliersList?.[selectedIndex]?.order_details.map((od) =>
      ({ vas: od.vas, weight: od.weight })
    )
    setValue('order_details', orderDetail)
  }, [suppliersList, selectedIndex])

  const tariff = watch('tariff');
  const shipping = watch('shipping');
  const orderDetailsForm = watch('order_details')
  const margin = watch('margin');

  const hideForStatus = () => {
    if (status === 'Artwork Inprogress') return true
    return false
  }

  const updateSupplierData = (e, field, selectedOrder) => {
    const { value } = e.target
    dispatch(updateQuotedSuppliersList({ field, value, selectedIndex, selectedOrder }))
  }

  const supplierHeaders = [
    { name: 'Name' },
    { name: 'Email' },
    { name: 'Weight', hide: hideForStatus() },
    { name: 'Qty', hide: hideForStatus() },
    { name: 'Amount', hide: hideForStatus() },
    // { name: 'Choose Documents', hide: suppliersList?.length === 1 },
    { name: 'Status' },
    { name: 'Actions' },
  ];


  const supplierProductsHeaders = [
    { name: 'Name' },
    { name: 'Sub Type' },
    { name: 'Weight', hide: hideForStatus() },
    { name: 'Quantity', hide: hideForStatus() },
    { name: 'FOB', hide: hideForStatus() },
    { name: 'Margin', hide: hideForStatus() },
    { name: 'Price', hide: hideForStatus() },
    { name: 'Discount', hide: hideForStatus() },
    { name: 'Amount', hide: hideForStatus() }
  ]

  useEffect(() => {
    if (props?.open && param) {
      setLoading(true);
      dispatch(getQuotedSuppliers({ id: param, params: { stage: name === "Choose Supplier for Proof" ? 'rfd' : 'quote' } })).finally(() => setLoading(false));
    }
  }, [props, param]);

  useEffect(() => {
    if (props?.open) {
      reset();
      setSelected('');
      setAccordionIndex(null);
      setNext(true);
      setApprove(false)
      setSelectedImage({})
    }
  }, [reset, props?.open]);


  useEffect(() => {
    suppliersList?.[selectedIndex]?.order_details.forEach((data, ind) => {
      setWeightIndex(prev => ({ ...prev, [ind]: data.weight }))
    })
  }, [suppliersList, selectedIndex, props.open])


  const onClose = () => dispatch(closeChooseSupplierDialog());

  const calculatePrice = (fob, wgt, qty, mrg, isPrice, discount) => {
    const shipVal = Number(wgt) * Number(shipping);
    const tariffVal = Number(fob) * (Number(tariff) / 100);
    const value = (Number(fob) + shipVal + tariffVal) / (1 - Number(mrg / 100));
    const price = value && Number(value) ? value.toFixed(0) : 0;
    if (isPrice)
      return price
    let wholeSalePrice = Number(price)
    if (discount > 0) {
      const discountValue = wholeSalePrice * discount / 100
      wholeSalePrice -= discountValue
    }
    return Math.round(wholeSalePrice);
  }

  const handleSubmit = (values) => {
    if (next)
      setNext(false)
    else setNext(true)
    setApprove(false)
  };

  const custom = () => {
    return parentId?.map((parent, index) => {
      const child = suppliersList[selectedIndex].order_details[index]
      return {
        ...omit(getValues(), ['note']),
        id: parent.product_id,
        unit_price: calculatePrice(child.unit_price, child.weight, child.quantity, child.margin, true),
      }
    })
  }

  const approveOrRejectQuote = (isApprove, note, toStatusCode) => {
    if (isApprove)
      setApprove(true)
    if (isApprove && note === '') {
      setError('note', { message: 'You must provide note' })
      return
    }
    dispatch(
      updateOrder({
        id: suppliersList[selectedIndex].id,
        to_statuses_type_id: toStatusCode,
        updateStatus: 'yes',
        note
      })
    ).then((res) => {
      if (!res.error) {
        dispatch(showMessage({ type: 'success', message: 'Success' }));
        if (isDashboard && loadOrders) {
          loadOrders();
        }
        dispatch(getOrders({ params: { stage } }))
      }
    })
    onClose()
  }


  const SupplierHandler = ({ note }, isApprove, toStatusCode) => {
    console.log('submitting')
    if (isApprove)
      approveOrRejectQuote(isApprove, note, toStatusCode)
    else {
      let isValidVas = true
      let payload = {
        supplier: {},
        new_documents: [],
        note
      };

      let supplierIndex
      suppliersList.forEach((data, index) => {
        const { supplier, order_details: od } = data;


        if (selected === supplier.id) {
          payload.supplier = { ...supplier, is_selected: true, };
          payload.order_details = od;
          supplierIndex = index
        }
        if (accordionIndex !== null && accordionIndex === index) {
          payload.new_documents = data.uploadedFile?.map(({ id }) => ({ id }));
        }
        payload = cloneDeep(payload)
      });
      payload.order_details.forEach((od, sIndex) => {
        if (completeOrderData?.image_customisation === 'vas') {
          const vasPrice = getValues().order_details[sIndex].vas
          if (!vasPrice?.imagePrice && (vasPrice?.images?.[0]?.name || vasPrice?.images?.[0]?.value || vasPrice?.images?.[0]?.url)) {
            isValidVas = false
            setError(`order_details.${sIndex}.vas.imagePrice`, { message: 'Required' })
          }
          if (!vasPrice?.textPrice && (vasPrice.text?.[0]?.name || vasPrice.text?.[0]?.value || vasPrice.text?.[0]?.url)) {
            isValidVas = false
            setError(`order_details.${sIndex}.vas.textPrice`, { message: 'Required' })
          }
        }
      })

      if (!isValidVas) return

      payload.order_details.forEach((od, sIndex) => {
        const foundProuduct = suppliersList[accordionIndex]?.order_details.find(data => data.product.name === od.product.name)
        if (foundProuduct)
          payload.order_details[sIndex].new_drawing_documents = foundProuduct.drawing_documents
        else {
          const image = []
          od?.drawing_documents?.forEach((doc, docIndex) => {
            if (selectedImage?.[supplierIndex]?.[sIndex]?.[docIndex]) {
              image.push(od.drawing_documents[docIndex])
              od.new_drawing_documents = image
            }
          })
          od?.proof_documents?.forEach((doc, docIndex) => {
            if (selectedImage?.[supplierIndex]?.[sIndex]?.[docIndex]) {
              image.push(od.proof_documents[docIndex])
              od.new_proof_documents = image
            }
          })
        }
        delete od.drawing_documents;
        delete od.proof_documents;
        const parentOrder = parentId?.find((item) => item.id === od.parent_order_detail_id)
        od.vas = getValues().order_details[sIndex].vas ? JSON.stringify({ ...parentOrder.vas, ...getValues().order_details[sIndex].vas }) : null
        od.weight = weightIndex[sIndex]
      })

      if (status !== 'Artwork Inprogress') {
        dispatch(saveOrder({ ...payload, isQuote: true, id: param, custom_product: custom() }))
          .then((res) => {
            if (!res.error) {
              dispatch(showMessage({ type: 'success', message: 'Success' }));
              if (isDashboard && loadOrders) {
                loadOrders();
              }
              dispatch(getOrders({ params: { stage } }))
            }
          })
          .finally(onClose);
      }
      else {
        const { new_documents: newDocumnets, note: Note, order_details: orderDetails } = payload
        orderDetails.forEach((od, sIndex) => {
          const image = []
          od?.proof_documents?.forEach((doc, docIndex) => {
            if (selectedImage?.[supplierIndex]?.[sIndex]?.[docIndex]) {
              image.push(od.proof_documents[docIndex])
              od.new_proof_documents = image
            }
          })
          delete od.proof_documents
          delete od.vas
        })
        const artWorkPayload = {
          new_documents: newDocumnets,
          note: Note,
          order_details: orderDetails,
          id: param
        }
        dispatch(chooseSupplierForArtWork(artWorkPayload)).then((res) => {
          if (!res.error) {
            dispatch(showMessage({ type: 'success', message: 'Success' }));
            if (isDashboard && loadOrders) {
              loadOrders();
            }
            dispatch(getOrders({ params: { stage } }))
          }
        }).finally(onClose)
      }

    }
  }

  const handleSupplierSelect = (supplier, od, index) => {
    setSelected(supplier.id);
    setSelectIndex(index)
    if (supplier.profile) {
      setValue('margin', Number(supplier.profile.margin) || 0);
      setValue('shipping', Number(supplier.profile.shipping) || 0);
      setValue('tariff', Number(supplier.profile.tariff) || 0);
    }
    handleSubmit()
  };
  const getOrderLabel = () => {
    switch (status) {
      case 'Received Supplier Quote':
        return 'RFQ'
      case 'Artwork Inprogress':
        return 'Order'
      case 'Design Inprogress':
        return 'Order'
      default:
        return 'Order';
    }
  }

  const headerData = {
    enable: true,
    component: OrderHeader,
    data: {
      order: completeOrderData,
      hideAmount: true,
      hideQuantity: status === 'Artwork Inprogress',
      stage: getOrderLabel()
    },
  };

  const renderImages = (images, index, sInd) => {
    return <>
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        className="mt-6"
      >
        Documents
      </Typography>
      <div className='flex'>
        {images?.map((media, selectedImageIndex) => {
          return (
            <>
              <div>
                <Checkbox
                  className='flex-start'
                  checked={selectedImage?.[sInd]?.[index]?.[selectedImageIndex] || false}
                  onChange={(event) => {
                    const newSelectedImages = selectedImage?.[sInd] || [];
                    newSelectedImages[index] = {
                      ...(newSelectedImages[index] || {}),
                      [selectedImageIndex]: event.target.checked
                    };
                    setSelectedImage({ [sInd]: newSelectedImages });
                  }}
                />
              </div>
              <div
                className="justify-center w-128 h-128 rounded-16 mr-12 overflow-hidden outline-none shadow hover:shadow-lg relative cursor-pointer"
                key={media.id}
                onClick={() => {
                  if (!_.startsWith(media['Content-Type'], 'image/')) {
                    pdfViewerWindow(media.data)
                  } else {
                    ImageViewerWindow([media])
                  }
                }}
                onKeyDown={() => { }}
                role="button"
                tabIndex={0}
              >
                <img
                  src={
                    _.startsWith(media['Content-Type'], 'image/')
                      ? `data:${media['Content-Type']};base64,${media.data}`
                      : 'assets/icons/pdf.png'
                  }
                  className="max-w-none w-auto h-full"
                  alt="product"
                  aria-hidden="true"
                />
                <p className='mb-24 text-center w-96 m-auto text-sm'>{media?.filename || media?.name}</p>
              </div>
            </>
          );
        })}
      </div>
    </>
  }

  const getTitle = () => {
    let title = 'Approve'
    if (action.revision) title = 'Request Revision'
    if (action.reject) title = 'Reject'
    return title
  }

  const getVasPrice = (type, index, propertyKey, row) => {
    return (
      <tr>
        <td className="w-800 px-8 py-4" style={{ marginLeft: '100px' }} colSpan={8}>
          {type}
        </td>
        <td className="w-64 px-8 py-4">
          <Controller
            control={control}
            name={`order_details.${index}.vas.${propertyKey}`}
            render={({ field }) => (
              <>
                <TextField
                  {...field}
                  type="number"
                  variant="standard"
                  className="p-0"
                  size="small"
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  error={!!errors.order_details?.[index]?.vas?.[propertyKey]}
                  helperText={errors.order_details?.[index]?.vas?.[propertyKey]?.message}
                  disabled={action?.reject}
                  required
                /></>
            )}
          />
        </td>
        <td colSpan={2} />
      </tr>
    );
  };

  const getWidth = () => {
    if (status === 'Artwork Inprogress') return '850px'
    if (completeOrderData?.image_customisation === 'vas') return '550px'
    return 'auto'
  }


  const onChange = (rowIndex, value) => {
    setWeightIndex(prev => ({ ...prev, [rowIndex]: value }))
  }
  const getProductDetail = (products, index, isWeightEditable, allFiles, sInd) => {
    const vas = orderDetailsForm?.[index]?.vas || {}
    const vasPrice = Number(vas?.textPrice || 0) + Number(vas?.imagePrice || 0) || 0
    return (
      <>
        <TableRow>
          <TableCell className="px-8 py-4">
            {products.name} {products.type} {products.polish}
          </TableCell>
          <TableCell className="px-8 py-4">
            {products.sub_type}
          </TableCell>

          {status !== 'Artwork Inprogress' && <>
            <TableCell className="px-8 py-4">
              {
                isWeightEditable ? (
                  <Controller
                    control={control}
                    name={`order_details.${index}.weight`}
                    rules={{
                      required: {
                        value: true,
                        message: 'Required'
                      }
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="number-no-scroll w-60"
                        type="number"
                        onChange={(e) => {
                          field.onChange(e.target.value)
                          updateSupplierData(e, 'weight', index)
                        }}
                        variant="standard"
                        size="small"
                        label=''
                        required
                        error={!!errors?.order_details?.[index]?.weight}
                        helperText={errors?.order_details?.[index]?.weight?.message}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">lbs</InputAdornment>
                          )
                        }}
                        disabled={action?.reject}
                      />
                    )}
                  />
                ) : <>{products.weight} lbs</>
              }
            </TableCell>
            <TableCell className="px-8 py-4">
              {products.quantity}
            </TableCell>
            <TableCell className="px-8 py-4">
              {`$${products.unit_price}`}
            </TableCell>
            <>
              <TableCell className="px-8 py-4">
                {
                  isWeightEditable && (
                    <Controller
                      control={control}
                      name={`order_details.${index}.margin`}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="number-no-scroll w-60"
                          type="number"
                          onChange={(e) => {
                            field.onChange(e.target.value)
                            updateSupplierData(e, 'margin', index)
                          }}
                          variant="standard"
                          size="small"
                          label=''
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">%</InputAdornment>
                            )
                          }}
                          disabled={action?.reject}
                        />
                      )}
                    />)}
              </TableCell>
              <TableCell className="px-8 py-4">
                ${calculatePrice(products.unit_price, products.weight, products.quantity, products.margin, true)}
              </TableCell>
              <TableCell className="px-8 py-4">
                {products.discount || 0}%
              </TableCell>
              <TableCell className="px-8 py-4">
                ${(calculatePrice(products.unit_price, products.weight, products.quantity, products.margin, false, products.discount) * products.quantity) + vasPrice}
              </TableCell>
            </>
          </>}
        </TableRow>
        {!isWeightEditable && (
          <TableRow>
            < Box sx={{ margin: 2 }}>
              <Collapse in timeout="auto" unmountOnExit>
                {renderImages(products.drawing_documents || products.proof_documents, index, sInd)}
              </Collapse>
            </Box>
          </TableRow>
        )}
      </>
    )
  }


  const supplierHasDocument = (pdetail, ind, allFiles, sInd) => {
    // const orderDetail = { vas: pdetail.vas, weight: pdetail.weight }
    // setValue(`order_details.${ind}`, orderDetail)
    return (
      <Table>
        <AuraSimpleTableHead
          headerList={supplierProductsHeaders}
          classes={{ cnCell: 'font-semibold px-8 py-8' }}
        />
        <TableBody>
          {getProductDetail(pdetail, ind, false, allFiles, sInd)}
        </TableBody>
      </Table>
    )
  }


  useEffect(() => {
    suppliersList?.forEach((data) => {
      data.order_details.forEach((od, key) => {
        setOpen(prev => ({ ...prev, [key]: false }))
      })
    })
  }, [props?.open])



  return (
    <FormProvider {...methods}>
      <AuraBaseDialog
        dialogProps={{ open: props?.open }}
        closeDialog={onClose}
        dialogParam={{
          maxWidth: 'md',
          title: next ? name : getTitle(),
          hideDialogActions: true,
        }}
        disableSubmitButton={() => false}
        header={headerData}
        additionalButton={
          !next &&
          <>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              onClick={handleSubmit}
              disabled={!selected}
            >
              Previous
            </Button>
            {action.approve && (
              <Button
                variant="contained"
                color="success"
                type="submit"
                onClick={formSubmit((data) => SupplierHandler(data, false))}
              >
                Submit For Approval
              </Button>)}
            {action.revision && (
              <Button
                variant="contained"
                color="warning"
                type="submit"
                onClick={formSubmit((data) => SupplierHandler(data, true, 'QUOTE_REVISIONS_REQUESTED'))}
              >
                Request Revision
              </Button>
            )}
            {action.reject && (
              <Button
                variant="contained"
                color="error"
                type="submit"
                onClick={formSubmit((data) => SupplierHandler(data, true, 'QUOTE_CANCELED'))}
              >
                Reject
              </Button>
            )}
          </>
        }
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            {next && <div className="table-responsive w-full">
              <Table className="simple w-full min-w-full">
                <AuraSimpleTableHead
                  headerList={supplierHeaders}
                  classes={{ cnCell: 'font-semibold px-8 py-8' }}
                />
                <TableBody>
                  {suppliersList?.map((data, index) => {
                    const { supplier = {}, order_details: od = [], uploadedFile } = data;
                    const hasDrawingDocuments = od.some(d => d.drawing_documents || d.proof_documents);
                    const allFiles = []
                    if (uploadedFile?.length)
                      allFiles.push(...uploadedFile)
                    od?.forEach(l => {
                      if (l.drawing_documents)
                        allFiles.push(...l.drawing_documents)
                      if (l.proof_documents)
                        allFiles.push(...l.proof_documents)
                    })

                    return (
                      <Fragment key={index}>
                        <TableRow>
                          <TableCell scope="row">
                            <Typography>{supplier.name}</Typography>
                          </TableCell>
                          <TableCell scope="row">
                            <Typography>{supplier.email}</Typography>
                          </TableCell>
                          {status !== 'Artwork Inprogress' && <>
                            <TableCell scope="row">
                              <Typography>{data.weight} lbs</Typography>
                            </TableCell>
                            <TableCell scope="row">
                              <Typography>{data.quantity}</Typography>
                            </TableCell>
                            <TableCell scope="row">
                              <Typography>${data.amount}</Typography>
                            </TableCell>
                          </>}
                          {/* {suppliersList?.length > 1 &&
                            <TableCell scope="row">
                              {allFiles.length ? (
                                <Switch
                                  size="small"
                                  checked={index === accordionIndex}
                                  onClick={(e) => setAccordionIndex(e.target.checked ? index : null)}
                                />
                              ) : null}
                            </TableCell>
                          } */}
                          <TableCell scope="row">
                            <Typography>{data.status?.name}</Typography>
                          </TableCell>
                          <TableCell scope="row">
                            {["Requested", "Submitted"].includes(data.status?.name) &&
                              <div className='flex'>
                                {
                                  name !== 'Revisions To Supplier' && (
                                    <Tooltip title='Approve'>
                                      <FuseSvgIcon onClick={() => {
                                        setAction({ approve: true, revision: false, reject: false })
                                        handleSupplierSelect(supplier, od, index)
                                      }} className="text-48 cursor-pointer" size={24} color="action">material-outline:done</FuseSvgIcon>
                                    </Tooltip>
                                  )
                                }
                                <Tooltip title='Request Revision'>
                                  <FuseSvgIcon onClick={() => {
                                    setAction({ approve: false, revision: true, reject: false })
                                    handleSupplierSelect(supplier, od, index)
                                  }} className="text-48 cursor-pointer">material-outline:restart_alt</FuseSvgIcon>
                                </Tooltip>
                                <Tooltip title='Reject'>
                                  <FuseSvgIcon onClick={() => {
                                    setAction({ approve: false, revision: false, reject: true })
                                    handleSupplierSelect(supplier, od, index)
                                  }} className="text-48 cursor-pointer">material-outline:clear</FuseSvgIcon>
                                </Tooltip>
                                {hasDrawingDocuments && (
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpen((prev) => ({ ...prev, [index]: !prev[index] }))}
                                    title="Show Product Detail"
                                  >
                                    <Icon>
                                      {open[index] ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                    </Icon>
                                  </IconButton>
                                )}

                              </div>}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                            {open[index] && suppliersList.map((sList, sInd) => {
                              if (sInd !== index) return null
                              return (
                                sList?.order_details?.map((pdetail, ind) => {
                                  if (pdetail?.drawing_documents?.length || pdetail?.proof_documents?.length) {
                                    return supplierHasDocument(pdetail, ind, allFiles, sInd)
                                  }
                                  return null;
                                })
                              )
                            })}
                            {/* < Box sx={{ margin: 5 }}>
                              <Collapse in timeout="auto" unmountOnExit>
                                {renderImages(allFiles)}
                              </Collapse>
                            </Box> */}

                          </TableCell>
                        </TableRow>
                      </Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </div>}
            {!next && <>
              <div className="table-responsive w-full">
                <Table className="simple w-full min-w-full">
                  <AuraSimpleTableHead
                    headerList={supplierProductsHeaders}
                    classes={{ cnCell: 'font-semibold px-8 py-8' }}
                  />
                  <TableBody>
                    {
                      selectedIndex !== undefined && suppliersList && suppliersList[selectedIndex]?.order_details.map((products, index) => {
                        console.log('completeOrderData', completeOrderData, products)
                        return (
                          <Fragment key={products.id}>
                            {getProductDetail(products, index, true)}
                            {(products?.vas?.text?.[0]?.name || products?.vas?.text?.[0]?.value || products?.vas?.text?.[0]?.url) && getVasPrice('Sandblasting', index, 'textPrice')}
                            {(products?.vas?.images?.[0]?.name || products?.vas?.images?.[0]?.value || products?.vas?.images?.[0]?.url) && getVasPrice('Etching', index, 'imagePrice')}
                          </Fragment>
                        )
                      })}
                  </TableBody>
                </Table>

              </div>
              <div className='flex mt-14 gap-10 justify-between'>
                <div>
                  {(status !== 'Artwork Inprogress') && (
                    <div className="flex gap-6">
                      {['Tariff', 'Shipping'].map((label) => (
                        <Controller
                          control={control}
                          key={label}
                          name={label.toLowerCase()}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="mb-24"
                              label={['Tariff', 'Margin'].includes(label) ? `${label} (%)` : label}
                              id={label.toLowerCase()}
                              variant="outlined"
                              fullWidth
                              size="small"
                              type="number"
                              error={!!errors[label.toLowerCase()]}
                              helperText={errors[label.toLowerCase()]?.message}
                              InputProps={{
                                startAdornment: ['Shipping'].includes(label) && (
                                  <InputAdornment position="start">$</InputAdornment>
                                ),
                                inputProps: { min: 0 },
                              }}
                              disabled={action?.reject}
                            />
                          )}
                        />
                      ))}
                    </div>
                  )}
                  {
                    selectedIndex !== undefined && suppliersList && suppliersList[selectedIndex]?.note_histories?.length > 0 && (
                      <div className='mb-10 p-10 overflow-scroll border !border-black' style={{ height: '19rem' }}>
                        {
                          suppliersList[selectedIndex].note_histories.map((note, index) => {
                            return (
                              <div key={`key-${index}`}>
                                <div className='font-semibold'>{note?.added_by}<span className='text-xs text-slate-400 font-light ml-4'>{format(new Date(note.added_on), 'MMM dd, yyyy h:mm a')}</span></div>
                                <div className='font-light mb-10'>{note?.note}</div>
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  }
                  <div style={{ width: getWidth() }}>
                    <Controller
                      control={control}
                      name='note'
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label='Notes'
                          required={approve}
                          multiline
                          minRows={3}
                          maxRows={5}
                          fullWidth
                          error={!!errors.note}
                          helperText={errors.note?.message}
                        />
                      )}
                    />
                  </div>

                </div>

                {status !== 'Artwork Inprogress' && (<div className="flex justify-end flex-col w-1/3">
                  <Table className="simple mt-20">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography className="font-normal text-13 ">Total Item(s)</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography className="font-normal">
                            {suppliersList && sumBy([suppliersList[selectedIndex]], function (o) {
                              return o?.quantity;
                            })}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography className="font-normal text-13 ">Total Weight</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography className="font-normal">

                            {suppliersList && _.sumBy(_.map(suppliersList[selectedIndex].order_details, (o, index) => {
                              const quantity = Number(o?.quantity);
                              const itemWeight = weightIndex[index];
                              return quantity * itemWeight;
                            }), _.identity)} lbs

                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography className="font-bold text-13">
                            TOTAL
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <div className="flex flex-row justify-end">
                            <Typography className="font-normal" variant="h6">
                              ${selectedIndex !== undefined && suppliersList &&
                                suppliersList[selectedIndex]?.order_details.reduce((sum, o, index) => {
                                  const vas = orderDetailsForm?.[index]?.vas || {};
                                  const vasPrice = Number(vas?.textPrice || 0) + Number(vas?.imagePrice || 0) || 0;
                                  return sum + (calculatePrice(o.unit_price, o.weight, o.quantity, o.margin, false, o.discount) * o.quantity) + vasPrice;
                                }, 0)
                              }

                            </Typography>
                            <Typography className="font-normal text-red" variant="h6">
                              *
                            </Typography>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>)}
              </div>
            </>}
          </>
        )}
      </AuraBaseDialog>
    </FormProvider>
  );
}

export default memo(ChooseSupplier);