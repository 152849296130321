import TextField from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { InputAdornment } from '@mui/material';
import AuraControlledAutocomplete from 'app/main/apps/common/AuraControlledAutocomplete';

function PaymentFormContent(props) {
  const methods = useFormContext();
  const { control, formState, getValues, trigger } = methods;
  const { errors } = formState;
  const isReadOnly = props.isReadOnly ? props.isReadOnly : false;
  const showOrderAmountDetails = true;

  async function onPaymentTypesUpdate(event, value) {
    if (value && getValues().amount) {
      // trigger amount validation
      await trigger('amount');
    }
  }

  return (
    <>
      {showOrderAmountDetails && (
        <div className="flex flex-col md:flex-row w-full">
          <div className="flex w-full mb-16">
            <Typography className="font-medium" color="textSecondary">
              Total Amount:
            </Typography>
            <Typography className="font-medium pl-16" color="textPrimary">
              ${getValues().data.amount}
            </Typography>
            <Typography className="font-medium pl-16" color="textSecondary">
              Paid Amount:
            </Typography>
            <Typography className="font-medium pl-16" color="textPrimary">
              ${getValues().data.paid_amount}
            </Typography>
            <Typography className="font-medium pl-16" color="textSecondary">
              Balance Amount:
            </Typography>
            <Typography className="font-medium pl-16" color="textPrimary">
              ${getValues().data.balance_amount}
            </Typography>
          </div>
        </div>
      )}
      <div className="flex flex-col md:flex-row w-full">
        <div className="flex w-full md:w-1/2">
          <AuraControlledAutocomplete
            control={control}
            controlName="payment_mode"
            inputValues={props.paymentTypes}
            labelName="Payment Type *"
            readOnly={isReadOnly}
            className={errors && errors?.payment_type?.message ? 'mt-8 mb-0' : 'mt-8 mb-16'}
            onUpdate={onPaymentTypesUpdate}
            renderOption={(renderprops, option) => (
              <li {...renderprops} className="py-2 px-8">
                <div className="capitalize">{option.name}</div>
              </li>
            )}
            hideClearable
          />
          {errors && errors?.payment_type?.message && (
            <FormHelperText className="mt-10 text-12 text-center" error={!!errors.payment_type}>
              {errors?.payment_type?.message}
            </FormHelperText>
          )}
        </div>
        <div className="flex w-full md:w-1/2 md:pl-16">
          <Controller
            control={control}
            name="date"
            render={({ field }) => (
              <TextField
                {...field}
                className="mt-8 mb-16"
                id="date"
                label="Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                fullWidth
                required
                error={!!errors.date}
                helperText={errors?.date?.message}
                size="small"
                InputProps={{
                  readOnly: isReadOnly,
                }}
              />
            )}
          />
        </div>
        <div className="flex w-full md:w-1/2 md:pl-16">
          <Controller
            name="amount"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mt-8 mb-16"
                id="amount"
                label="Amount"
                variant="outlined"
                fullWidth
                size="small"
                type="number"
                required
                error={!!errors.amount}
                helperText={errors?.amount?.message}
                InputProps={{
                  readOnly: isReadOnly,
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
              />
            )}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row w-full">
        <div className="flex w-full">
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                label="Notes"
                id="description"
                variant="outlined"
                fullWidth
                size="small"
                multiline
                rows={3}
                error={!!errors.note}
                helperText={errors?.note?.message}
              />
            )}
          />
        </div>
      </div>
    </>
  );
}

export default PaymentFormContent;
