import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';

function ValueSectionSmall(props) {
  const { value, title, isAmount, type, className } = props;

  return (
    <div className={clsx(className, 'p-16')}>
      <Typography className="text-sm font-medium leading-none" color="text.secondary">
        {title}
      </Typography>
      <Typography className="mt-8 font-mono text-md leading-none">
        {isAmount
          ? value?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })
          : value}{' '}
        {type}
      </Typography>
    </div>
  );
}

ValueSectionSmall.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isAmount: PropTypes.oneOf([true, false]),
  type: PropTypes.string,
};

ValueSectionSmall.defaultProps = {
  title: '',
  value: 0,
  className: '',
  isAmount: false,
  type: '',
};

export default ValueSectionSmall;
