const jwtServiceConfig = {
  signIn: 'api/auth/login',
  logout: 'api/logout',
  signUp: 'api/auth/sign-up',
  accessToken: 'api/auth/access-token',
  updateUser: 'api/user/update',
  getUser: 'api/users/me',
};

export default jwtServiceConfig;
