import ClientAppConfig from './client/ClientAppConfig';
import AllAppConfig from './e-commerce/AllAppConfig';
import StaffAppConfig from './e-commerce/StaffAppConfig';
import CustomerAppConfig from './e-commerce/CustomerAppConfig';
import SettingsAppConfig from './settings/SettingsAppConfig';
import BackofficeConfig from './backoffice/BackofficeConfig';
import SupplierAppConfig from './e-commerce/SupplierAppConfig';

const appsConfigs = [
  AllAppConfig,
  StaffAppConfig,
  CustomerAppConfig,
  ClientAppConfig,
  SettingsAppConfig,
  BackofficeConfig,
  SupplierAppConfig,
];

export default appsConfigs;
