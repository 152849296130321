import clsx from 'clsx';
import { useSelector,useDispatch } from 'react-redux';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import { useEffect } from 'react';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { Box } from '@mui/material';
import { getCartItems } from 'app/store/auth/cartsSlice';
import withRouter from '../../../@fuse/core/withRouter';

const UserCart = (props) => {
  const allState = useSelector((state) => state);
  const dispatch = useDispatch();
  function handleClick() {
    props.navigate(`/cart`);
  }

  useEffect(() => {
    dispatch(getCartItems());
  }, [dispatch]);

  return (
    <Box sx={{ cursor: 'pointer' }} onClick={(event) => handleClick()}>
      <Stack spacing={2} direction="row">
        <Badge
          badgeContent={
            allState.auth && allState.auth.carts ? allState.auth.carts.totalCartItem : 0
          }
          color="secondary"
        >
          <ShoppingCartCheckoutIcon
            color="action"
            className={clsx('', props.className)}
            onClick={(event) => handleClick()}
          />
        </Badge>
      </Stack>
    </Box>
  );
};

export default withRouter(UserCart);
