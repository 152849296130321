import { lazy } from 'react';
import { authRoles } from 'app/auth';

const Customers = lazy(() => import('./customers/Customers'));
const Customer = lazy(() => import('./customer/Customer'));
const Employees = lazy(() => import('./employees/Employees'));
const Employee = lazy(() => import('./employee/Employee'));
const Suppliers = lazy(() => import('./suppliers/Suppliers'));
const Supplier = lazy(() => import('./supplier/Supplier'));

const ClientAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.staff,
  routes: [
    {
      path: 'customers',
      element: <Customers />,
    },
    {
      path: 'customers/:customerId',
      element: <Customer />,
    },
    {
      path: 'employees',
      element: <Employees />,
    },
    {
      path: 'employees/:employeeId',
      element: <Employee />,
    },
    {
      path: 'suppliers',
      element: <Suppliers />,
    },
    {
      path: 'suppliers/:supplierId',
      element: <Supplier />,
    },
  ],
};

export default ClientAppConfig;
