import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import clsx from 'clsx';
import Table from '@mui/material/Table';

export const AuraSimpleTableHead = ({ headerList, classes, sxStyles }) => {
  const cnHead = classes && classes.cnHead ? classes.cnHead : '';
  const cnRow = classes && classes.cnRow ? classes.cnRow : '';
  const cnCell = classes && classes.cnCell ? classes.cnCell : 'p-8';
  return (
    <TableHead className={cnHead}>
      <TableRow className={cnRow}>
        {headerList.map((header, index) => (
          !header.hide &&
          <TableCell
            key={index}
            className={clsx(header.cn ? header.cn : '', cnCell)}
            style={header.style ? header.style : {}}
          >
            {header.name}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const AuraSimpleTableBody = ({ dataList, dataFieldList, classes, sxStyles }) => {
  const cnBody = classes && classes.cnBody ? classes.cnBody : '';
  const cnRow = classes && classes.cnRow ? classes.cnRow : '';
  const cnCell = classes && classes.cnCell ? classes.cnCell : 'p-8';
  return (
    <TableBody className={cnBody}>
      {dataList.map((row, index) => (
        <TableRow key={`row_${index}_${row.id}`} className={cnRow}>
          {dataFieldList.map((field, fieldIndex) => (
            <TableCell
              key={`cell_${index}_${row.id}_${fieldIndex}`}
              className={clsx(field.cn ? field.cn : '', cnCell && cnCell !== '' ? cnCell : 'p-8')}
              style={field.style ? field.style : {}}
            >
              {row[field.name]}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

export const AuraSimpleTable = ({ headerList, dataList, dataFieldList, classes, sxStyles }) => {
  const sxTableStyle = sxStyles && sxStyles.table ? sxStyles.table : { minWidth: 650 };
  return (
    <Table sx={sxTableStyle} aria-label="table">
      <AuraSimpleTableHead headerList={headerList} classes={classes} sxStyles={sxStyles} />
      <AuraSimpleTableBody
        dataList={dataList}
        fieldNames={dataFieldList}
        classes={classes}
        sxStyles={sxStyles}
      />
    </Table>
  );
};
