import { combineReducers } from '@reduxjs/toolkit';
import login from './loginSlice';
import carts from './cartsSlice';
import resetPasswordSlice from './resetPasswordSlice';
import reviewOrderSlice from './reviewOrderSlice';

const authReducers = combineReducers({
  login,
  carts,
  resetPasswordSlice,
  reviewOrderSlice
});

export default authReducers;
