import Table from '@mui/material/Table';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import AuraTableTopToolBar from 'app/main/apps/common/AuraTableTopToolBar';
import AuraTablePaginationActions from './AuraTablePaginationActions';
import ListInfoMessage, { Loader } from './ListInfoMessage';

const AuraTable = ({
  columns,
  data,
  updateMyData,
  AddItemToCart,
  onRowClick,
  skipPageReset = false,
  resetFilters,
  cellData = null,
  toolbarParam = null,
  showPagination = true,
  elevation = 1,
  includeAllOptions = true,
  setCartObj,
  serverPaginationProps,
  headers,
  params,
  updateParams,
  onSorting,
  loaderProps,
  loading = false,
  showListInfo = false
}) => {
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize, selectedRowIds, sortBy },
    setGlobalFilter,
    setAllFilters,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
      autoResetPage: !skipPageReset,
      autoResetExpanded: !skipPageReset,
      autoResetGroupBy: !skipPageReset,
      autoResetSelectedRows: !skipPageReset,
      autoResetSortBy: !skipPageReset,
      autoResetFilters: !skipPageReset,
      autoResetRowState: !skipPageReset,
      manualPagination: Boolean(serverPaginationProps),
      manualSortBy: Boolean(serverPaginationProps),
      // updateMyData, AddItemToCart aren't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
      AddItemToCart,
      setCartObj,
      cellData,
      params,
      updateParams
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );
  const ref = useRef()
  const location = useLocation();

  let count = rows.length;
  let rowsPerPage = pageSize;
  let currentPage = pageIndex;
  // ServerPaginationProps indicates we are using serverside pagination and not using local
  if (serverPaginationProps) {
    count = Number(headers['app-pagination-total-records'] || 0);
    rowsPerPage = Number(headers['app-per-page-limit'] || pageSize);
    currentPage = Number(headers['app-pagination-current-page-num'] || 0)
  }

  const handleChangePage = (event, newPage) => {
    if (serverPaginationProps)
      return serverPaginationProps.changePage(newPage)
    return gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    if (serverPaginationProps) {
      return serverPaginationProps.perPage(Number(event.target.value))
    }
    return setPageSize(Number(event.target.value));
  };

  useEffect(() => {
    if (resetFilters === true) setAllFilters([]);
  }, [resetFilters, setAllFilters]);

  useEffect(() => {
    const sp = new URLSearchParams(location.search);
    const sortArr = sp.get('sort')?.split(',');
    if (sp.get('sort')) {
      const param = columns.find(col => col.paramKey === sortArr[0]);
      setSortBy([{ id: param?.accessor, desc: sortArr[1] === 'DESC' }]);
    }
  }, [location.search]);

  useEffect(() => {
    if (serverPaginationProps)
      if (sortBy.length) {
        const param = columns.find(col => col.accessor === sortBy[0].id)
        onSorting(param?.paramKey, sortBy[0].desc);
      }
      else {
        if (ref.current)
          onSorting('', '');
        ref.current = true
      }
  }, [sortBy]);

  // if (loaderProps?.show)
  //   return <ListInfoMessage loading={loaderProps.loading} message={loaderProps.message} />

  // Render the UI for your table
  return (
    <Paper elevation={elevation} sx={{ width: '100%', overflow: 'hidden', padding: '10px' }}>
      {toolbarParam && (
        <AuraTableTopToolBar
          toggleAllRowsSelected={toggleAllRowsSelected}
          selectedFlatRows={selectedFlatRows}
          selectedRowIds={selectedRowIds}
          toolbarParam={toolbarParam}
        />
      )}
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table {...getTableProps()} stickyHeader>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    className={clsx(
                      'whitespace-nowrap font-semibold bg-white',
                      column.disablePadding ? 'p-0' : 'px-4 pt-0 pb-8',
                      column.className
                    )}
                    {...(!column.sortable
                      ? column.getHeaderProps()
                      : column.getHeaderProps(column.getSortByToggleProps()))}
                  >
                    {column.render('Header')}
                    {column.sortable ? (
                      <TableSortLabel
                        active={column.isSorted}
                        // react-table has an unsorted state which is not treated here
                        direction={column.isSortedDesc ? 'desc' : 'asc'}
                      />
                    ) : null}
                    {column.showFilter ? column.render('Filter') : null}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {
              !loading && showListInfo && rows?.length === 0 && page?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={headerGroups[0].headers.length} sx={{ position: 'relative' }}>
                    <div className="loader-overlay">
                      <ListInfoMessage loading={false} message="No data available!" />
                    </div>
                  </TableCell>
                </TableRow>
              )
            }
            {(loading ? (
              <TableRow>
                <TableCell colSpan={headerGroups[0].headers.length} sx={{ position: 'relative' }}>
                  <div className="loader-overlay">
                    <Loader />
                  </div>
                </TableCell>
              </TableRow>
            ) : (serverPaginationProps ? rows : page).map((row, i) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  onClick={(ev) => {
                    if (onRowClick) onRowClick(ev, row);
                  }}
                  className={`truncate ${onRowClick ? 'cursor-pointer' : 'cursor-default'}`}
                >
                  {row.cells.map((cell) => (
                    <TableCell
                      {...cell.getCellProps()}
                      className={clsx(
                        cell.column.disablePadding ? 'p-0' : 'px-4 py-6 cu',
                        cell.column.className
                      )}
                      sx={cell.column.sxStyle}
                    >
                      <div>{cell.render('Cell')}</div>
                    </TableCell>
                  ))}
                </TableRow>
              );
            }))}
          </TableBody>
        </Table>
      </TableContainer>
      {showPagination && (
        <TablePagination
          component="div"
          classes={{
            root: 'shrink-0 border-t-1',
          }}
          rowsPerPageOptions={[
            5,
            10,
            25,
            // includeAllOptions ? { label: 'All', value: serverPaginationProps ? count : rows.length + 1 } : 50,
          ]}
          colSpan={5}
          count={count}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: false,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={AuraTablePaginationActions}
        />
      )}
    </Paper>
  );
};

AuraTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
};

export default AuraTable;
