import React from 'react';
import { Button, Typography } from '@mui/material';
import AuraBaseDialog from 'app/main/apps/common/AuraBaseDialog';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import _ from 'lodash';
import ImageViewerWindow from 'app/main/dashboards/main/dialogs/ImageViewerWindow';
import pdfViewerWindow from 'app/main/dashboards/main/dialogs/pdfViewerWindow';
import Attachments from '../../shared/Attachments';


const ApprovalContent = ({ type, open, onClose, hideDialogActions, title, heading, attachmentProps = {}, isview = false }) => {
    const methods = useFormContext()
    const { watch } = methods
    const properties = {
        category: 'document',
        allowAdd: true,
        addTo: 'Enquiry',
        allowDelete: true,
        acceptedTypes: 'image/*,.pdf',
        attachmentKey: type,
        ...attachmentProps,
    };
    const attachments = watch(attachmentProps.attachmentsType);
    return (
        <>
            <AuraBaseDialog
                dialogProps={{ open }}
                closeDialog={onClose}
                dialogParam={{ title, hideDialogActions: true }}
                onSubmit={onClose}
                disableSubmitButton={() => { }}
                additionalButton={<Button onClick={onClose} variant='contained' color='secondary'>Close</Button>}
            >
                {!isview && (
                    <>
                        <Typography variant='h6'>{heading}</Typography>
                        <div className='flex justify-center'>
                            <Attachments {...properties} />
                        </div>
                    </>
                )}
                {isview &&
                    (
                        <div className="grid grid-cols-4 gap-4">
                            {attachments.map((images, ind) => (
                                <>
                                    <div key={ind} className={clsx(
                                        'flex justify-center w-128 h-128 rounded-16 mr-12 overflow-hidden outline-none shadow hover:shadow-lg relative cursor-pointer')}>
                                        <img src={
                                            _.startsWith(images['Content-Type'], 'image/')
                                                ? `data:${images['Content-Type']};base64,${images.data}`
                                                : 'assets/icons/pdf.png'
                                        } onClick={() => {
                                            if (!_.startsWith(images['Content-Type'], 'image/')) {
                                                pdfViewerWindow(images.data)
                                            } else {
                                                ImageViewerWindow([images])
                                            }
                                        }} alt="fetch" aria-hidden="true" />

                                    </div>
                                    <p className='mb-24 text-center w-96 m-auto text-sm'>{images?.filename || images?.name}</p>
                                </>
                            ))}
                        </div>
                    )
                }
            </AuraBaseDialog>
        </>
    );
};

export default ApprovalContent;