import TextField from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';

function GenerateInvoice(props) {
    const methods = useFormContext();
    const { control, formState } = methods;
    const { errors } = formState;
    return (
        <>
            <div className="flex flex-col md:flex-row w-full gap-10">
                <div className="flex w-full ">
                    <Controller
                        control={control}
                        name="invoice_number"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className="mb-24"
                                label="Invoice Number"
                                id="invoice_number"
                                variant="outlined"
                                fullWidth
                                size="small"
                                required
                                error={!!errors.shipping_cost}
                                helperText={errors?.shipping_cost?.message}
                            />
                        )}
                    />
                </div>

            </div>
        </>
    );
}

export default GenerateInvoice;
