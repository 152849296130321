import React from 'react';
import _, { startsWith } from 'lodash';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import clsx from 'clsx';
import ImageViewerWindow from './ImageViewerWindow';

function ImageContent({ onChange, title, image, expanded }) {

    const openPDFWindow = (base64) => {
        // Assuming your base64 PDF data is stored in a variable called base64PDFData
        const base64PDFData = base64.replace(/^data:application\/pdf;base64,/, '');

        // Convert base64 to a blob
        const byteCharacters = atob(base64PDFData);
        const byteArrays = [];
        for (let i = 0; i < byteCharacters.length; i += 1) {
            byteArrays.push(byteCharacters.charCodeAt(i));
        }
        const byteArray = new Uint8Array(byteArrays);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        // Create a data URL from the blob
        const dataUrl = URL.createObjectURL(blob);

        // Open a new window or tab with the PDF data URL
        window.open(dataUrl, '_blank');
    };
    return (
        <Accordion
            className="border-0 shadow-0 overflow-hidden pb-8 bg-transparent"
            expanded={!!expanded}
            onChange={onChange}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{
                    root: 'border-b-2 border-solid mb-24 px-0 border-gray-200',
                }}
            >
                <Typography
                    color="textSecondary"
                    variant="h6"
                    className="font-semibold"
                >
                    {title}
                </Typography>

            </AccordionSummary>
            <AccordionDetails className="flex flex-col md:flex-row -mx-0 px-0">
                <div className='grid grid-cols-8 gap-4'>
                    {image?.map((media, ind) => (
                        <div
                            role="button"
                            onClick={() => {
                                if (!startsWith(media['Content-Type'], 'image/')) {
                                    openPDFWindow(media.data)
                                } else {
                                    ImageViewerWindow([media])
                                }
                            }}
                            onKeyDown={() => { }}
                            tabIndex={0}
                        >
                            <img
                                src={
                                    startsWith(media['Content-Type'], 'image/')
                                        ? `data:${media['Content-Type']};base64,${media.data}`
                                        : 'assets/icons/pdf.png'
                                }
                                className={clsx(
                                    'flex justify-center w-128 h-128 rounded-16 mr-12 mb-24 overflow-hidden outline-none shadow hover:shadow-lg relative cursor-pointer')}
                                alt="rfq"
                            />
                            <p className='mb-24 text-center w-96 m-auto text-sm'>{media?.filename || media?.name}</p>
                        </div>
                    ))}
                </div>
            </AccordionDetails>
        </Accordion>
    );
};


export default ImageContent;