import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

function NoItemMessage(props) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 0.1 } }}
      className="flex flex-col flex-1 items-center justify-center h-full"
    >
      {props.message ? (
        <Typography color="textSecondary" variant="h5">
          {props.message}
        </Typography>
      ) : (
        <Typography color="textSecondary" variant="h5">
          There is no such {props.itemName ? props.itemName : 'item'}!
        </Typography>
      )}
      {props.returnLink && (
        <Button
          className="mt-24"
          component={Link}
          variant="outlined"
          to={props.returnLink}
          state={props.linkState}
          color="inherit"
        >
          {props.itemsName ? `Go to ${props.itemsName} Page` : 'Go Back'}
        </Button>
      )}
    </motion.div>
  );
}

export default NoItemMessage;
