import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import ValueSectionSmall from '../../../common/ValueSectionSmall';

function ShipmentHeader(props) {
  const { name, quantity, weight, ordersCount, showShipTo = true } = props;
  const bulletName = showShipTo && name?.map((item, index) => {
    return `${index + 1}. ${item}`;
  });

  return (
    <Box boxShadow={2}>
      <div className="flex flex-wrap w-full items-center px-8 py-4 md:px-16 border-b">
        {showShipTo && <div className="flex flex-col flex-auto my-12 mx-8">
          <div className="flex items-center space-x-8">
            <Typography className="font-mono text-md">Ship To</Typography>
          </div>
          <div className="flex items-end mt-4 space-x-6">
            <Typography className="font-mono text-md whitespace-pre-wrap">{bulletName.join('\n')}</Typography>
          </div>
        </div>}
        <div className="hidden sm:flex items-center my-12 mx-8 rounded-xl border">
          <ValueSectionSmall title="No of Orders" value={ordersCount} />
          <Divider orientation="vertical" flexItem />
          <ValueSectionSmall title="Total Quantity" value={quantity} />
          <Divider orientation="vertical" flexItem />
          <ValueSectionSmall title="Total Weight" value={weight} type="lbs" />
        </div>
      </div>
    </Box>
  );
}

export default ShipmentHeader;
