import TextField from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';

function AddressFormContent(props) {
  const methods = useFormContext();
  const { control, formState } = methods;
  const { errors } = formState;
  const isReadOnly = props.isReadOnly ? props.isReadOnly : false;
  const addressLine1Required = props.addressLine1Required ? props.addressLine1Required : false;

  return (
    <>
      <div className="flex flex-col md:flex-row w-full">
        <div className="flex w-full">
          <Controller
            control={control}
            name="address.address_line1"
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                label="Address Line1"
                id="address_line1"
                variant="outlined"
                fullWidth
                size="small"
                required={!!addressLine1Required}
                error={!!errors.address?.address_line1}
                helperText={errors?.address?.address_line1?.message}
                InputProps={{
                  readOnly: isReadOnly,
                }}
              />
            )}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row w-full">
        <div className="flex w-full">
          <Controller
            control={control}
            name="address.address_line2"
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                label="Address Line 2"
                id="address_line2"
                variant="outlined"
                fullWidth
                size="small"
                InputProps={{
                  readOnly: isReadOnly,
                }}
              />
            )}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row w-full">
        <div className="flex w-full md:w-1/3">
          <Controller
            control={control}
            name="address.city"
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                label="City"
                id="city"
                variant="outlined"
                fullWidth
                size="small"
                InputProps={{
                  readOnly: isReadOnly,
                }}
              />
            )}
          />
        </div>
        <div className="flex w-full md:w-1/3 md:ml-16">
          <Controller
            control={control}
            name="address.region"
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                label="Region"
                id="region"
                variant="outlined"
                fullWidth
                size="small"
                InputProps={{
                  readOnly: isReadOnly,
                }}
              />
            )}
          />
        </div>
        <div className="flex w-full md:w-1/3 md:ml-16">
          <Controller
            control={control}
            name="address.postcode"
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                label="ZIP"
                id="postcode"
                variant="outlined"
                fullWidth
                size="small"
                InputProps={{
                  readOnly: isReadOnly,
                }}
              />
            )}
          />
        </div>
      </div>
    </>
  );
}

export default AddressFormContent;
