import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Controller, useFormContext } from 'react-hook-form';
import { number } from 'prop-types';

function DiscountFormContent(props) {
  const methods = useFormContext();
  const { control, formState } = methods;
  const { errors } = formState;

  return (
    <>
      <div className="flex flex-col md:flex-row w-full">
        <div className="flex w-full ">
          <Controller
            control={control}
            name="discount"
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                label="Discount"
                id="discount"
                variant="outlined"
                fullWidth
                size="small"
                type='number'
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                 error={!!errors.note}
                helperText={errors?.note?.message}
              />
            )}
          />
        </div>
      </div>
    </>
  );
}

export default DiscountFormContent;
