import { omit } from "lodash";
import axios from "axios"
import { getParams } from "./AuraFunctions";

async function downloadFile({ params, endPoint }) {
    const { filterObj, headerObj } = getParams()
    const headers = omit(headerObj, ['App-Pagination-Limit', 'App-Pagination-Num']);
    // const response = await axios.get(endPoint, { params: { ...params, ...filterObj }, headers, responseType: 'blob' })
    const response = await axios({
        method: 'get',
        url: endPoint,
        params: { ...params, ...filterObj },
        headers,
        responseType: 'blob'
    })
        .then((res) => {
            const disposition = res.headers['content-disposition']
            const matches = /filename=(.+)/.exec(disposition)
            const fileName = matches != null && matches[1] ? matches[1].slice(1, -1) : null

            // create file link in browser's memory
            const href = URL.createObjectURL(res.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', fileName || 'orders.xlsx'); // or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });

    return response;
}


export const downloadFileAttachments = (responseData) => {
    // Extract data from the response
    const { data, filename, mime } = responseData;

    // Convert base64 string to ArrayBuffer
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i += 1) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const fileBlob = new Blob([byteArray], { type: mime });

    // Create a temporary URL for the file
    const fileUrl = URL.createObjectURL(fileBlob);

    // Create a link element and trigger the download
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

    // Clean up resources
    URL.revokeObjectURL(fileUrl);
    document.body.removeChild(link);
}

export default downloadFile