import * as yup from 'yup';
import format from 'date-fns/format';
import { emptySchema, formatDateString } from 'app/main/apps/common/AuraFunctions';


function statusFormInitValues(data) {
  return {
    id: data && data.id ? data.id : null,
    status: data && data.status ? data.status : '',
    note: '',
  };
}

function noteFormInitValues(data) {
  return {
    id: data && data.id ? data.id : null,
    note: data && data.note ? data.note : '',
  };
}

function containerNumberFormInitValues(data) {
  return {
    shipment_id: data && data?.shipment_id,
    container_number: data && data?.container_number
  }
}

function confirmFormInitValues(data) {
  return {
    id: data && data.id ? data.id : null,
    note: '',
    order_no: data && data.order_no ? data.order_no : '',
  };
}

function addressFormInitValues(data) {
  return {
    id: data && data.id ? data.id : null,
    cid: data && data.customer && data.customer.id ? data.customer.id : null,
    address_id: data && data.customer && data.customer.address_id ? data.customer.address_id : null,
    address: data && data.customer && data.customer.address ? data.customer.address : null,
  };
}

function paymentFormInitValues(data) {
  return {
    id: data?.id,
    data: data
      ? {
        id: data.id,
        amount: data.invoice_amount,
        paid_amount: data.paid_amount || 0,
        balance_amount: data.due_amount,
        payment_status: data.payment_status,
      }
      : null,
    payment_mode:
      data && data.payment && data.payment.payment_type ? data.payment.payment_type : null,
    date:
      data && data.payment && data.payment.date
        ? data.payment.date
        : format(new Date(), 'yyyy-MM-dd'),
    amount: data && data.payment && data.payment.amount ? data.payment.amount : '',
    description: data && data.payment && data.payment.description ? data.payment.description : '',
  };
}

function completeShipmentFormInitValues(data) {
  return {
    shipment_id: data?.id,
    container_tracking_number: '',
    eta_date: '',
    shipping_company: null,
    invoice_number: ''
  };
}

function confirmInvoiceFormInitValues(data) {
  return {
    id: data && data.id ? data.id : null,
    shipping_cost: data && data.shipping_cost ? data.shipping_cost : null,
    comments_to_send: data && data.comments_to_send ? data.comments_to_send : ''
  }
}

function editOrderFormInitValues(data) {
  return {
    id: data && data.id ? data.id : null,
    eta_date: data && data.eta_date ? formatDateString(data.eta_date, 'yyyy-MM-dd') : '',
    location: data && data.location ? data.location : 'Columbus',
    order_type: data && data.order_type ? { name: data.order_type.name } : null,
    ship_to: data && data.ship_to ? data.ship_to : '',
    sales_person: data && data?.sales_person
  }
}

/**
 * Form Init Values
 */
export default function getInitValues(type, data) {
  if (type && type === 'container_number') {
    console.log('data', data)
    return containerNumberFormInitValues(data)
  }
  if (type && type === 'status') {
    return statusFormInitValues(data);
  }
  if (type && type === 'note') {
    return noteFormInitValues(data);
  }
  if (type && type === 'confirm') {
    return confirmFormInitValues(data);
  }
  if (type && type === 'address') {
    return addressFormInitValues(data);
  }
  if (type && type === 'payment') {
    return paymentFormInitValues(data);
  }
  if (type && type === 'complete_shipment') {
    return completeShipmentFormInitValues(data);
  }
  if (type && type === 'confirm_invoice') {
    return confirmInvoiceFormInitValues(data);
  }
  if (type && type === 'edit_order') {
    return editOrderFormInitValues(data)
  }
  return { id: data && data.id ? data.id : null };
}

const statusSchema = yup.object().shape({
  status: yup.object().required('You must enter a status'),
});

const noteSchema = yup.object().shape({
  note: yup.string().required('You must enter a note'),
});

const containerNumberSchema = yup.object().shape({
  container_number: yup.string().required('Container number is required'),
});

const reviewNoteSchema = yup.object().shape({
  isInternalNotes: yup.boolean(),
});

const confirmSchema = yup.object().shape({
  order_no: yup.string().required('You must enter a order no'),
});

const addressSchema = yup.object().shape({
  address: yup.object().shape({
    address_line1: yup.string().required('You must enter a value'),
  }),
});

const paymentSchema = yup.object().shape({
  payment_mode: yup
    .object()
    .shape({
      name: yup.string().required('You must enter a value'),
    })
    .required('You must select a type'),
  data: yup.object().shape({
    balance_amount: yup.number().nullable(),
    paid_amount: yup.number().nullable(),
  }),
  amount: yup.number().when('payment_mode.name', (paymentType) => {
    if (paymentType === 'Refund') {
      return yup
        .number()
        .nullable()
        .min(1, 'You must enter a valid amount')
        .typeError('You must enter a amount')
        .required('You must enter a amount')
        .max(yup.ref('data.paid_amount'), 'Refund amount must be less than paid amount');
    }
    return yup
      .number()
      .nullable()
      .min(1, 'You must enter a valid amount')
      .typeError('You must enter a amount')
      .required('You must enter a amount')
      .max(yup.ref('data.balance_amount'), 'Amount must be equal to or less than the remaining balance');
  }),
});

const completeShipmentSchema = yup.object().shape({
  container_tracking_number: yup
    .string()
    .required('You must enter tracking number')
    .min(11, 'Minimum 11 characters')
    .max(11, 'Maximum 11 characters'),
  shipping_company: yup.object().required('Required'),
  eta_date: yup.string().required('Required'),
  invoice_number: yup.string().required('You must enter invoice number').max(20, 'Maximum 20 characters')
});

const confirmInvoiceSchema = yup.object().shape({
  shipping_cost: yup.number().nullable().transform((value, originalValue) => (originalValue === '' ? null : value)),
  comments_to_send: yup.string().nullable()
})

const editOrderSchema = yup.object().shape({
  location: yup.string().nullable().required('You must enter a location'),
  order_type: yup.object().nullable().required('You must select a order type'),
  eta_date: yup.string().nullable(),
})
/**
 * Form Validation Schema
 */
export function getSchema(type) {
  if (type && type === 'container_number') {
    return containerNumberSchema
  }
  if (type && type === 'status') {
    return statusSchema;
  }
  if (type && type === 'review_note') {
    return reviewNoteSchema
  }
  if (type && type === 'note' || type === 'rejectQuote') {
    return noteSchema;
  }
  if (type && type === 'confirm') {
    return confirmSchema;
  }
  if (type && type === 'address') {
    return addressSchema;
  }
  if (type && type === 'payment') {
    return paymentSchema;
  }
  if (type === 'complete_shipment') {
    return completeShipmentSchema;
  }
  if (type === 'confirm_invoice') {
    return confirmInvoiceSchema
  }
  if (type === 'edit_order') {
    return editOrderSchema
  }
  return emptySchema;
}
