/**
 * Authorization Roles
 */
const authRoles = {
  admin: ['admin'],
  staff: ['admin', 'staff', 'sales'],
  supplier: ['admin', 'staff', 'supplier', 'sales'],
  customer: ['admin', 'staff', 'customer', 'sales'],
  users: ['admin', 'staff', 'supplier', 'customer', 'sales'],
  onlyGuest: [],
  onlyCustomer: ['customer'],
  onlySupplier: ['supplier'],
  onlyStaffAndAdmin: ['staff', 'admin', 'sales']

};

export default authRoles;
