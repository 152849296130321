import withReducer from 'app/store/withReducer';
import _ from '@lodash';
import { useEffect, useRef, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import FuseUtils from '@fuse/utils';
import { authPermissions } from 'app/auth';
import { selectUser } from 'app/store/userSlice';
import reducer from 'app/main/apps/e-commerce/store';
import { AuraSimplePageRoot, isCustomer } from '../../common/AuraFunctions';
import AuraHeader from '../../common/AuraHeader';
import EntitiesContent from './EntitiesContent';

function Entities(props) {
  const {
    headerData,
    entitiesSelector,
    getEntities,
    getColumns,
    selectEntities,
    getEntityStatuses,
    listType,
    statusCategory,
    setCustomer,
    setEntitiesSearchText,
    handleShow,
    uniqueName,
    filterType,
  } = props;
  const dispatch = useDispatch();
  const pageLayout = useRef(null);
  const location = useLocation();
  const customer =
    location && location.state && location.state.customer ? location.state.customer : null;
  const defaultSearch = useSelector(
    ({ eCommerceApp }) => eCommerceApp[entitiesSelector].searchText
  );
  const defaultCustomer = useSelector(
    ({ eCommerceApp }) => eCommerceApp[entitiesSelector].customer
  );
  const [resetFilters, setResetFilters] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(customer);
  const [searchText, setSelectedSearchText] = useState(defaultSearch);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const user = useSelector(selectUser);
  const isManage = user && FuseUtils.hasPermission(authPermissions.manage, user.role);
  const { role } = user

  const handleBackdropClose = () => {
    setOpenBackdrop(false);
  };

  const handleBackdropToggle = (value) => {
    setOpenBackdrop(value);
  };

  const handleResetFiltersClick = () => {
    setResetFilters(true);
    setTimeout(() => setResetFilters(false), 2000);
  };

  useEffect(() => {
    dispatch(setCustomer(selectedCustomer));
  }, [dispatch, selectedCustomer]);

  const handleSearchText = useCallback(
    (event) => {
      setSelectedSearchText(event.target.value);
      dispatch(setEntitiesSearchText(event));
      localStorage.setItem(`${uniqueName}TableGlobalFilter`, event.target.value);
    },
    [dispatch]
  );

  if (handleShow) {
    headerData.links.forEach((link, m) => {
      headerData.links[m].show = handleShow(link.id, isManage, customer, listType, user.role);
    });
  }
  const headerDataAdd = {
    customer,
    pageLayout,
    handleBackdropToggle,
    isManage,
    role: user.role,
    defaultCustomer,
    entitiesSelector,
    setCustomer,
    search: {
      default: searchText,
      onChange: handleSearchText,
      resetHandler: handleResetFiltersClick,
    },
    filter: {
      enabled: isManage || isCustomer(role),
    },
  };
  const DialogComponent = props.dialog?.component;
  const AdditionalDialogComponent = props.dialog?.additional?.component;
  return (
    <>
      <AuraSimplePageRoot
        header={<AuraHeader {..._.merge(headerData, headerDataAdd)} />}
        content={
          <EntitiesContent
            customer={customer}
            entitiesSelector={entitiesSelector}
            selectedCustomer={selectedCustomer}
            resetFilters={resetFilters}
            isManage={isManage}
            role={user.role}
            listType={listType}
            type={props.type}
            statusCategory={statusCategory}
            getEntities={getEntities}
            getEntityStatuses={getEntityStatuses}
            selectEntities={selectEntities}
            getColumns={getColumns}
            setCustomer={setCustomer}
            setEnquiriesSearchText={setEntitiesSearchText}
            uniqueName={uniqueName}
            handleSearchText={handleSearchText}
            filterType={filterType}
          />
        }
        scroll="page"
        ref={pageLayout}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={handleBackdropClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {props.dialog?.required && <DialogComponent {...props.dialog?.data} />}
      {props.dialog?.additional?.required && (
        <AdditionalDialogComponent {...props.dialog?.additional?.data} />
      )}
    </>
  );
}
export default withReducer('eCommerceApp', reducer)(Entities);
