import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

export const HelperTooltipWithIcon = ({ title = '', placement = 'top', arrow = true }) => {
    return (
        <BootstrapTooltip placement={placement} title={title} arrow={arrow}>
            <HelpCenterIcon color='info' fontSize='small' className='mt-7' />
        </BootstrapTooltip>
    )
}