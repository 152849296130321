import { Box, Button, Toolbar, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { makeStyles } from '@mui/styles';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import clsx from "clsx";
import { useState, useEffect } from "react";
import Confirm from "@fuse/core/FuseDialog/confirm";
import { showMessage } from "app/store/fuse/messageSlice";
import { setSelectedProducts, getProducts } from "../store/productsSlice";
import { openProductDialog } from "../store/productSlice";
import ProductDialog from "../product/dialogs/ProductDialog";
import { performAction } from "../store/orderSlice";

const ProductToolBar = (props) => {
  const dispatch = useDispatch();
  const { toolbarParam } = props;
  const { selectedProducts, filterType, loadPage } = toolbarParam
  const numSelected = selectedProducts.length;
  const productId = selectedProducts.map(products => products.id)
  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      minHeight: '45px',
      paddingLeft: '0px',
      color: theme.palette.text.primary,
    },
    colorBox: {},
  }));
  const classes = useToolbarStyles();
  useEffect(() => {
    dispatch(setSelectedProducts({ data: [] }))
  }, [filterType])
  return selectedProducts && selectedProducts.length > 0 ? (
    <Toolbar className={clsx(classes.root, 'flex items-start')}>
      <div className="flex flex-row items-center">
        <Box className="flex items-center font-medium">{numSelected}</Box>
        <Typography className="pl-8 font-medium" color="inherit">
          {toolbarParam.selectionMsg ? toolbarParam.selectionMsg : 'Row(s) Selected'}
        </Typography>
      </div>
      <div className="flex flex-row items-center">
        {["low_stock", "all"].includes(filterType) &&
          <Button
            color="secondary"
            aria-label="Re Order"
            size="small"
            variant="contained"
            sx={{ padding: '0px 15px', marginLeft: "10px" }}
            disabled={!selectedProducts.length}
            onClick={() => {
              dispatch(
                openProductDialog({
                  param: {
                    name: 'supplierOrder',
                    title: 'Create Supplier Order',
                    data: {},
                    loadProducts: loadPage
                  },
                  data: selectedProducts,
                })
              );
              dispatch(setSelectedProducts({ data: selectedProducts, type: 'dialog' }))
            }}
          >
            Re Order
          </Button>
        }
        <Button
          color="secondary"
          aria-label="Archive Product"
          size="small"
          variant="contained"
          sx={{ padding: '0px 15px', marginLeft: "10px" }}
          disabled={!selectedProducts.length}
          onClick={() =>
            dispatch(
              openDialog({
                children: (
                  <Confirm title={filterType === 'archive' ? 'Unarchive' : 'Archive'}
                    content={`Are you sure you want to permanently  ${filterType === 'archive' ? "unarchive the selected item?" : "archive the selected item?"}`}
                    onSubmit={() => {
                      dispatch(performAction({
                        ui_id: "ARCHIVE",
                        data: {
                          product_ids: productId,
                          is_archive: !(filterType === 'archive')
                        },
                      })).then(res => {
                        if (!res.error) {
                          dispatch(showMessage({ message: `Selected items have been  ${filterType === 'archive' ? 'restored' : ' moved to archive'}`, type: 'success' }))
                          dispatch(getProducts({ params: filterType, goToFirstPage: true }))
                          dispatch(setSelectedProducts({ data: [] }))
                        }
                      })
                    }}
                  />
                )
              }))
          }
        >
          {filterType === 'archive' ? 'Unarchive' : 'Archive'}
        </Button>
      </div>
      <ProductDialog />
    </Toolbar>
  ) : (
    <></>
  );
};
export default ProductToolBar;
