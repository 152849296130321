import * as yup from 'yup';
import { emptySchema } from 'app/main/apps/common/AuraFunctions';

export function productInitValues(data) {
  return {
    id: data && data.id ? data.id : null,
    name: data && data.name ? data.name : '',
    description: data && data.description ? data.description : '',
    code: data && data.code ? data.code : '',
    type: data && data.type ? data.type : { name: '' },
    sub_type: data && data.sub_type ? data.sub_type : { name: '' },
    stone_color: data && data.stone_color ? data.stone_color : '',
    category: data && data.category ? data.category : { name: '' },
    length: data && data.length ? data.length : '',
    width: data && data.width ? parseFloat(data.width) : '',
    height: data && data.height ? data.height : '',
    weight: data && data.weight ? data.weight : '',
    polish: data && data.polish ? data.polish : '',
    finish: data && data.finish ? data.finish : '',
    unit_cost: data && data.unit_cost ? data.unit_cost : '',
    unit_price: data && data.unit_price ? parseFloat(data.unit_price) : '',
    units_in_stock: data && data.units_in_stock ? data.units_in_stock : '',
    on_order: data && data.on_order ? data.on_order : '',
    committed: data && data.committed ? data.committed : '',
    reorder_point: data && data.reorder_point ? data.reorder_point : '',
    short_fall: data && data.short_fall ? data.short_fall : '',
    sku: data && data.sku ? data.sku : '',
    note: data && data.note ? data.note : '',
    is_taxable: data && data.is_taxable ? data.is_taxable : false,
    visible_to_wholesaler: data && data.visible_to_wholesaler ? data.visible_to_wholesaler : false,
    is_active: data && data.is_active ? data.is_active : false,
    images: data && data.images ? data.images : [],
    new_images: data && data.new_images ? data.new_images : [],
    tariff: data && data.tariff ? parseFloat(data.tariff) : '',
    shipping: data && data.shipping ? parseFloat(data.shipping) : '',
    fob: data && data.fob ? parseFloat(data.fob) : '',
    margin: data && data.margin ? parseFloat(data.margin) : '',
    order_quantity: data && data.order_quantity ? data.order_quantity : '',
    orders: data?.orders || [],
    is_custom: data?.is_custom,
    discount: data?.discount,
    quantity: data?.quantity,
    customer_provider_number: data?.customer_provider_number,
    comments: data?.comments,
    decedent: data?.decedent
  };
}


/**
 * Form Init Values
 */
export default function getInitValues(type, data) {
  if (type && (type === 'product' || type === 'custom_product' || type === 'edit_custom_product')) {
    return productInitValues(data);
  }
  if (type === 'supplierOrderDirectShipment')
    return { products: [] }
  if (type === 'supplierOrder')
    return { products: data }

  return { id: data && data.id ? data.id : null };
}

export const productSchema = yup.object().shape({
  name: yup
    .string()
    .required('You must enter a product name')
    .min(5, 'The product name must be at least 5 characters'),
  weight: yup.string().required('You must enter a weight'),
  length: yup.string().required('You must enter a length'),
  width: yup.string().required('You must enter a width'),
  height: yup.string().required('You must enter a height'),
  unit_price: yup.string().required('Unit price can not be empty'),
  tariff: yup.string().required('You must enter a tariff'),
  shipping: yup.string().required('You must enter a shipping'),
  fob: yup.string().required('You must enter a value'),
  margin: yup.string().required('You must enter a value'),
  stone_color: yup
    .object()
    .shape({
      name: yup
        .string()
        .nullable()
        .when('stone_image', {
          is: (value) => value && value.id,
          then: yup.string().required('You must enter a stone_image'),
        }),
    })
    .nullable(),
});

export const customProductSchema = yup.object().shape({
  name: yup
    .string()
    .required('You must enter a product name')
    .min(5, 'The product name must be at least 5 characters'),
  length: yup.string().required('You must enter a length'),
  width: yup.string().required('You must enter a width'),
  height: yup.string().required('You must enter a height'),
  order_quantity: yup.string().required('Order quantity can not be empty'),
  polish: yup.mixed()
    .required('You must enter a polish')
    .test('is-string-or-object', 'Polish must be either a string or an object', value => {
      // Check if value is an empty string, which should be treated as invalid
      if (value === '') return false;
      // Check if value is either a string or an object
      return typeof value === 'string' || typeof value === 'object';
    }),

  type: yup.mixed()
    .required('You must enter a type')
    .test('is-string-or-object', 'type must be either a string or an object', value => {
      return typeof value === 'string' || typeof value === 'object';
    }),
  stone_color: yup.mixed()
    .required('You must enter a stone color')
    .test('is-string-or-object', 'stone must be either a string or an object', value => {
      return typeof value === 'string' || typeof value === 'object';
    }),
  weight: yup.number().required('You must enter weight').transform((value) => (Number.isNaN(value) ? undefined : value)).nullable(),
});

export const quoteCustomProductSchema = yup.object().shape({
  name: yup
    .string()
    .required('You must enter a product name')
    .min(5, 'The product name must be at least 5 characters'),
  length: yup.string().required('You must enter a length'),
  width: yup.string().required('You must enter a width'),
  height: yup.string().required('You must enter a height'),
  order_quantity: yup.string().required('Order quantity can not be empty'),
  polish: yup.mixed()
    .required('You must enter a polish')
    .test('is-string-or-object', 'Polish must be either a string or an object', value => {
      // Check if value is an empty string, which should be treated as invalid
      if (value === '') return false;
      // Check if value is either a string or an object
      return typeof value === 'string' || typeof value === 'object';
    }),

  type: yup.mixed()
    .required('You must enter a type')
    .test('is-string-or-object', 'type must be either a string or an object', value => {
      return typeof value === 'string' || typeof value === 'object';
    }),
  stone_color: yup.mixed()
    .required('You must enter a stone color')
    .test('is-string-or-object', 'stone must be either a string or an object', value => {
      return typeof value === 'string' || typeof value === 'object';
    }),
  weight: yup.number().required('You must enter weight').transform((value) => (Number.isNaN(value) ? undefined : value)).nullable(),
  unit_cost: yup.number().required('You must enter fob').transform((value) => (Number.isNaN(value) ? undefined : value)).nullable(),
});

const directShipmentSchema =
  yup.object().shape({
    selected_supplier: yup.object().required(),
    products: yup.array().of(
      yup.object().shape({
        unit_cost: yup
          .number()
          .positive('Unit cost must be greater than 0')
          .required('Unit cost is required and must be greater than 0')
          .transform((value) => (Number.isNaN(value) ? undefined : value)).nullable()
      }))
  });

const vasOrderSchema = yup.object().shape({
  selected_supplier: yup.object().required(),
  products: yup.array().of(
    yup.object().shape({
      vas: yup.object().shape({
        images: yup.array().nullable(),
        text: yup.array().nullable(),
        imagePrice: yup.number().when('images', {
          is: (value) => Boolean(value && value.length > 0),
          then: yup.number().positive('Etching price must be greater than 0').required('Etching price is required and must be greater than 0'),
          otherwise: yup.number().nullable(),
        }),
        textPrice: yup.number().when('text', {
          is: (value) => Boolean(value && value.length > 0),
          then: yup.number().positive('Sandblasting price must be greater than 0').required('Sandblasting price is required and must be greater than 0'),
          otherwise: yup.number().nullable(),
        }),
      }),
    })
  ),
});




const reOrderSchema =
  yup.object().shape({
    selected_supplier: yup.object().required(),
    products: yup.array().of(
      yup.object().shape({
        quantity: yup
          .number()
          .positive('Quantity must be greater than 0')
          .required('Quantity is required and must be greater than 0'),
        unit_cost: yup
          .number()
          .positive('Unit cost must be greater than 0')
          .required('Unit cost is required and must be greater than 0'),
      }))
  });

export const editCustomProductSchema = yup.object().shape({
  name: yup
    .string()
    .required('You must enter a product name')
    .min(5, 'The product name must be at least 5 characters'),
  length: yup.string().required('You must enter a length'),
  width: yup.string().required('You must enter a width'),
  height: yup.string().required('You must enter a height'),
  order_quantity: yup.string().required('Order quantity can not be empty'),
  polish: yup.mixed()
    .required('You must enter a polish')
    .test('is-string-or-object', 'Polish must be either a string or an object', value => {
      // Check if value is an empty string, which should be treated as invalid
      if (value === '') return false;
      // Check if value is either a string or an object
      return typeof value === 'string' || typeof value === 'object';
    }),
  type: yup.mixed()
    .required('You must enter a type')
    .test('is-string-or-object', 'type must be either a string or an object', value => {
      return typeof value === 'string' || typeof value === 'object';
    }),
  stone_color: yup.mixed()
    .required('You must enter a stone color')
    .test('is-string-or-object', 'stone must be either a string or an object', value => {
      return typeof value === 'string' || typeof value === 'object';
    }),
  weight: yup.number().required('You must enter weight').transform((value) => (Number.isNaN(value) ? undefined : value)).nullable(),
});

const editConvertToQuote = yup.object().shape({
  name: yup
    .string()
    .required('You must enter a product name')
    .min(5, 'The product name must be at least 5 characters'),
  length: yup.string().required('You must enter a length'),
  order_quantity: yup.string().required('Order quantity can not be empty'),
  width: yup.string().required('You must enter a width'),
  height: yup.string().required('You must enter a height'),
  polish: yup.mixed()
    .required('You must enter a polish')
    .test('is-string-or-object', 'Polish must be either a string or an object', value => {
      // Check if value is an empty string, which should be treated as invalid
      if (value === '') return false;
      // Check if value is either a string or an object
      return typeof value === 'string' || typeof value === 'object';
    }),
  type: yup.mixed()
    .required('You must enter a type')
    .test('is-string-or-object', 'type must be either a string or an object', value => {
      return typeof value === 'string' || typeof value === 'object';
    }),
  stone_color: yup.mixed()
    .required('You must enter a stone color')
    .test('is-string-or-object', 'stone must be either a string or an object', value => {
      return typeof value === 'string' || typeof value === 'object';
    }),
  weight: yup.number().required('You must enter weight').transform((value) => (Number.isNaN(value) ? undefined : value)).nullable(),
  unit_cost: yup.number().required('You must enter fob').transform((value) => (Number.isNaN(value) ? undefined : value)).nullable(),
});

/**+
 * Form Validation Schema
 */
export function getSchema(type, vas, stage, orderType) {
  console.log('type', type)
  if (vas) {
    return vasOrderSchema
  }
  if (type && type === 'product') {
    return productSchema;
  }
  if (type && type === 'custom_product' && stage === 'quote') {
    return quoteCustomProductSchema;
  }
  if (type && type === 'custom_product') {
    return customProductSchema;
  }
  if (type && type === 'supplierOrderDirectShipment') {
    return directShipmentSchema;
  }
  if (type && type === 'supplierOrder') {
    return reOrderSchema;
  }
  if (type && type === 'edit_custom_product' && orderType === 'convert-to-quote' || stage === 'quote') {
    return editConvertToQuote
  }
  if (type && type === 'edit_custom_product') {
    return editCustomProductSchema;
  }

  return emptySchema;
}