export default function getFilterOptions(stage, role, isSupplierInvoice) {
    if (['admin', 'staff', 'sales'].includes(role)) {
        switch (stage) {
            case 'invoice':
                if (isSupplierInvoice) {
                    return [
                        { name: 'Supplier Invoices', value: 'supplier' },
                        { name: 'Supplier Archived Invoices', value: 'supplier_archive' }
                    ]
                }
                return [
                    { name: 'Customer Invoices', value: 'customer' },
                    { name: 'Customer Archived Invoices', value: 'customer_archive' },
                ]
            case 'order':
                return [
                    { name: 'Customer Orders', value: 'customer' },
                    { name: 'Supplier Orders', value: 'supplier' },
                    { name: 'Customer Archived Orders', value: 'customer_archive' },
                    { name: 'Supplier Archived Orders', value: 'supplier_archive' }
                ];
            case 'rfq':
                return [
                    { name: 'Active RFQ', value: 'active_rfq' },
                    { name: 'Archived RFQ', value: 'archive_rfq' }
                ];
            case 'quote':
                return [
                    { name: 'Active Quote', value: 'active_quote' },
                    { name: 'Archived Quote', value: 'archive_quote' }
                ];
            case 'notifications':
                return [
                    { name: 'Customers', value: 'customers' },
                    { name: 'Suppliers', value: 'suppliers' }
                ]
            default:
                return [];
        }
    }
    if (role === 'customer') {
        switch (stage) {
            case 'order':
                return [
                    { name: 'Active Orders', value: 'customer' },
                    { name: 'Archived Orders', value: 'customer_archive' },
                ];
            case 'invoice':
                return [
                    { name: 'Active Invoices', value: 'customer' },
                    { name: 'Archived Invoices', value: 'customer_archive' },

                ];
            default:
                return [];
        }
    }
    return [];
}
