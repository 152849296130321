import TextField from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import AuraControlledAutocomplete from 'app/main/apps/common/AuraControlledAutocomplete';

function StatusFormContent(props) {
  const methods = useFormContext();
  const { control } = methods;

  return (
    <>
      <div className="flex flex-col md:flex-row w-full h-[25vh]">
        <AuraControlledAutocomplete
          control={control}
          controlName="status"
          inputValues={props.statuses || []}
          labelName="Status *"
          className="mt-8 mb-16"
          renderOption={(renderprops, option) => (
            <li {...renderprops} className="py-2 px-8">
              <div className="text-capitalize">{option.name}</div>
            </li>
          )}
          hideClearable
        />
      </div>
      {/* <div className="flex flex-col md:flex-row w-full">
        <div className="flex w-full ">
          <Controller
            control={control}
            name="note"
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-24"
                label="Notes"
                id="note"
                variant="outlined"
                fullWidth
                size="small"
                multiline
                rows={3}
              />
            )}
          />
        </div>
      </div> */}
    </>
  );
}

export default StatusFormContent;
