import MailApprovalPage from './MailApprovalPage';
import authRoles from '../../auth/authRoles';

const MailActionConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: false,
                },
                footer: {
                    display: false,
                },
                leftSidePanel: {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
            },
        },
    },
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: 'api/approveShipment/:id',
            element: <MailApprovalPage type='shipment' />,
        },
        {
            path: 'api/approval/:id',
            element: <MailApprovalPage type='order' />,
        },
    ],
};

export default MailActionConfig;
