import { useNavigate, useSearchParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import { getCustomerBasedProfile } from '../apps/common/AuraFunctions';

const MailApprovalPage = (props) => {
    const { type } = props
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const getBooleanParam = (paramName) => Boolean(searchParams.get(paramName) === 'true');
    const isApproved = getBooleanParam('is_approve');
    const isReject = getBooleanParam('is_reject');
    const isRevision = getBooleanParam('is_revision');

    const isSuccess = isApproved || isReject || isRevision

    const getStatusMessage = () => {
        if (isApproved) return `Thank you very much for approving the ${type}.`
        if (isReject) return `Thank you very much your ${type} is rejected.`
        if (isRevision) return `Thank you very much your ${type} is in revision.`
        return ''
    }

    return (
        <div className="flex sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
            <Paper className="h-full sm:h-auto md:flex md:items-center md:justify-center w-full sm:w-auto md:h-full md:w-full py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
                <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
                    <div className="flex items-center m-24 mb-48">
                        <img src={getCustomerBasedProfile.companyLogo} alt="logo" />
                    </div>
                    <div className="flex items-center justify-center m-24">
                        <img src={`assets/gif/${isSuccess ? 'success' : 'error'}.gif`} alt="loading..." />
                    </div>
                    <Typography className="mt-32 font-medium text-xl text-center tracking-tight leading-tight">
                        {isSuccess ? getStatusMessage() : 'Sorry, Something went wrong!'}
                    </Typography>
                    <div className="flex items-center justify-center m-24">
                        <Button variant='contained' onClick={() => navigate('/sign-in')}>Okay!</Button>
                    </div>
                </div>
            </Paper>
        </div>
    );
}

export default MailApprovalPage;
